import React from 'react';
const Unit = ({
  type = 'eth',
  length = 'abrv',
  fontSize = 'inherit',
  color = 'inherit',
}) => {
  const units = {
    long: {
      eth: 'Ether (Ξ)',
      weth: 'Wrapped Ether (WΞ)',
      nft: 'NFT',
      ra: 'RARA',
      taste: 'TASTE',
    },
    abrv: {
      eth: 'ETH',
      weth: 'WETH',
      nft: 'NFT',
      ra: '$RA!',
      taste: 'TASTE',
    },
    symbol: {
      eth: 'Ξ',
      weth: 'WΞ',
      nft: 'NFT',
      ra: 'RARA',
      taste: 'TASTE',
    },
  };
  return (
    <span style={{ whiteSpace: 'pre', fontSize: fontSize, color: color }}>
      {units[length][type]}
    </span>
  );
};
export default Unit;
