import { useState, useEffect, useCallback, useMemo } from 'react';
import * as Sentry from '@sentry/react';

import { appSelector } from 'reducers/rootReducer';
import { useSelector } from 'react-redux';

const useAuctionRoom = (auctionId) => {
  // const App = useContext(AppContext);
  const App = useSelector(appSelector);
  const [auctionStatus, setStatus] = useState('loading');
  const [auctionError, setError] = useState(null);
  const [auction, setAuction] = useState(null);

  const connectToAuctionRoom = useCallback(
    ({ auctionId }) => {
      if (App.socket) {
        setStatus('connecting');
        App.socket.on(`auction-update-${auctionId}`, ({ auction }) => {
          setAuction(auction);
        });
        App.socket.emit('auction', { auctionId }, (err, auction) => {
          if (err) {
            setAuction(null);
            setStatus('disconnected');
            setError(err);
            return;
          }
          setAuction(auction);
          setStatus('connected');
        });
      }
    },
    [App.socket]
  );

  useEffect(() => {
    if (auctionError) {
      Sentry.captureException(auctionError);
    }
  }, [auctionError]);

  useEffect(() => {
    // join the auction room when this hook mounts
    if (auctionId && App.socket) {
      connectToAuctionRoom({ auctionId });
    }
    return () => {
      // leave the auction room when this hook unmounts
      auctionId &&
        App.socket &&
        App.socket.emit('leave-room', { roomName: `auction-${auctionId}` });
    };
  }, [connectToAuctionRoom, auctionId, App.socket]);

  return useMemo(
    () => ({
      auction,
      auctionStatus,
      auctionError,
    }),
    [auction, auctionStatus, auctionError]
  );
};

export default useAuctionRoom;
