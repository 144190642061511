import { combineReducers } from 'redux';
import appReducer from './appReducer';
import sessionReducer from './sessionReducer';

const rootReducer = combineReducers({
  app: appReducer,
  session: sessionReducer,
});

export const appSelector = (state) => state.app;
export const socketSelector = (state) => state.app.socket;
export const sessionSelector = (state) => state.session;
export const userSelector = (state) => state.session.user;
export const web3Selector = (state) => state.session.web3;
export const networkSelector = (state) => state.session.web3.network;
export const walletSelector = (state) => state.session.web3.wallet;
export const preferenceSelector = (state) => state.session.preferences; // save to local storage instead?

export default rootReducer;
