import React, { useEffect, useCallback } from 'react';
import { Router } from '@reach/router';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import 'react-toastify/dist/ReactToastify.css';
import '../css/react-toastify-overrides.scss';
import 'antd/dist/antd.css';
import { toast } from 'react-toastify';
import connectAppSocket from 'actions//connectAppSocket';
import disconnectAppSocket from 'actions//disconnectAppSocket';
import checkStatus from 'actions//checkStatus';
import {
  sessionSelector,
  userSelector,
  appSelector,
} from 'reducers/rootReducer';
import { ToastContainer } from 'react-toastify';
import useModal from 'hooks/useModal';
// App-Level Components
import Header from 'components/organisms/Header';
import Footer from 'components/organisms/Footer';
import Notifications from 'components/organisms/Notifications';
// Router Pages
import RacePage from 'components/views/RacePage';
import Leaderboard from 'components/views/LeaderboardView';
import MagicLogin from 'components/views/MagicLogin';

import Auction from 'components/views/AuctionDetailView';
import Profile from 'components/views/ProfilePage.jsx';
import TransferAsset from 'components/molecules/transferAssetForm';
import SendAsset from 'components/views/SendAssetPage';
import SendERC721 from 'components/molecules/sendERC721';
import useAuthSocket from 'hooks/useAuthSocket';
import Modal from 'components/atoms/Modal';
import { AppActions } from 'reducers/appReducer';
toast.configure({
  pauseOnFocusLoss: false,
  // autoClose: false,
}); // lazy load toast conatainer

function App() {
  const dispatch = useDispatch();
  const app = useSelector(appSelector);
  const session = useSelector(sessionSelector);
  const user = useSelector(userSelector);
  const authSocket = useAuthSocket(); // { socket, status, connect, disconnect }

  useEffect(() => {
    // app mounted
    dispatch(connectAppSocket());
    dispatch(checkStatus());
    return () => {
      dispatch(disconnectAppSocket());
    };
  }, [dispatch]);

  useEffect(() => {
    // connect auth socket on user connect
    session.status === 'connected' &&
      authSocket.status === 'disconnected' &&
      authSocket.connect();
    return () => {
      authSocket.status === 'connected' && authSocket.disconnect();
    };
  }, [session.status, authSocket.status, authSocket]);
  const { closeModal } = useModal();
  return (
    <div className='app'>
      <Header />

      <div className='page-container'>
        <Router style={{ height: '100%' }}>
          <Auction path='/auction/:auctionId' />
          <Leaderboard path='/leaderboard' />
          {/* <Communities path='/communities' />
          <Community path='/community/:communityId' /> */}

          <Profile path='/profile/:publicAddress' />
          {session.status === 'connected' ? (
            <>
              {/* <Profiles path='/profiles' /> */}

              <SendAsset path='/transfer'>
                <TransferAsset assetType={'eth'} path='/eth' />
                <TransferAsset assetType={'weth'} path='/weth' />
                <SendERC721 path='/nft' />
              </SendAsset>
            </>
          ) : null}

          <MagicLogin path='/magic' providerType={'magic'} />
          <RacePage default />
        </Router>
      </div>
      {/* Notification Modal */}
      {session.status === 'connected' && user && user.showNotifications ? (
        <Notifications
          notifications={user.notifications}
          showNotifications={user.showNotifications}
        />
      ) : null}

      <ToastContainer
        id='toast-container'
        pauseOnFocusLoss={false}
        style={{ marginTop: '3rem' }}
      />

      {app.modal.isVisible && (
        <Modal
          isVisible={app.modal.isVisible}
          closeModal={closeModal}
          content={app.modal.content}
        />
      )}

      {/* Default Page Title */}
      <Helmet>
        <title>RARA</title>
      </Helmet>

      {/* Blob Images */}
      {/* <img
        className='BgBig1'
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '350px',
          zIndex: -1,
          filter: 'blur(3rem)',
        }}
        src={BgBig1}
        alt='bgbig1'
      />
      <img
        className='BgBig2'
        style={{
          position: 'absolute',
          bottom: 0,
          right: 0,
          width: '350px',
          zIndex: -1,
          filter: 'blur(3rem)',
        }}
        src={BgBig2}
        alt='bgbig1'
      />
      <img
        className='BgSmall1'
        style={{
          position: 'absolute',
          bottom: '10%',
          left: '10%',
          zIndex: -1,
          filter: 'blur(3rem)',
        }}
        src={BgSmall1}
        alt='bgbig1'
      />
      <img
        className='BgSmall2'
        style={{
          position: 'absolute',
          top: '10%',
          right: '10%',
          width: '200px',
          zIndex: -1,
          filter: 'blur(3rem)',
        }}
        src={BgSmall2}
        alt='bgbig1'
      /> */}
    </div>
  );
}

export default App;
