import React, { useState, useRef, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import useOnClickOutside from 'hooks/useOnClickOutside';
import useRequest from 'hooks/useRequest';
import useAuctionContext from 'hooks/useAuctionContext';
import { toast } from 'react-toastify';
import Toast from 'components/molecules/Toast';
import RaErrorIcon from 'icons/RaErrorIcon';
import RaSVG from 'icons/RaSVGIcon';
import RaSVGBackground from 'icons/RaSVGBackground';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
// import RAIconPressed from 'icons/RaIconPressed';
import {
  preferenceSelector,
  walletSelector,
  userSelector,
} from 'reducers/rootReducer';
import useSound from 'use-sound';
import ClapSound from 'images/clap.wav';
const Button = styled.button`
  display: flex;
  // display: none;
  align-items: center;
  position: absolute;
  height: ${({ size }) => (size === 'large' ? '4.35rem' : '3.15rem')};
  width: ${({ size }) => (size === 'large' ? '6rem' : '3.25rem')};
  right: ${({ size }) => (size === 'large' ? '-1.5rem' : '-.5rem')};
  top: ${({ size }) => (size === 'large' ? '-.55rem' : '-.45rem')};
  padding: 0;
  background-color: transparent;
  cursor: pointer;
  &:disabled {
    opacity: 0.75;
    cursor: no-drop;
  }
`;
const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 100%;
  width: 100%;
`;
const RACountTooltip = styled.div`
  position: absolute;
  background: ${({ theme }) => theme.colors.white};
  /* background: #708090; */
  border-radius: 8px;
  /* border: 2px solid white; */
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.25rem 0.5rem 0;
  height: 7rem;
  width: 10rem;
  bottom: ${({ size }) => (size !== 'large' ? '3rem' : '4rem')};
  right: ${({ size }) => (size !== 'large' ? '-.5rem' : '0rem')};
  color: rgba(13, 236, 182);
  font-weight: bold;
  box-shadow: 2px 4px 2px 2px rgba(0, 0, 0, 0.5);
`;
const RACountTooltipTail = styled.div`
  position: absolute;
  right: 1rem;
  height: 20px;
  width: 20px;
  border-radius: 5px;
  transform: rotate(45deg);
  background-color: ${({ theme }) => theme.colors.white};
  /* background-color: #708090; */
  /* border: 1px solid white; */
  bottom: -0.5rem;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  /* border: 1px solid orange; */
  /* margin-top: 0.5rem; */
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
`;

const Input = styled.input`
  width: 100%;
  height: 2.75em;
  text-align: center;
  border-radius: 12px;
  border: 2.5px solid black;
  font-weight: 800;
  /* margin-bottom: 0.4rem; */
  &:active {
    border-color: black;
  }
  &:focus {
    outline: none !important;
  }
`;
const Submit = styled.button`
  background: ${({ theme }) => theme.colors.green100};
  border-radius: 12px;
  height: 3em;
  margin: 0;
  margin-top: .25rem;
  padding: 0.25em 2em;
  &:active {
    opacity: 0.7;
  }
  &:disabled {
    opacity: 0.7;
    background: ${({ theme }) => theme.colors.transparentDark}};
  }
`;

const EarningsWrapper = styled.header`
  width: 100%;
  // border: 1px solid blue;
  border-radius: 12px;
  background: #c0c0c070;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0;
  padding: 0.5rem 1rem;
  h3 {
    font-size: small;
  }
`;
const EarningsSpan = styled.span`
  justify-items: center;
  font-size: 0.8em;
  color: black;
  /* font-style: italic; */
  font-weight: normal;
`;

const EditCurationMenu = ({ size, isDisabled, onSuccess }) => {
  const { auction } = useAuctionContext();
  const preferences = useSelector(preferenceSelector);
  const wallet = useSelector(walletSelector);
  const user = useSelector(userSelector);
  const [isOpen, setIsOpen] = useState(false);
  const [isPressed, setIsPressed] = useState(false);
  const [clapCount, setClapCount] = useState(0);
  const [waitingForClicks, setWaitingForClicks] = useState(false);
  const [isAbandoned, setIsAbandoned] = useState(true);
  // const [nudge, setNudge] = useState(0.5);
  // const dispatch = useDispatch();
  // const toggleMenu = useCallback(() => setIsOpen((o) => !o), []);
  const callApi = useRequest();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const updateRAIncrement = useCallback((amt) => {
    setClapCount(Math.round(amt));
    // dispatch({ type: 'SET_PREFERENCES', payload: { raIncrement } });
  }, []);
  const [playbackRate, setPlaybackRate] = useState(0.75); // sound
  const [play] = useSound(ClapSound, {
    playbackRate,
    volume: 0.5,
  });
  const InputRef = useRef();
  // const nudgeIncrement = useCallback(
  //   (direction) => {
  //     const nudgeAmt = preferences.raIncrement * nudge;
  //     dispatch({
  //       type: 'SET_PREFERENCES',
  //       payload: {
  //         raIncrement: Math.round(
  //           preferences.raIncrement +
  //             (direction === 'up' ? nudgeAmt : -nudgeAmt)
  //         ),
  //       },
  //     });
  //   },
  //   [dispatch, preferences.raIncrement, nudge]
  // );
  const resetClaps = useCallback(() => {
    setClapCount(0);
    setPlaybackRate(0.75);
    setIsAbandoned(true);
  }, []);

  const onClickOutside = useCallback(() => {
    if (!loading && isAbandoned) {
      fetch('/api/reaction/abandon', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ amount: clapCount }),
      });
    }
    resetClaps();
    setIsOpen(false);
  }, [resetClaps, isAbandoned, loading, clapCount]);

  const sendRa = useCallback(
    (e) => {
      e.preventDefault();
      const numToSend = clapCount || 0;
      if (!numToSend) return new Promise((resolve, reject) => resolve());
      onSuccess && onSuccess(numToSend);
      setLoading(true);
      return callApi({
        method: 'POST',
        path: '/api/reaction/ra',
        body: {
          amount: numToSend,
          auctionId: auction.id,
        },
      })
        .then((r) => {
          if (r.error) {
            toast.error(<Toast title={r.error.name} text={r.error.message} />);
            // throw r.error;
          }
          setIsAbandoned(false);
          resetClaps();
          setIsOpen(false);
        })
        .catch((e) => {
          setError(e);
          setTimeout(() => {
            setError(null);
          }, 5000);
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [callApi, auction.id, onSuccess, clapCount, resetClaps]
  );

  const handleClick = useCallback(() => {
    // toggleMenu();
    setWaitingForClicks(true);
    setClapCount((c) => (c + 1 <= wallet.ra ? c + 1 : c));
    setPlaybackRate((p) => p + 0.1);
    setIsPressed(false);
    play();
  }, [wallet.ra, play]);

  useEffect(() => {
    const waitForClicksTimer = setTimeout(() => {
      if (clapCount && !loading) {
        try {
          // setLoading(true);
          setWaitingForClicks(false);
          setIsOpen(true);
        } catch (err) {
          resetClaps();
          toast.error(
            <Toast title='$RA! Error' text={'Couldnt send $RA!: ' + err} />
          );
          setError(err);
          setTimeout(() => {
            setError(false);
          }, 1800);
          setLoading(false);
        }
      }
    }, 750);
    return () => {
      clearTimeout(waitForClicksTimer);
    };
  }, [clapCount, loading, sendRa, resetClaps, wallet.ra]);

  const RAFormTooltipRef = useRef();
  useOnClickOutside({ ref: RAFormTooltipRef, onClickOutside });
  useEffect(() => {
    isOpen && InputRef.current.focus();
  }, [isOpen]);
  return (
    <>
      {/* // <CurationMenuBtn onClick={toggleMenu}> */}
      <Button
        size={size}
        // onClick={onClick}
        onMouseDown={() => setIsPressed((p) => true)}
        onMouseLeave={() => setIsPressed(false)}
        onMouseUp={handleClick}
        disabled={
          loading ||
          error ||
          // auction.state !== 'ACTIVE' ||
          isDisabled ||
          isOpen
        }
      >
        {loading && (
          <LoadingWrapper>
            <RaSVGBackground />
            <Spin
              indicator={antIcon}
              size={size}
              style={{
                position: 'absolute',
              }}
            />
          </LoadingWrapper>
        )}
        {error ? (
          <RaErrorIcon />
        ) : !loading ? (
          waitingForClicks ? (
            <LoadingWrapper>
              <div
                style={{ position: 'absolute', color: 'rgba(13, 236, 182, 1)' }}
              >
                +{clapCount}
              </div>
              <RaSVGBackground />
              {/* // <RAIconPressed  /> */}
            </LoadingWrapper>
          ) : isPressed ? (
            <LoadingWrapper style={{ opacity: 0.7 }}>
              <RaSVG />
            </LoadingWrapper>
          ) : (
            <RaSVG />
          )
        ) : null}
      </Button>
      {isOpen && (
        // </CurationMenuBtn>
        // <Wrapper ref={RAFormTooltipRef}>
        // </Wrapper>
        <RACountTooltip size={size} ref={RAFormTooltipRef}>
          <Form
            style={{ height: '100%', width: '100%', color: 'black' }}
            onSubmit={sendRa}
          >
            {/* <EarningsWrapper>
              <h3 style={{ margin: 0, padding: 0 }}>Your Investment</h3>
              <EarningsSpan>
                Previously Invested:&nbsp;
                {(auction?.auctionResults &&
                  user?.publicAddress &&
                  auction.auctionResults[user.publicAddress] &&
                  auction.auctionResults[user.publicAddress].raSpent) ||
                  0}
                &nbsp;$RA!
              </EarningsSpan>
              <EarningsSpan>
                Estimated Earnings:&nbsp;
                {(auction?.auctionResults &&
                  user?.publicAddress &&
                  auction.auctionResults[user.publicAddress] &&
                  auction.auctionResults[user.publicAddress].raEarned) ||
                  0}
                &nbsp;$RA!
              </EarningsSpan>
            </EarningsWrapper> */}
            <div
              style={{
                width: '100%',
                height: '25%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <fieldset
                style={{
                  // border: '1px solid white',
                  margin: 0,
                  padding: 0,
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-evenly',
                }}
              >
                <Input
                  type='number'
                  ref={InputRef}
                  style={{
                    color:
                      clapCount > wallet.ra || clapCount < 0
                        ? 'crimson'
                        : 'black',
                  }}
                  value={clapCount > 0 ? clapCount : ''}
                  onChange={(e) => updateRAIncrement(Number(e.target.value))}
                ></Input>
                {/* <label style={{ margin: 0, padding: 0 }}>RAs</label> */}
              </fieldset>
              {/* <NudgePanel>
                <NudgeControls>
                  <NudgeBtn
                    style={{ background: 'lightgreen' }}
                    onClick={(e) => {
                      e.preventDefault();
                      nudgeIncrement('up');
                    }}
                  >
                    +
                  </NudgeBtn>
                  <NudgeBtn
                    style={{ background: 'lightpink' }}
                    onClick={(e) => {
                      e.preventDefault();
                      nudgeIncrement('down');
                    }}
                  >
                    -
                  </NudgeBtn>
                </NudgeControls>
                <Select
                  style={{ width: '60%' }}
                  onChange={(e) => setNudge(e.target.value)}
                  defaultValue={0.5}
                >
                  <option value={0.1}>10%</option>
                  <option value={0.25}>25%</option>
                  <option value={0.5}>50%</option>
                  <option value={0.75}>75%</option>
                  <option value={1}>100%</option>
                </Select>
              </NudgePanel> */}
            </div>
            <Submit
              type='submit'
              onClick={sendRa}
              style={{ width: '100%' }}
              disabled={wallet?.ra < preferences?.raIncrement}
            >
              Confirm
            </Submit>
          </Form>
          <RACountTooltipTail />
        </RACountTooltip>
      )}
    </>
  );
};

export default EditCurationMenu;
const antIcon = <LoadingOutlined style={{ color: '#ffffff' }} spin />;
