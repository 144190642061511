import styled from 'styled-components';
import { layout } from 'styled-system';

const Track = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  min-height: 2.5rem;
  border-radius: 16px;
  background: ${({ theme }) => theme.colors.solidDark};
  padding: 0 0.5rem;
  box-sizing: border-box;
  margin: 0.25rem 0 0.25rem 0;
  @media (max-width: 375px) {
    padding: 0;
  }
  ${layout}
`;

export default Track;
