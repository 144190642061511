import React from 'react';
import styled from 'styled-components';
import Unit from 'components/atoms/Unit';
import Badge from 'components/_particles/Badge';

const Span = styled.span`
  font-size: ${({ size }) => (size === 'small' ? '1.3em' : '2em')};
  font-weight: 800;
  color: white;
  cursor: default;
`;

const ClosingBid = ({ auction, size = 'small' }) => {
  if (!auction)
    return (
      <Badge>
        <Span style={{ fontSize: size === 'small' ? '.8em' : '2em' }}>
          AUCTION CLOSED
        </Span>
      </Badge>
    );
  return (
    <Badge height={size !== 'small' ? '3.5rem' : null}>
      <Span size={size}>
        {auction.highBid_amount && auction.highBid_amount > 0
          ? auction.highBid_amount.toFixed(2)
          : '0'}
        &nbsp;
        <Unit type={'weth'} length='abrv' fontSize={'x-small'} />
      </Span>
    </Badge>
  );
};

export default ClosingBid;
