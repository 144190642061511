import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import ReactPlayer from 'react-player';
import useRequest from 'hooks/useRequest';
import ModalPage from 'components/atoms/ModalPage';
import Loader from 'components/atoms/Loader';
import MediaPlayer from 'components/molecules/MediaPlayer';
const MessagePreview = styled.div`
  text-align: left;
  overflow-y: scroll;
  text-overflow: ellipsis;
  word-wrap: break-word;
  padding: 1em;
  background: lightgrey;
  border-radius: 12px;
  width: 20rem;
  height: 8rem;
`;
const CurationPreview = ({
  selectedRace,
  asset,
  assetUrl,
  closeModal,
  message,
  setPage,
  navPrev,
  navNext,
  onSuccess,
}) => {
  const callApi = useRequest();
  const [isLoading, setIsLoading] = useState(false);
  const curateAuction = useCallback(() => {
    setIsLoading(true);
    callApi({
      method: 'POST',
      path: '/api/race/curate-asset',
      body: {
        raceId: selectedRace?._id,
        url: assetUrl,
        message,
      },
    })
      .then((result) => {
        if (result.error) {
          const errorPageCodes = {
            'Insufficient RAs': 'insufficient',
            'Missing URL': 'missingUrl',
            'Bad URL': 'badUrl',
            'Duplicate Asset': 'duplicate',
          };
          setPage(errorPageCodes[result.error.name]);
          navNext();
        } else {
          setPage('success');
          onSuccess(result.auction);
          navNext();
        }
      })
      .catch((error) => {
        const errorPageCodes = {
          'Insufficient RAs': 'insufficient',
          'Missing URL': 'missingUrl',
          'Bad URL': 'badUrl',
          'Duplicate Asset': 'duplicate',
        };
        setPage(errorPageCodes[error.name]);
        // setPage('submission-error');

        navNext();
      })
      .finally(() => setIsLoading(false));
  }, [callApi, assetUrl, message, navNext, setPage, onSuccess, selectedRace]);
  if (!asset) return null;
  return (
    <ModalPage
      title={selectedRace ? selectedRace.name : 'Curate an NFT'}
      primaryBtnText={
        isLoading ? (
          <div
            style={{
              height: '100%',
              width: '100%',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Loader type={'bounce'} />
          </div>
        ) : (
          'Confirm'
        )
      }
      primaryBtnAction={curateAuction}
      primaryBtnDisabled={isLoading}
      backAction={navPrev}
      closeAction={closeModal}
      size={'xxl'}
    >
      <div
        style={{
          height: '40rem',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        {asset.animationURL && ReactPlayer.canPlay(asset.animationURL) ? (
          // <ReactPlayer
          //   url={asset.animationURL}
          //   playing={true}
          //   volume={1}
          //   light={asset.imageUrl}
          //   loop={true}
          //   height='15rem'
          //   width='100%'
          // />
          <div
            style={{
              border: '1px solid lightgrey',
              width: '100%',
              height: '35%',
              borderRadius: '8px',
            }}
          >
            <MediaPlayer
              asset={asset}
              imageCropped={true}
              height={'100%'}
              clickHandler={() => {}}
            />
          </div>
        ) : (
          <img
            src={asset.imageUrl}
            alt={asset.name}
            style={{ height: '15rem' }}
          />
        )}
        <div style={{ margin: '.5rem 0' }}>
          <span style={{ fontWeight: 'bold' }}>{asset.name}</span>
        </div>
        <MessagePreview>{message}</MessagePreview>
        <p style={{ textAlign: 'start', fontSize: 'smaller' }}>
          By confirming this curation, you are investing 25 $RA!s in this NFT
        </p>
      </div>
    </ModalPage>
  );
};

export default CurationPreview;
