import React, { useCallback } from 'react';
import styled from 'styled-components';
import useUser from 'hooks/useUser';
import useModal from 'hooks/useModal';
import AuthModal from 'components/molecules/ConnectWallet';
import NavLink from 'components/atoms/NavLink';

const UserStats = styled.div`
  display: grid;
  grid-template-columns: ${({ connected }) => (connected ? '1fr 1fr' : '1fr')};
  place-items: center;
  background-color: ${({ theme, connected }) =>
    connected ? theme.colors.transparentDark : theme.colors.green30};
  border: 2px solid
    ${({ theme, connected }) =>
      connected ? theme.colors.transparentDark : theme.colors.green};
  border-radius: 0 0 16px 16px;
  width: 71rem;
  padding: 0.5rem;
  cursor: ${({ connected }) => (!connected ? 'pointer' : 'inherit')};
  h2,
  h4 {
    margin: 0;
    padding: 0;
  }
  @media (max-width: 1220px) {
    width: 48rem;
  }
  @media (max-width: 900px) {
    width: 23rem;
    grid-template-columns: auto;
    place-items: start;
  }
  @media (max-width: 375px) {
    width: 19rem;
  }
`;
const UserPFP = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  margin-right: 1rem;
  margin-left: 0.5rem;
`;
const DashboardItem = styled.div`
  font-size: larger;
  font-size: 1em;
`;

const DashboardUserStats = ({ summary }) => {
  const { user, UserAvatar } = useUser();
  const { openModal } = useModal();
  const openAuthDialog = useCallback(() => {
    if (user) return;
    openModal(<AuthModal />);
  }, [openModal, user]);
  return (
    <UserStats onClick={openAuthDialog} connected={!!user}>
      {user ? (
        summary && (
          <>
            <NavLink
              to={'/profile/' + user.publicAddress}
              style={{ color: 'white' }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  fontWeight: 'bold',
                  fontSize: '1.4em',
                  width: '18rem',
                  justifySelf: 'center',
                }}
              >
                <UserPFP>
                  <UserAvatar />
                </UserPFP>
                {user.profile.displayName}
              </div>
            </NavLink>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                justifyContent: 'space-evenly',
              }}
            >
              <DashboardItem>
                <span style={{ fontWeight: 'bold', fontSize: '1.75em' }}>
                  {summary?.userSpend || 0}
                </span>{' '}
                $RA! Invested
              </DashboardItem>
              <DashboardItem>
                <span style={{ fontWeight: 'bold', fontSize: '1.75em' }}>
                  {summary?.userEarned || 0}
                </span>{' '}
                $RA! Earned
              </DashboardItem>
            </div>
          </>
        )
      ) : (
        <h2>Enter RA!CE</h2>
      )}
    </UserStats>
  );
};

export default DashboardUserStats;
