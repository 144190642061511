import React from 'react';
import styled from 'styled-components';
import TelegramIcon from 'icons/TelegramIcon';
import RedditIcon from 'icons/RedditIcon';
import InstagramIcon from 'icons/InstagramIcon';
import DiscordIcon from 'icons/DiscordIcon';
import TwitterIcon from 'icons/TwitterIcon';
import Button from 'components/atoms/Button';

const StyledFooter = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 2rem;
  border-top: 1px solid ${({ theme }) => theme.colors.transparentDark};
  padding: 0.5rem 0;
`;
const FooterHead = styled.h2`
  margin: 0;
  padding: 0;
`;
const Platforms = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 15rem;
  margin: 1rem 0;
`;
const IconWrapper = styled.div`
  height: 2rem;
  width: 2rem;
`;
const Legal = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-top: 1px solid ${({ theme }) => theme.colors.transparentDark};
  margin-top: 1rem;
  padding: 1rem 0;
  @media (max-width: 425px) {
    flex-direction: column-reverse;
    justify-content: center;
  }
`;
const LegalLinksWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 10rem;
  a {
    color: white;
  }
`;

const Footer = () => {
  return (
    <StyledFooter>
      <FooterHead>Join our community</FooterHead>
      <Platforms>
        <IconWrapper>
          <a
            href='https://twitter.com/rara_social'
            target='_blank'
            rel='noreferrer'
          >
            <TwitterIcon />
          </a>
        </IconWrapper>
        {/* <IconWrapper>
          <a href='https://t.me/rara_social' target='_blank' rel='noreferrer'>
            <TelegramIcon />
          </a>
        </IconWrapper> */}
        <IconWrapper>
          <a
            href='https://discord.gg/grhCnJqZPc'
            target='_blank'
            rel='noreferrer'
          >
            <DiscordIcon />
          </a>
        </IconWrapper>
        {/* <IconWrapper>
          <a
            href='https://www.reddit.com/r/rara_social/'
            target='_blank'
            rel='noreferrer'
          >
            <RedditIcon />
          </a>
        </IconWrapper> */}
        <IconWrapper>
          <a
            href='https://www.instagram.com/rara_social/'
            target='_blank'
            rel='noreferrer'
          >
            <InstagramIcon />
          </a>
        </IconWrapper>
      </Platforms>
      <Button
        borderRadius='8px'
        width='15rem'
        height='2rem'
        padding='0'
        bg='#6e779550'
        color='white'
        onClick={() => {
          window.open(
            'https://docs.google.com/forms/d/1XmnMejgtWySe7_bgqSWtgGK651EGZcQJZmS7NL3I0QM/viewform?edit_requested=true',
            '_blank'
          );
        }}
      >
        Send Feedback
      </Button>
      <Legal>
        <span>© RARA Social, Inc. All rights reserved.</span>
        <LegalLinksWrapper>
          <a
            href='https://app.rara.social/terms.pdf'
            target='_blank'
            rel='noreferrer'
          >
            Terms
          </a>
          <a
            href='https://app.rara.social/privacy.pdf'
            target='_blank'
            rel='noreferrer'
          >
            Privacy Policy
          </a>
        </LegalLinksWrapper>
      </Legal>
    </StyledFooter>
  );
};

export default Footer;
