import React from 'react';
import styled from 'styled-components';
import { layout, space, color, border, flexbox } from 'styled-system';

const StyledButton = styled.button`
  cursor: pointer;
  background: ${({ disabled }) =>
    disabled ? 'grey' : 'rgba(13, 236, 182, 0.8)'};
  &:disabled {
    background: grey;
  }
  ${layout}
  ${space}
  ${color}
  ${border}
  ${flexbox}
`;

const Button = ({ onClick, children, disabled, ...rest }) => {
  return (
    <StyledButton onClick={onClick} disabled={disabled} {...rest}>
      {children}
    </StyledButton>
  );
};

export default Button;
