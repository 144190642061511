import React from 'react';
const RaIconLight = () => {
  return (
    <svg
      width='100%'
      height='100%'
      viewBox={'0 0 47 47'}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M22.195 18.857a.653.653 0 0 1-1.01.828l-9.448-11.51a.653.653 0 0 1 1.01-.828l9.448 11.51z'
        fill='#0DECB6'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M18.889 21.924a.653.653 0 1 1-.806 1.027l-4.38-3.436L9.9 16.533a.653.653 0 1 1 .806-1.027l3.8 2.982 4.382 3.436z'
        fill='#D356FF'
      />
      <g
        filter='url(#ra-icon-light-filter-1)'
        fillRule='evenodd'
        clipRule='evenodd'
      >
        <path
          d='M25.18 15.315c-.254.135-.516.285-.787.45a.672.672 0 0 1-.822-.095.638.638 0 0 1 .104-.996c2.269-1.39 4.126-1.882 4.892-1.116 1.326 1.326-1.118 5.92-5.46 10.262-3.672 3.672-7.526 5.987-9.429 5.81 1.903.178 5.757-2.137 9.43-5.81 3.674-3.673 5.989-7.528 5.81-9.43-.001-.02.021-.025.026-.006l4.759 17.758a1.735 1.735 0 0 1-2.107 2.13l-18.204-4.66a.507.507 0 0 1-.115-.044c-.054-.03-.107-.064-.162-.092-.1-.053-.19-.118-.27-.197-.701-.702-.347-2.319.785-4.33.207-.365.709-.417 1.006-.12a.676.676 0 0 1 .107.805l-.14.258c-.46.865-.707 1.556-.785 2.043-.037.229.143.41.372.372.487-.078 1.178-.325 2.043-.785 1.714-.912 3.846-2.52 5.951-4.625 2.106-2.106 3.714-4.237 4.626-5.952.46-.864.706-1.555.785-2.042a.316.316 0 0 0-.373-.373c-.487.079-1.178.325-2.042.785zm2.52-.791-.005-.002a.019.019 0 0 1 .004.002zm-.097-.094a.022.022 0 0 1-.002-.004l.002.004zm-13.89 13.884.004.002a.016.016 0 0 1-.004-.002zm.096.093a.023.023 0 0 1 .002.005l-.002-.005z'
          fill='#fff'
          fillOpacity='.7'
        />
        <path
          d='M25.18 15.315c-.254.135-.516.285-.787.45a.672.672 0 0 1-.822-.095.638.638 0 0 1 .104-.996c2.269-1.39 4.126-1.882 4.892-1.116 1.326 1.326-1.118 5.92-5.46 10.262-3.672 3.672-7.526 5.987-9.429 5.81 1.903.178 5.757-2.137 9.43-5.81 3.674-3.673 5.989-7.528 5.81-9.43-.001-.02.021-.025.026-.006l4.759 17.758a1.735 1.735 0 0 1-2.107 2.13l-18.204-4.66a.507.507 0 0 1-.115-.044c-.054-.03-.107-.064-.162-.092-.1-.053-.19-.118-.27-.197-.701-.702-.347-2.319.785-4.33.207-.365.709-.417 1.006-.12a.676.676 0 0 1 .107.805l-.14.258c-.46.865-.707 1.556-.785 2.043-.037.229.143.41.372.372.487-.078 1.178-.325 2.043-.785 1.714-.912 3.846-2.52 5.951-4.625 2.106-2.106 3.714-4.237 4.626-5.952.46-.864.706-1.555.785-2.042a.316.316 0 0 0-.373-.373c-.487.079-1.178.325-2.042.785zm2.52-.791-.005-.002a.019.019 0 0 1 .004.002zm-.097-.094a.022.022 0 0 1-.002-.004l.002.004zm-13.89 13.884.004.002a.016.016 0 0 1-.004-.002zm.096.093a.023.023 0 0 1 .002.005l-.002-.005z'
          fill='url(#ra-icon-light-filter-2)'
        />
      </g>
      <defs>
        <linearGradient
          id='ra-icon-light-filter-2'
          x1='12.449'
          y1='28.378'
          x2='31.338'
          y2='23.317'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#fff' />
          <stop offset='1' stopColor='#fff' stopOpacity='0' />
        </linearGradient>
        <filter
          id='ra-icon-light-filter-1'
          x='4.412'
          y='7.59'
          width='34.962'
          height='36.514'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset />
          <feGaussianBlur stdDeviation='2.611' />
          <feColorMatrix values='0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.2 0' />
          <feBlend in2='BackgroundImageFix' result='effect1_dropShadow' />
          <feBlend in='SourceGraphic' in2='effect1_dropShadow' result='shape' />
        </filter>
      </defs>
    </svg>
  );
};
export default RaIconLight;
