import React from 'react';
import styled from 'styled-components';
import useAuctionContext from 'hooks/useAuctionContext';
import format from 'date-fns/format';
import EditCurationMenu from 'components/molecules/EditCurationMenu';
import { useSpring, animated } from 'react-spring';
import useUser from 'hooks/useUser';

const Header = styled.header`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
  padding: 0.25rem 0;
  width: 100%;
  background: rgba(110, 119, 149, 0.1);
  border-radius: 24px 24px 0 0;
  min-height: 3rem;
`;
const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0.5rem 1.5rem 0;
`;
const CurationInfo = styled.div`
  display: flex;
  align-items: center;
  max-width: 89%;
  width: 89%;
  min-width: 89%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
const CardTitle = styled.h3`
  margin: 0;
  padding: 0;
  font-size: 0.9em;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
const CardSubtitle = styled.h4`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin: 0;
  font-size: 0.7em;
  padding: 0;
`;

const AuctionCardHeader = ({
  isOwner,
  removeCuration,
  showEarningsDropdown,
}) => {
  const { auction, page } = useAuctionContext();
  const { user } = useUser();
  const animatedStyle = useSpring({
    top: showEarningsDropdown ? '0rem' : '-2rem',
    background: 'white',
    color: 'black',
    opacity: showEarningsDropdown ? 1 : 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    width: '100%',
    height: '100%',
    border: '2px solid white',
    fontWeight: 'bold',
    position: 'absolute',
    padding: '.25rem',
    borderRadius: '4px',
    zIndex: 5,
  });
  return (
    <Header>
      {auction && auction.race && (
        <HeaderRow>
          <CurationInfo>
            <CardTitle style={{ fontWeight: 'bold' }}>
              {auction.asset.name ? auction.asset.name : auction.asset.tokenId}
            </CardTitle>
            &nbsp;
            <CardSubtitle style={{ color: '#F0F0F0', fontStyle: 'italic' }}>
              {auction.asset?.raw?.creator?.name
                ? `by ${auction.asset?.raw?.creator?.name}`
                : auction.asset?.collection?.name
                ? `- ${auction.asset?.collection?.name}`
                : null}
            </CardSubtitle>
          </CurationInfo>
          {page === 'profile' && isOwner && auction.state === 'PENDING' && (
            <EditCurationMenu
              auctionId={auction._id}
              removeCuration={removeCuration}
            />
          )}
        </HeaderRow>
      )}
      {auction && auction.race && (
        <HeaderRow
          style={{
            margin: 0,
            paddingBottom: '.5rem',
            paddingTop: '0',
            color: '#F0F0F0',
          }}
        >
          {page === 'profile' && (
            <CardSubtitle>
              RA!CE #{auction.race.raceNumber.toString().padStart(3, '0')}
              &nbsp;-&nbsp;
              {auction.race.theme ? auction.race.theme : 'Best NFT of the Day'}
            </CardSubtitle>
          )}
          <CardSubtitle>
            {page === 'profile'
              ? `Curated on ${format(
                  new Date(auction.race.raceStart),
                  'MM/dd/yyyy'
                )}`
              : null}
          </CardSubtitle>
        </HeaderRow>
      )}
      {user &&
        auction?.auctionResults &&
        auction?.state !== 'PENDING' &&
        auction.auctionResults[user.publicAddress] && (
          <animated.div
            style={{
              ...animatedStyle,
              position: 'absolute',
            }}
          >
            <span>
              Investment:{' '}
              {auction?.auctionResults &&
                user?.publicAddress &&
                auction.auctionResults[user.publicAddress] &&
                auction.auctionResults[user.publicAddress].raSpent}
            </span>
            <span>
              Earnings:{' '}
              {auction?.auctionResults &&
                user?.publicAddress &&
                auction.auctionResults[user.publicAddress] &&
                auction.auctionResults[user.publicAddress].raEarned}
            </span>
          </animated.div>
        )}
    </Header>
  );
};

export default AuctionCardHeader;
