import React, { useMemo } from 'react';

const Memes = {
  welcome: [
    'https://media.giphy.com/media/l0MYC0LajbaPoEADu/giphy.gif',
    'https://media.giphy.com/media/l4JyOCNEfXvVYEqB2/giphy.gif',
    'https://media.giphy.com/media/l0MYC0LajbaPoEADu/giphy.gif',
  ],
  'curation-success': [],
  'explain-taste': [
    'https://c.tenor.com/NMuTJkeoRjkAAAAC/snoop-dogg-high-class.gif',
    'https://media.giphy.com/media/2XskdWPH2WxnxfMnZAI/giphy.gif',
    'https://media.giphy.com/media/13tShWaU241SBW/giphy.gif',
    'https://media.giphy.com/media/8087BPwFoZY0o/giphy.gif',
  ],
};

const useMeme = ({ type = 'welcome' }) => {
  return useMemo(() => {
    if (!type) return 'Oops';
    const numMemes = Memes[type].length;
    const randomNum = Math.floor(Math.random() * numMemes);
    return Memes[type][randomNum];
  }, [type]);
};

export default useMeme;
