import React from 'react';

const XIcon = ({ stroke, fill }) => {
  return (
    <svg
      width='14'
      height='14'
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M1 13 13 1M1 1l12 12'
        stroke={stroke ? stroke : '#0C1226'}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default XIcon;
