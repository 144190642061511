import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { web3Selector } from 'reducers/rootReducer';
import { abi_WETH } from 'configs/wethContract';
import { toast } from 'react-toastify';
import Toast from 'components/molecules/Toast';
import ethUtil from 'ethereumjs-util';

// now that we've moved createBuyOrder to the auction context, I'm wondering if it's even worth while to keep this hook around just to share a single function.
const useWeb3 = () => {
  const web3 = useSelector(web3Selector);
  /*
    transferAsset - generic asset transfer func returning a promise that resolves with the 'receipt' of a crypto transaction between our client's connectedAccount and some destination address.
    Accepts a config object with three params:
      transferAssetConfig = {
        assetType: 'eth' | 'weth' | 'nft',
        destinationAddress: string,
        [amount?: number |
        asset?: {
          tokenId: string,
          tokenAddress: string,
          schemaName: string
        }]
      }
  */
  const transferAsset = useCallback(
    async ({ assetType, destinationAddress, amount, asset }) => {
      if (web3.network.id !== Number(process.env.REACT_APP_ETH_NETWORK_ID)) {
        toast.error(
          <Toast
            title='Error'
            text={`You must be on Mainnet to complete this action.  Current network: ${web3.network.name}`}
          />
        );
        return;
      }
      // TODO: handle missing params better
      if (!amount && !asset) return; // must include something to transfer
      if (amount && asset) return; // must include only one type of transferable
      if (!assetType || !destinationAddress) return; // must have other required fields
      if (asset) {
        const { tokenId, tokenAddress, schemaName } = asset;
        if (!tokenId || !tokenAddress || !schemaName) return; // nft specific required field check
      }
      switch (assetType) {
        // TODO: refresh wallet balance on successful transfer of currency assets
        case 'eth':
          const amountInWei = web3.instance.utils.toWei(amount);
          return await web3.instance.eth.sendTransaction({
            from: web3.connectedAccount,
            to: destinationAddress,
            value: amountInWei,
          });
        case 'weth':
          const paymentToken = await web3.seaport.api.getPaymentTokens({
            symbol: assetType.toUpperCase(),
          });
          if (!paymentToken.tokens[0]) {
            throw new Error('token not found');
          }
          if (paymentToken.tokens[0].decimals !== 18) {
            // TODO - 'toWei' won't handle tokens where the decimals is not 18
            throw new Error('token has wrong decimals');
          }
          const amountBN = web3.instance.utils.toWei(amount);
          const contractAddress = paymentToken.tokens[0].address;
          const wethContract = new web3.instance.eth.Contract(
            abi_WETH,
            contractAddress
          );
          return wethContract.methods
            .transfer(destinationAddress, amountBN)
            .send({ from: web3.connectedAccount });
        case 'nft':
          return await web3.seaport.transfer({
            asset,
            fromAddress: web3.connectedAccount,
            toAddress: destinationAddress,
            quantity: 1,
          });
        default:
          return;
      }
    },
    [web3]
  );

  const signMsg = useCallback(
    async (msg) => {
      try {
        if (web3) {
          const hexMsg = ethUtil.bufferToHex(Buffer.from(msg));
          const signature = await web3.instance.eth.personal.sign(
            hexMsg,
            web3.connectedAccount
          );
          return ethUtil.bufferToHex(signature);
        } else {
          toast.error(
            <Toast title='Web3 Error' text={'You are not connected to web3'} />
          );
          return new Promise.reject();
        }
      } catch (e) {
        toast.error(
          <Toast title='Signature Error' text={'Error with Wallet Signature'} />
        );
        return { error: e };
      }
    },
    [web3]
  );

  return useMemo(() => ({ transferAsset, signMsg }), [transferAsset, signMsg]);
};

export default useWeb3;
