import React, { useMemo } from 'react';
import styled from 'styled-components';
import UserProfile from 'components/molecules/UserProfile';
import WalletModal from 'components/organisms/WalletModal';
import AuctionCard from 'components/organisms/AuctionCard';
import useUser from 'hooks/useUser';

const ProfileTemplate = styled.div`
  display: flex;
  justify-content: center;
  gap: 1.5em;
  padding: 0 3rem;
  margin-top: 1rem;
  @media (max-width: 900px) {
    flex-direction: column;
    align-items: center;
  }
`;
const ProfileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 20rem;
`;
const GalleryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 60px; // match user profile card
`;
const Curations = styled.div`
  margin: 1rem 0;
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr 1fr 1fr;
  @media (max-width: 1500px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 1130px) {
    grid-template-columns: 1fr;
  }
`;
const UserProfileTemplate = ({
  user,
  curations,
  closedBids,
  removeCuration,
}) => {
  const { user: currentUser } = useUser();
  const isProfileOwner = useMemo(
    () => user?.publicAddress === currentUser?.publicAddress,
    [user, currentUser]
  );
  const ActiveCurations = useMemo(() => {
    return curations?.ACTIVE?.map((b, i) => (
      <AuctionCard
        auctionId={b._id}
        page={'profile'}
        header={'curator'}
        withCurator={true}
        removeCuration={removeCuration}
        key={`ACTIVE-${i}`}
      />
    ));
  }, [curations, removeCuration]);
  const PendingCurations = useMemo(() => {
    return curations?.PENDING?.map((b, i) => (
      <AuctionCard
        auctionId={b._id}
        page={'profile'}
        header={'curator'}
        removeCuration={removeCuration}
        key={`PENDING-${i}`}
      />
    ));
  }, [curations, removeCuration]);
  const CompletedCurations = useMemo(() => {
    return curations?.COMPLETED?.map((b, i) => (
      <AuctionCard
        auctionId={b._id}
        page={'profile'}
        header={'curator'}
        withCurator={true}
        removeCuration={removeCuration}
        key={`COMPLETED-${i}`}
      />
    ));
  }, [curations, removeCuration]);
  return (
    <ProfileTemplate>
      <ProfileWrapper>
        <UserProfile profile={user} />
        <br />
        {isProfileOwner && <WalletModal />}
      </ProfileWrapper>
      <GalleryWrapper>
        {isProfileOwner && curations?.PENDING?.length > 0 && (
          <>
            <h2 style={{ margin: 0, padding: 0 }}>Pending Curations</h2>
            <Curations>{PendingCurations}</Curations>
          </>
        )}
        {curations?.ACTIVE?.length > 0 && (
          <>
            <h2 style={{ margin: 0, padding: 0 }}>LIVE Curations</h2>
            <Curations>{ActiveCurations}</Curations>
          </>
        )}
        {curations?.COMPLETED?.length > 0 && (
          <>
            <h2 style={{ margin: 0, padding: 0 }}>Curated NFTs</h2>
            <Curations>{CompletedCurations}</Curations>
          </>
        )}
      </GalleryWrapper>
    </ProfileTemplate>
  );
};
export default UserProfileTemplate;
