import React, { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import useUser from 'hooks/useUser';
import { walletSelector, appSelector } from 'reducers/rootReducer';
import RaIconLight from 'icons/RaIconLight';
import { useMediaQuery } from 'react-responsive';
import { useParams } from '@reach/router';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 1rem;
  padding: 0.25rem;
  background-color: ${({ theme }) => theme.colors.transparentDark};
  border-radius: 40px 15px 15px 40px;
  box-shadow: ${({ theme, active }) =>
    active ? '0px 0px 8px 3px rgba(13, 86, 135, .9)' : 'transparent'};
  transition: box-shadow 0.2s ease-out;
  @media (max-width: 900px) {
    gap: 0rem;
  }
`;
const ProfileWrapper = styled.div`
  gap: 1rem;
  display: grid;
  place-items: center;
  grid-template-columns: auto 1fr;
  @media (max-width: 900px) {
    /* grid-template-columns: auto; */
    gap: 0.5rem;
  }
`;
const Name = styled.span`
  line-height: 40px;
  max-height: 40px;
  overflow: hidden;
  text-align: left;
`;
const BalanceWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding-left: 1rem;
  background: #0c1226;
  border-radius: 0 7px 7px 0;
  @media (max-width: 900px) {
    font-size: 0.9em;
  }
`;
const Balance = styled.div`
  margin-right: 0.5rem;
`;

const UserProfileSmall = () => {
  const { user, UserAvatar } = useUser();
  const { page } = useSelector(appSelector);
  const { ra: raBalance } = useSelector(walletSelector);
  const [isPressed, setIsPressed] = useState(false);
  const handlePress = useCallback(() => {
    setIsPressed((p) => !p);
  }, []);
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 900px)' });
  return (
    <Wrapper
      active={
        page === 'profile' && window.location.href.includes(user?.publicAddress)
      }
      pressed={isPressed}
      onMouseDown={handlePress}
      onMouseUp={handlePress}
    >
      <ProfileWrapper>
        <div
          style={{
            alignSelf: 'center',
            height: '40px',
            width: '40px',
            borderRadius: '50%',
            overflow: 'hidden',
          }}
        >
          <UserAvatar size='sm' />
        </div>

        {!isTabletOrMobile && (
          <Name>
            {user.profile
              ? user.profile.displayName
                ? user.profile.displayName
                : user.profile.publicAddress
                ? user.profile.publicAddress
                : 'Anon'
              : null}
          </Name>
        )}
      </ProfileWrapper>
      <BalanceWrapper>
        <Balance>{raBalance + ' $RA!'}</Balance>
        <div style={{ height: '40px', width: '40px' }}>
          <RaIconLight />
        </div>
      </BalanceWrapper>
    </Wrapper>
  );
};
export default UserProfileSmall;
