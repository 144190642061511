import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { space } from 'styled-system';
import ClipboardIcon from 'icons/ClipboardIcon';
import CheckmarkPaperIcon from 'icons/CheckmarkPaperIcon';
import copyTextToClipboard from 'helpers/copyTextToClipboard';
const Wrapper = styled.div`
  background: lightgrey;
  position: relative;
  display: flex;
  align-items: center;
  padding: 0.75rem;
  width: 100%;
  word-break: break-word;
  height: auto;
  border-radius: 8px;
  font-size: smaller;
  cursor: pointer;
  ${space}
`;
const IconWrapper = styled.div`
  width: 18%;
  padding: 1rem;
  color: ${({ hideCopyButton }) => (hideCopyButton ? '#ffffff' : '#00ff00')};
  @media (max-width: 374px) {
    padding: 0.5rem 0.75rem;
    padding-left: 0.25rem;
  }
`;
const CopyValueWrapper = styled.div`
  width: 82%;
`;

const CopyArea = ({ value, ...props }) => {
  const [hideCopyButton, setHideCopyButton] = useState(true);
  const animatedCopy = useCallback(() => {
    setHideCopyButton(false);
    copyTextToClipboard(value);
  }, [value]);
  useEffect(() => {
    let timer;
    if (hideCopyButton === false) {
      timer = setTimeout(() => {
        setHideCopyButton(true);
      }, 1800);
    }
    return () => {
      timer && clearTimeout(timer);
    };
  }, [hideCopyButton]);
  return (
    <Wrapper onClick={animatedCopy} {...props}>
      <IconWrapper hideCopyButton={hideCopyButton}>
        {!hideCopyButton ? (
          <CheckmarkPaperIcon />
        ) : (
          <ClipboardIcon stroke={'black'} />
        )}
      </IconWrapper>
      <CopyValueWrapper>{value}</CopyValueWrapper>
    </Wrapper>
  );
};

export default CopyArea;
