import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { appSelector } from 'reducers/rootReducer';
import { AppActions } from 'reducers/appReducer';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import Gallery from 'components/molecules/Gallery';
import Badge from 'components/_particles/Badge';
import AuctionCard from 'components/organisms/AuctionCard';
import useRaceRoom from 'hooks/useRaceRoom';
import RaceDashboard from 'components/organisms/RaceDashboard';
import AppTitle from 'components/atoms/AppTitle';
import Footer from 'components/organisms/Footer';
import shuffle from 'lodash.shuffle';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const PageHeader = styled.header`
  width: 71rem;
  margin-bottom: 1rem;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  h1 {
    text-align: center;
    margin: 0;
    padding: 0;
  }
  span {
    font-size: 1.2em;
  }
  @media (max-width: 1220px) {
    width: 48rem;
  }
  @media (max-width: 900px) {
    width: 23rem;
  }
  @media (max-width: 375px) {
    width: 20rem;
    span {
      font-size: 1em;
    }
  }
  @media (max-width: 320px) {
    padding: 0 0.5rem;
  }
`;
const RARAHeader = styled.h1`
  line-height: 1em;
  font-size: 2.5em;
  @media (max-width: 900px) {
    font-size: 3.7em;
  }
  @media (max-width: 375px) {
    font-size: 3.5em;
  }
  @media (max-width: 320px) {
    font-size: 3.3em;
  }
`;
const RaceHeader = styled.h1`
  font-family: 'Monoton', cursive;
  color: rgba(13, 236, 182, 0.8);
  line-height: 1em;
  font-size: 4.5em;
  @media (max-width: 900px) {
    font-size: 4.2em;
  }
  @media (max-width: 375px) {
    font-size: 3.8em;
  }
  @media (max-width: 320px) {
    font-size: 3.3em;
  }
`;
const SortWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const RacePage = () => {
  const dispatch = useDispatch();
  const { page } = useSelector(appSelector);
  const { activeRace } = useRaceRoom();
  const RaceNFTs = useMemo(() => {
    if (activeRace && activeRace.auctions) {
      const auctions = activeRace.auctions.map((a, i) => (
        <SortWrapper auctionId={a?._id}>
          {activeRace.state !== 'PENDING' && Ranks[i]}
          <AuctionCard
            auctionId={a?._id}
            view='list'
            page='list'
            // header='curator'
          />
        </SortWrapper>
      ));
      if (activeRace.state === 'PENDING') {
        return shuffle(auctions);
      } else {
        return auctions;
      }
    } else {
      return [];
    }
    // return activeRace && activeRace.auctions
    //   ? activeRace.auctions.map((a, i) => (
    //       <SortWrapper auctionId={a?._id}>
    //         {activeRace.state !== 'PENDING' && Ranks[i]}
    //         <AuctionCard
    //           auctionId={a?._id}
    //           view='list'
    //           page='list'
    //           header='curator'
    //         />
    //       </SortWrapper>
    //     ))
    //   : [];
  }, [activeRace]);
  useEffect(() => {
    if (page !== 'landing') dispatch(AppActions.SET_PAGE('landing'));
  }, [dispatch, page]);
  return (
    <>
      <Wrapper style={{ marginTop: '2rem' }}>
        <PageHeader>
          <RARAHeader>RARA's</RARAHeader>
          <RaceHeader
            style={{
              marginBottom: '1rem',
            }}
          >
            {/* NFT RA!CE */}
            <AppTitle />
          </RaceHeader>
          <span>
            The NFT RA!CE is a like-to-earn competition to find the best NFTs
            according to fans, not price. Invest $RA!s in your favorite NFTs and
            earn when other fans invest in the same NFT after you. The creators
            of the Top 3 NFTs will earn 500, 300 and 200 $RA! respectively.{' '}
            <a
              href='https://rara.mirror.xyz/5WNrK3VvIO4Lw4F1iBYuLbx7op3O9L_EQ2EFVpebSnI'
              target='_blank'
              rel='noreferrer'
            >
              Continue reading...
            </a>
          </span>
        </PageHeader>
        {<RaceDashboard activeRace={activeRace} />}
        {!activeRace ? (
          <></>
        ) : activeRace.state === 'ACTIVE' ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <span
              style={{
                margin: '1rem 0',
                padding: 0,
                fontSize: '20px',
                textAlign: 'center',
              }}
            >
              <b>Cheer for all of your favorite NFTs by hitting $RA!</b> <br />
              Rapid tap the $RA! if you really love an NFT.
            </span>{' '}
          </div>
        ) : activeRace.state === 'PENDING' ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <span
              style={{
                margin: '1rem 0',
                padding: 0,
                fontSize: '20px',
                textAlign: 'center',
              }}
            >
              <b>The NFT RA!CE is pending. </b> <br />
              Soon you'll be able to cheer for your favorite NFT
            </span>{' '}
          </div>
        ) : activeRace.state === 'COMPLETED' ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <span
              style={{
                margin: '1rem 0',
                padding: 0,
                fontSize: '20px',
                textAlign: 'center',
              }}
            >
              <b>This NFT RA!CE is now closed. </b> <br />
              Check out the winners of the last RA!CE.
            </span>{' '}
          </div>
        ) : (
          <></>
        )}
        <Gallery assets={RaceNFTs} columns={3} />
        <Footer />
      </Wrapper>
      <Helmet>
        <title>RA!CE</title>
      </Helmet>
    </>
  );
};

export default RacePage;

const Ranks = {
  0: (
    <>
      <Badge
        height='2.5rem'
        width={'70%'}
        fontWeight='bold'
        fontSize={'1.5em'}
        bg={'transparent'}
      >
        <div>🏆</div> &nbsp;&nbsp; 1st
      </Badge>
      <hr style={{ width: '100%', border: '1px solid #0DECB6' }} />
    </>
  ),

  1: (
    <>
      <Badge
        height='2.5rem'
        width={'70%'}
        fontWeight='bold'
        fontSize={'1.5em'}
        bg={'transparent'}
      >
        <div>🥈</div> &nbsp; 2nd
      </Badge>
      <hr style={{ width: '100%', border: '1px solid #0DECB6' }} />
    </>
  ),
  2: (
    <>
      <Badge
        height='2.5rem'
        width={'70%'}
        fontWeight='bold'
        fontSize={'1.5em'}
        bg={'transparent'}
      >
        <div>🥉</div> &nbsp; 3rd
      </Badge>
      <hr style={{ width: '100%', border: '1px solid #0DECB6' }} />
    </>
  ),
};
