import React from 'react';
const ArrowLeftIcon = ({ fill }) => {
  return (
    <svg
      width='17'
      height='11'
      viewBox='0 0 17 11'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M3.81834 6.1528C3.23678 6.1528 2.94553 6.85594 3.35676 7.26717L5.88867 9.79908C6.1436 10.054 6.1436 10.4673 5.88867 10.7222C5.63375 10.9772 5.22043 10.9772 4.9655 10.7222L0.204866 5.96161C-0.0500595 5.70668 -0.0500595 5.29337 0.204866 5.03844L4.9655 0.277803C5.22043 0.0228775 5.63375 0.0228775 5.88867 0.277803C6.1436 0.532729 6.1436 0.946045 5.88867 1.20097L3.35676 3.73289C2.94553 4.14411 3.23678 4.84725 3.81834 4.84725H15.6803C16.0409 4.84725 16.3331 5.13951 16.3331 5.50003C16.3331 5.86054 16.0409 6.1528 15.6803 6.1528H3.81834Z'
        fill={fill ? fill : '#0C1226'}
      />
    </svg>
  );
};

export default ArrowLeftIcon;
