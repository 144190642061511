import React, { useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { userSelector } from 'reducers/rootReducer';
import { AppActions } from 'reducers/appReducer';
import styled from 'styled-components';
import Unit from 'components/atoms/Unit';
import useRequest from 'hooks/useRequest';
import useModal from 'hooks/useModal';
import InfoIcon from 'icons/ToastInfoIcon';
import Loader from 'components/atoms/Loader';
import ModalPage from 'components/atoms/ModalPage';
import { toast } from 'react-toastify';
import Toast from 'components/molecules/Toast';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-radius: 8px;
  background: #6e779550; //transparentLight @ 50%
  padding: 0.75rem;
`;
const Subtitle = styled.span`
  font-size: 0.7em;
  width: 100%;
  display: flex;
  flex-direction: row;
`;
const Verify = styled.div`
  width: 4rem;
  padding: 0.25rem 1rem;
  font-size: smaller;
  font-weight: bold;
  border-radius: 8px;
  background: #6e779570; //transparentLight @ 70%
  cursor: pointer;
`;
const InfoIconWrapper = styled.div`
  height: 1.5em;
  width: 1.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
const UserWalletRAs = ({ amount, unit, caption, highlight }) => {
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const callApi = useRequest();
  const { closeModal } = useModal();
  const [isVerifying, setIsVerifying] = useState(false);
  const [verifiedBalance, setVerifiedBalanced] = useState(0);
  const verifyBalance = useCallback(() => {
    if (user && user.publicAddress) {
      setIsVerifying(true);
      callApi({
        path: '/api/verify/' + user.publicAddress,
        method: 'GET',
      })
        .then((result) => {
          if (result.balance || result.balanceVerified) {
            setVerifiedBalanced(result.balance);
          } else if (result.error) {
            throw result.error;
          }
        })
        .catch((e) => {
          e &&
            e.reason &&
            toast.error(<Toast title={'Verification Error'} text={e.reason} />);
        })
        .finally(() => {
          setIsVerifying(false);
        });
    }
  }, [user, callApi]);
  const showVerificationModal = useCallback(() => {
    dispatch(
      AppActions.SET_MODAL(
        <ModalPage
          size={'xl'}
          title={'Verify your $RA! balance'}
          headerNav={'close'}
          closeAction={closeModal}
          primaryBtnAction={closeModal}
          primaryBtnText={'Close'}
        >
          <p style={{ color: 'black' }}>
            At the end of each NFT RA!CE, RARA batches the results on-chain in a
            Merkle tree update, including your updated $RA! balance. By clicking
            "Verify", you are verifying your $RA! balance on-chain. <br />
            <br /> If your verified balance is different from what you see in
            the app, don't worry. Results to an NFT RA!CE may be in the process
            of being updated on-chain.
          </p>
        </ModalPage>
      )
    );
  }, [dispatch, closeModal]);
  return (
    <Wrapper>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <div
          style={{ color: 'white', fontWeight: 'bold', lineHeight: '1.5em' }}
        >
          {amount}{' '}
          <span style={{ color: '#90A0D3', fontWeight: 'normal' }}>
            <Unit type={unit} />
          </span>
        </div>
        <Verify onClick={verifyBalance}>
          {isVerifying ? (
            <div>
              {' '}
              <Loader
                type='bounce'
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  width: '4rem',
                }}
              />
            </div>
          ) : (
            'Verify'
          )}
        </Verify>
      </div>
      <Subtitle style={{ color: '#90A0D3' }}>
        {verifiedBalance
          ? `On-chain: ${verifiedBalance} $RA!`
          : 'Verify to check your on-chain balance.'}{' '}
        &nbsp;
        <InfoIconWrapper onClick={showVerificationModal}>
          <InfoIcon fill={'#90A0D3'} />
        </InfoIconWrapper>
      </Subtitle>
    </Wrapper>
  );
};
export default UserWalletRAs;
