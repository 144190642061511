import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useNavigate } from '@reach/router';
import { appSelector } from 'reducers/rootReducer';
import TrackLabel from 'components/atoms/TrackLabel';
import AuctionAvatar from 'components/atoms/AuctionAvatar';
import useAuctionContext from 'hooks/useAuctionContext';
import NavLink from 'components/atoms/NavLink';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* padding: 0.5rem 0.5rem; */
  width: ${({ page }) => (page === 'detail' ? '30vw' : '23rem')};
  cursor: ${({ page }) => (page !== 'detail' ? 'pointer' : 'inherit')};
  background: #23283bef;
  border-radius: 12px;
  padding: 1rem 1rem;
  /* padding: 1rem 0.75rem; */
  /* margin-bottom: 0.75rem; */
  @media (max-width: 900px) {
    width: 23rem;
  }
  @media (max-width: 375px) {
    width: 20rem;
  }
  @media (max-width: 320px) {
    width: 18rem;
  }
`;
const CuratorMsg = styled.div`
  padding-top: 0.5rem;
  width: 100%;
  overflow-wrap: break-word;
`;

const Curator = ({ curator }) => {
  const { page } = useSelector(appSelector);
  const AuctionContext = useAuctionContext();
  const navigate = useNavigate();
  const navToDetail = useCallback(() => {
    // in order to display this component in Detail view, we 'pass' the component up to the AuctionCard's parent for rendering, this breaks the connection to the cards Context so there will only be an auctionId on non-detail pages
    if (page !== 'detail') navigate('/auction/' + AuctionContext.auction._id);
  }, [AuctionContext, navigate, page]);

  if (!curator) return null;

  return (
    <Wrapper page={page} onClick={navToDetail}>
      <div
        style={{
          display: 'flex',
          width: '100%',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div style={{ display: 'flex' }}>
          <AuctionAvatar user={curator || null} mr={'.5rem'}></AuctionAvatar>
          <NavLink
            to={'/profile/' + curator.publicAddress}
            style={{ display: 'flex', alignItems: 'center', color: 'white' }}
            onClick={(e) => e.stopPropagation()}
          >
            <h3 style={{ margin: 0 }}>{curator ? curator.displayName : ''}</h3>
          </NavLink>
        </div>
        <TrackLabel
          label={'CURATOR'}
          style={{ marginRight: 0, justifySelf: 'center' }}
        />
      </div>
      <CuratorMsg>
        <span>
          {curator
            ? curator.message
            : '$RA! Check out this NFT auction I curated.'}
        </span>
      </CuratorMsg>
    </Wrapper>
  );
};
export default Curator;
