import { SessionActions } from 'reducers/sessionReducer';
import getWalletBalance from './getWalletBalance';
import { toast } from 'react-toastify';
import Toast from 'components/molecules/Toast';

const networks = {
  1: { name: 'Mainnet', etherScanUrl: 'https://etherscan.io/tx/' },
  3: { name: 'Ropsten', etherScanUrl: 'https://ropsten.etherscan.io/tx/' },
  4: { name: 'Rinkeby', etherScanUrl: 'https://rinkeby.etherscan.io/tx/' },
  5: { name: 'Goerli', etherScanUrl: 'https://goerli.etherscan.io/tx/' },
  42: { name: 'Kovan', etherScanUrl: 'https://kovan.etherscan.io/tx/' },
  137: { name: 'Matic', etherScanUrl: '' },
};

// get updated user info - acct, network
const refreshWallet = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const web3 = state.session.web3.instance;
    if (!web3) {
      // should i log out here? not sure. need more research
      // return dispatch(SessionActions.LOGOUT());
    } else {
      dispatch(SessionActions.SET_USER_STATUS('connecting'));
      const accounts = await web3.eth.getAccounts();
      const netId = await web3.eth.net.getId();
      dispatch(SessionActions.SET_CONNECTED_ACCOUNT(accounts[0].toLowerCase()));
      dispatch(SessionActions.SET_NETWORK({ id: netId, ...networks[netId] }));

      // see if we can add their network to the error message
      const knownNetwork = !!networks[netId];

      if (netId !== Number(process.env.REACT_APP_ETH_NETWORK_ID)) {
        toast.warn(
          <Toast
            title='Wrong Network'
            text={`To use this app, please connect to ${
              process.env.REACT_APP_ETH_NETWORK_NAME
            }.${
              knownNetwork ? ` Current network: ${networks[netId].name}` : ''
            }`}
          />
        );
        return;
      }

      return dispatch(getWalletBalance());
    }
  };
};

export default refreshWallet;
