import React, { useState, useRef, useCallback } from 'react';
import styled from 'styled-components';
import useOnClickOutside from 'hooks/useOnClickOutside';
import XIcon from 'icons/XIcon';
import useModal from 'hooks/useModal';
import useRequest from 'hooks/useRequest';
import { toast } from 'react-toastify';
import EllipsesIcon from 'icons/EllipsesIcon';
import Toast from 'components/molecules/Toast';
import ModalPage from 'components/atoms/ModalPage';

const Wrapper = styled.div`
  position: absolute;
  top: -1rem;
  right: 0rem;
  display: flex;
  flex-direction: column;
  width: 8rem;
  background: ${({ theme }) => theme.colors.greyBlue};
  padding: 0.5rem 1rem;
  border-radius: 8px;
  z-index: 5;
`;
const Item = styled.div`
  display: flex;
  text-align: center;
  align-items: center;
  width: 0.5rem;
  cursor: pointer;
  font-size: 0.8em;
`;
const CurationMenuBtn = styled.button`
  padding: 0.5rem 0 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: flex-start;
  background: transparent;
`;
const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 0.8em;
  width: 0.8em;
`;

const EditCurationMenu = ({ auctionId, removeCuration }) => {
  const [isOpen, setIsOpen] = useState(false);
  const callApi = useRequest();
  const { openModal, closeModal } = useModal();
  const toggleMenu = useCallback(() => setIsOpen((o) => !o), []);
  const onClickOutside = useCallback(() => {
    setIsOpen(false);
  }, []);
  const removePendingCuration = useCallback(async () => {
    return await callApi({
      path: '/api/race/remove-asset',
      method: 'POST',
      body: { auctionId },
    })
      .then((r) => {
        if (r.isError) {
          throw r;
        }
        removeCuration && removeCuration(auctionId);
      })
      .then(() => {
        onClickOutside();
        closeModal();
      })
      .catch((e) => {
        toast.error(<Toast title={'Error'} text={e.error} />);
      });
  }, [callApi, onClickOutside, closeModal, auctionId, removeCuration]);
  const confirmDeletion = useCallback(() => {
    openModal(
      <ModalPage
        title={'Delete Curation'}
        headerNav={'close'}
        primaryBtnText='Delete'
        primaryBtnAction={removePendingCuration}
        closeAction={() => {
          onClickOutside();
          closeModal();
        }}
        size='s'
      >
        <div>
          <span style={{ color: 'black' }}>
            Are you sure you want to delete this curation? You will not be able
            to find the curation note again.
          </span>
        </div>
      </ModalPage>
    );
  }, [openModal, removePendingCuration, onClickOutside, closeModal]);
  const ProfileMenuRef = useRef();
  useOnClickOutside({ ref: ProfileMenuRef, onClickOutside });
  return (
    <>
      {/* {!isOpen ? (
        <CurationMenuBtn onClick={toggleMenu}>
          <EllipsesIcon />
        </CurationMenuBtn>
      ) : ( */}
      {/* <Wrapper ref={ProfileMenuRef}> */}
      <Item onClick={confirmDeletion}>
        <Icon>
          <XIcon stroke={'red'} fill='red' />
        </Icon>
        {/* &nbsp;&nbsp;Delete Curation */}
      </Item>
      {/* </Wrapper> */}
      {/* )} */}
    </>
  );
};

export default EditCurationMenu;
