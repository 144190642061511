import React, { useMemo } from 'react';
import styled from 'styled-components';
import { space } from 'styled-system';
import Blockies from 'react-blockies';
import { Image, Transformation } from 'cloudinary-react';
import NavLink from 'components/atoms/NavLink';

const Wrapper = styled.div`
  border-radius: 50%;
  overflow: hidden;
  height: ${({ dimensions }) =>
    typeof dimensions.height === 'number'
      ? dimensions.height + 'px'
      : dimensions.height};
  min-height: ${({ dimensions }) =>
    typeof dimensions.height === 'number'
      ? dimensions.height + 'px'
      : dimensions.height};
  max-height: ${({ dimensions }) =>
    typeof dimensions.height === 'number'
      ? dimensions.height + 'px'
      : dimensions.height};
  width: ${({ dimensions }) =>
    typeof dimensions.width === 'number'
      ? dimensions.width + 'px'
      : dimensions.width};
  min-width: ${({ dimensions }) =>
    typeof dimensions.width === 'number'
      ? dimensions.width + 'px'
      : dimensions.width};
  max-width: ${({ dimensions }) =>
    typeof dimensions.width === 'number'
      ? dimensions.width + 'px'
      : dimensions.width};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  ${space}
`;
const Avatar = styled.img`
  object-fit: cover;
  height: ${({ dimensions }) =>
    typeof dimensions.height === 'number'
      ? dimensions.height + 'px'
      : dimensions.height};
  min-height: ${({ dimensions }) =>
    typeof dimensions.height === 'number'
      ? dimensions.height + 'px'
      : dimensions.height};
  max-height: ${({ dimensions }) =>
    typeof dimensions.height === 'number'
      ? dimensions.height + 'px'
      : dimensions.height};
  width: ${({ dimensions }) =>
    typeof dimensions.width === 'number'
      ? dimensions.width + 'px'
      : dimensions.width};
  min-width: ${({ dimensions }) =>
    typeof dimensions.width === 'number'
      ? dimensions.width + 'px'
      : dimensions.width};
  max-width: ${({ dimensions }) =>
    typeof dimensions.width === 'number'
      ? dimensions.width + 'px'
      : dimensions.width};
  border-radius: 50%;
`;

const AuctionAvatar = ({
  user,
  dimensions = { height: 24, width: 24 },
  ...props
}) => {
  const imgLink = useMemo(
    () => (user.avatar ? user.avatar : user.avatarURL),
    [user]
  );
  return (
    <NavLink to={'/profile/' + user.publicAddress}>
      <Wrapper dimensions={dimensions} {...props}>
        {user.imageId ? (
          <Image
            cloudName='rara-social'
            publicId={user.imageId + '.webp'}
            crop='scale'
          >
            <Transformation
              width={dimensions.width}
              height={dimensions.height}
              gravity='face'
              crop='thumb'
            />
          </Image>
        ) : imgLink ? (
          <Avatar src={imgLink} dimensions={dimensions} />
        ) : (
          <Blockies
            seed={user.publicAddress}
            size={dimensions.height > 24 ? 36 : 6}
            scale={4}
          />
        )}
      </Wrapper>
    </NavLink>
  );
};

export default AuctionAvatar;
