import React, { useMemo } from 'react';
import Column from '../_particles/Column';
import UserTrack from './UserTrack';
import TrackLabel from '../atoms/TrackLabel';
import AuctionTopUser from './AuctionTopUser';

const AuctionCardColumn = ({
  label,
  users,
  type,
  highlightCount,
  showAdditional = true,
}) => {
  const blankRowCount = useMemo(() => {
    return users.length >= highlightCount
      ? highlightCount
      : highlightCount - users.length;
  }, [users.length, highlightCount]);
  const HighlightedUsers = useMemo(() => {
    return users.length >= highlightCount
      ? users.slice(0, highlightCount)
      : users.concat(new Array(blankRowCount).fill(null));
  }, [users, highlightCount, blankRowCount]);
  const AdditionalUsers = users.slice(highlightCount);
  return (
    <Column>
      <TrackLabel label={label} align={'flex-start'} btmMargin={'.5rem'} />
      {users && (
        <>
          <div style={{ margin: '.25rem' }}></div>
          {HighlightedUsers.map((u, i) => (
            <AuctionTopUser
              user={u}
              amount={!u ? null : u.bidAmount ? u.bidAmount : u.amount}
              type={type}
              key={`highlighted-${type}-${i}`}
              emptyLabel={
                i === 0
                  ? label.indexOf('Offers') !== -1
                    ? 'Be the first to bid'
                    : 'Be the first fan'
                  : ''
              }
            />
          ))}
        </>
      )}
      {showAdditional && AdditionalUsers && (
        <UserTrack
          users={AdditionalUsers}
          numToDisplay={6}
          emptyLabel={
            label.indexOf('Offers') !== -1
              ? HighlightedUsers.length > 1
                ? 'Be an early bidder'
                : 'Be the first to bid'
              : ''
          }
        ></UserTrack>
      )}
    </Column>
  );
};

export default AuctionCardColumn;
