import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { userSelector } from 'reducers/rootReducer';
import Blockies from 'react-blockies';
import UserIcon from 'icons/UserIcon';
import { Image, Transformation } from 'cloudinary-react';

const useUser = () => {
  const user = useSelector(userSelector);
  const UserAvatar = useMemo(() => {
    return ({ size = 'lg' }) => {
      if (user && user.profile) {
        //prefer cloundinary imageId
        if (user.profile.imageId) {
          return (
            <Image
              cloudName='rara-social'
              publicId={user.profile.imageId + '.webp'}
              crop='scale'
            >
              <Transformation
                width={size === 'lg' ? '150' : '40'}
                height={size === 'lg' ? '150' : '40'}
                gravity='face'
                crop='thumb'
              />
            </Image>
          );
        }

        //use profile avatarURL
        if (
          user.profile.avatarURL &&
          !user.profile.avatarURL.endsWith('null.png')
        ) {
          return (
            <img
              src={`${user.profile.avatarURL}`}
              alt={user.profile.displayName}
              style={{ height: '100%', width: '100%', borderRadius: '50%' }}
            />
          );
        }

        // fallback
        return (
          <div
            className={'flex-center'}
            style={{
              height: '100%',
              width: '100%',
              borderRadius: '50%',
              overflow: 'hidden',
            }}
          >
            <Blockies
              seed={user.publicAddress}
              size={36}
              scale={4}
              style={{ height: '100%', width: '100%', borderRadius: '50%' }}
            />
          </div>
        );
      } else {
        // no user to display -- display other placeholder
        return <UserIcon />;
      }
    };
  }, [user]);
  return useMemo(
    () => ({
      user,
      UserAvatar,
    }),
    [user, UserAvatar]
  );
};

export default useUser;
