import React, { useMemo } from 'react';
import DataTable from 'react-data-table-component';
import { useMediaQuery } from 'react-responsive';
// import 'css/reactDataTableOverrides.scss';

// TODO: The docs say that it's possible to do all this styling with styled-components (which would be much preferred because then we could easily access our theme) but i  can't seem to get it to work.  overriding the default styling (as we're doing with customStyles) seems to be the only thing we got atm.

const Leaderboard = ({ data, columns }) => {
  const shouldResize = useMediaQuery({ query: '(max-width: 835px)' });
  const customStyles = useMemo(() => {
    return {
      tableWrapper: {
        style: {
          padding: '0px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: '#0c1226',
        },
      },
      table: {
        style: {
          width: shouldResize ? '100%' : '60%',
          overflowY: 'scroll',
          outlineWidth: 0,
          backgroundColor: '#0c1226',
          border: '1px solid rgba(13, 236, 182, .1)',
          borderRadius: '10px',
        },
      },
      header: {
        style: {
          height: '2.5rem',
          borderRadius: '25px 25px 0 0',
          backgroundColor: '#0c1226',
          padding: 0,
        },
      },
      subheader: {
        style: {
          height: '2.5rem',
          backgroundColor: 'purple',
        },
      },
      head: {
        style: {
          borderTopLeftRadius: '10px',
          borderTopRightRadius: '10px',
        },
      },
      headRow: {
        style: {
          height: '2.5rem',
          backgroundColor: '#0c1226',
        },
      },
      headCells: {
        style: {
          backgroundColor: 'rgba(13, 236, 182, .6)', //green60
          border: '1px solid #0c1226',
          padding: '0 1rem',
          fontWeight: 'bold',
          fontSize: shouldResize ? '.75em' : '1.1em',
          color: 'white',
          div: {
            wordWrap: 'break-word',
            whiteSpace: 'break-spaces',
            overflowWrap: 'break-word',
            textOverflow: 'clip',
            lineHeight: '1.5em',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            width: '100%',
            textAlign: 'center',
          },
          '&:first-child': {
            borderTopLeftRadius: '10px',
          },
          '&:last-child': {
            borderTopRightRadius: '10px',
          },
        },
      },
      rows: {
        style: {
          minHeight: '3rem',
          backgroundColor: '#0c1226', //solidDark
          color: 'white',
          // border: 'none',
          outlineWidth: 0,
          '&:last-child': {
            borderBottomLeftRadius: '10px',
            borderBottomRightRadius: '10px',
          },
        },
        highlightOnHoverStyle: {
          color: 'white',
          backgroundColor: 'rgba(13, 236, 182, .3)', //green30
          border: 'none',
          outlineWidth: 0,
        },
      },
      cells: {
        style: {
          paddingLeft: '1rem',
          paddingRight: '1rem',
        },
      },
    };
  }, [shouldResize]);
  return (
    // <div className={'around the table'}>
    <DataTable
      columns={columns}
      data={data}
      customStyles={customStyles}
      highlightOnHover
      paginationPerPage={data ? data.length : 0}
      keyField={'keyField'}
      // responsive
      // dense={isTabletOrMobile}
    />
    // </div>
  );
};

export default Leaderboard;
