import React, { useContext, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import login from 'actions//login';
import { navigate } from '@reach/router';
import { AppActions } from 'reducers/appReducer';
import { web3Selector } from 'reducers/rootReducer';
import { ProfileContext } from 'components/user/ProfileContext';
import Loader from 'components/atoms/Loader';
import ModalPage from 'components/atoms/ModalPage';

const ConnectWallet = () => {
  const dispatch = useDispatch();
  const web3 = useSelector(web3Selector);
  const { platformType, platformId, communityId, ref } =
    useContext(ProfileContext);
  const closeModal = useCallback(() => {
    dispatch(AppActions.SET_MODAL(null));
  }, [dispatch]);
  const connectWallet = useCallback(async () => {
    try {
      dispatch(
        login('walletconnect', null, {
          platformType,
          platformId,
          communityId,
          ref,
        })
      );
      // close modal
      dispatch(AppActions.SET_MODAL(null));
    } catch (error) {
      console.error('connectWallet error', error);
    }
  }, [dispatch, platformType, platformId, communityId, ref]);
  const selectMagic = useCallback(
    (params) => {
      let magicUrl = '/magic';
      const urlParts = window.location.href.split('/');
      if (urlParts[3] && urlParts[3] === 'event' && urlParts[4]) {
        magicUrl += '/' + urlParts[4];
      }
      navigate(magicUrl);
      // close modal
      dispatch(AppActions.SET_MODAL(null));
    },
    [dispatch]
  );

  return (
    <ModalPage
      title={'Enter the RA!CE'}
      closeAction={closeModal}
      headerNav={'close'}
      primaryBtnText={'Connect Wallet'}
      primaryBtnAction={connectWallet}
      secondaryBtnText={'Connect Email Wallet'}
      secondaryBtnAction={selectMagic}
      size={'s'}>
      {web3.status === 'connecting' ? (
        <div
          style={{
            display: 'grid',
            alignContent: 'center',
            justifyContent: 'center',
            height: '100%',
          }}>
          <p className='robot-label'>Continue with your Web3 wallet</p>
          <div className='text-center'>
            <Loader type='bounce' />
          </div>
        </div>
      ) : (
        <>{web3.status}</>
      )}

      {web3.status === 'error' && (
        <div>
          <div className='mb-3'></div>
          <p>
            <b>Connection Error</b>
          </p>
          <p>
            An error occurred while connecting your wallet. Please try again.
          </p>
        </div>
      )}
    </ModalPage>
  );
};

export default ConnectWallet;
