import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from '@reach/router';
import styled from 'styled-components';
import AuctionAvatar from '../atoms/AuctionAvatar';
import Unit from 'components/atoms/Unit';
import Row from '../_particles/Row';
import Track from '../_particles/Track';
import TrackLabel from '../atoms/TrackLabel';

const BidderName = styled.div`
  color: ${({ theme }) => theme.colors.white};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 68%;
  @media (max-width: 375px) {
    max-width: 100%;
    width: 8ch;
  }
  @media (max-width: 320px) {
    width: 6ch;
  }
`;
const BidAmount = styled.span`
  @media (max-width: 375px) {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  color: ${({ theme }) => theme.colors.white};
`;
const Group = styled.div`
  /* border: 1px solid; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-width: 0;
  flex: 1;
  @media (max-width: 375px) {
    margin-right: 0.25rem;
  }
`;

const AuctionTopUser = ({
  user,
  amount,
  type = 'Bid',
  label,
  emptyLabel = '',
}) => {
  // TODO: clean up this garbage
  let topUser = user ? (type === 'Bid' ? user : user.user) : null;
  const navigate = useNavigate();
  const navToProfile = useCallback(
    (e) => {
      navigate('/profile/' + topUser.publicAddress);
      e.stopPropagation();
    },
    [topUser, navigate]
  );
  return (
    <Row>
      {label && <TrackLabel label={label} />}
      {!topUser ? (
        <Track style={{ justifyContent: 'center' }}>
          <span>{emptyLabel}</span>
        </Track>
      ) : (
        <Track
          style={{
            justifyContent: 'space-between',
            overflow: 'hidden',
          }}
          maxWidth='calc(100%)'
          onClick={navToProfile}
        >
          <Group
            style={{ alignItems: 'center', justifyContent: 'flex-start' }}
            className='TopUserGroup'
          >
            <AuctionAvatar
              user={{
                avatar: topUser.avatarURL ? topUser.avatarURL : topUser.avatar,
                imageId: topUser.imageId,
                name: topUser.displayName ? topUser.displayName : topUser.user,
                publicAddress: topUser.publicAddress,
              }}
              mx={'.5rem'}
            />
            <BidderName>
              {topUser.displayName ? topUser.displayName : ''}
            </BidderName>
          </Group>
          <Group
            style={{
              alignItems: 'baseline',
              justifyContent: 'flex-end',
              maxWidth: '30%',
              width: '30%',
            }}
          >
            <BidAmount>
              {type === 'Bid' && amount.toString().indexOf('.') !== -1
                ? parseFloat(amount.toFixed(4))
                : amount}
            </BidAmount>
            &nbsp;
            <Unit
              type={type === 'Bid' ? 'weth' : 'ra'}
              length='abrv'
              fontSize='smaller'
              color='white'
            ></Unit>
          </Group>
        </Track>
      )}
    </Row>
  );
};

AuctionTopUser.propTypes = {
  type: PropTypes.oneOf(['Bid', 'Fans']),
  amount: PropTypes.number,
};

export default AuctionTopUser;
