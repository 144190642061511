import { SessionActions } from 'reducers/sessionReducer';
import { AppActions } from 'reducers/appReducer';
import { toast } from 'react-toastify';
import { navigate } from '@reach/router';
import Toast from 'components/molecules/Toast';

const logout = () => {
  return async (dispatch) => {
    await fetch('/api/logout', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((response) => {
      // success (201's?)
      if (response.status === 200 || response.status === 201) {
        window.localStorage.removeItem('MAGIC_CACHED_PROVIDER');
        window.localStorage.removeItem('walletconnect');
        window.localStorage.removeItem('WEB3_CONNECT_CACHED_PROVIDER');
        dispatch(SessionActions.LOGOUT());
        dispatch(AppActions.SET_PAGE('landing'));
        navigate(window.location.origin);
        toast.info(<Toast title='Logged out' text='You logged out of RARA' />);
        return;
      }
      // some type of error has occured...
      console.log(response.status, response.statusText);
      toast.error(<Toast title='Error' text={response.statusText} />);
    });
  };
};

export default logout;
