import React, { useState, useCallback } from 'react';
import ClipboardIcon from 'icons/ClipboardIcon';
import CheckmarkPaperIcon from 'icons/CheckmarkPaperIcon';
import copyTextToClipboard from 'helpers/copyTextToClipboard';
//TODO: clean this mess up!
function CopyButton({
  address,
  height = '1.5rem',
  width = '1.5rem',
  stroke = 'white',
  btnStyle = {
    background: 'transparent',
    marginRight: '.5rem',
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: height,
  },
}) {
  const [hideCopyButton, setHideCopyButton] = useState(true);
  const animatedCopy = useCallback(() => {
    setHideCopyButton(false);
    copyTextToClipboard(address);
    setTimeout(() => {
      setHideCopyButton(true);
    }, 1800);
  }, [address]);

  return (
    <button
      type='button'
      style={{ ...btnStyle, color: hideCopyButton ? '#ffffff' : '#00ff00' }}
      onClick={animatedCopy}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: height,
          width: width,
        }}
      >
        {!hideCopyButton ? (
          <CheckmarkPaperIcon />
        ) : (
          <ClipboardIcon stroke={stroke} />
        )}
      </div>
    </button>
  );
}
export default CopyButton;
