import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { appSelector } from 'reducers/rootReducer';

const NavListItem = styled.li`
  margin-left: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
  height: 100%;
`;
const TabIndicator = styled.div`
  position: absolute;
  bottom: -1rem;
  height: 3px;
  border-color: darkgrey;
  width: 100%;
  box-sizing: border-box;
  box-shadow: ${({ active }) =>
    active ? '0 -2px 2px rgba(13, 86, 195, .9)' : 'transparent'};
  transition: box-shadow 0.2s ease-out;
`;

const HeaderItem = ({ tab, children, ...props }) => {
  const app = useSelector(appSelector);
  const ActivePage = useMemo(
    () => app && app.page === tab && app.page !== 'profile',
    [app, tab]
  ); // could also use reach-router getProps func and isCurrent prop
  return (
    <NavListItem {...props}>
      {children}
      <TabIndicator active={ActivePage} />
    </NavListItem>
  );
};

export default HeaderItem;
