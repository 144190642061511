import React, { forwardRef, useCallback, useState, useEffect } from 'react';
import { color, layout, space, SpaceProps } from 'styled-system';
import styled from 'styled-components';

interface ITextarea
  extends SpaceProps,
    React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  name?: string;
  value?: string;
  onChange?: React.ChangeEventHandler<EventTarget>;
  error?: any;
  onFocus?: React.EventHandler<any>;
  focus?: boolean;
  placeholder?: string | React.ReactElement;
  tooltip?: React.ReactElement;
  dirty?: boolean;
  width?: string;
  height?: string;
  register?: any;
}

export const Container = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  ${layout};
`;

const StyledTextarea = styled.textarea`
  flex: 1;
  width: 100%;
  height: 100%;
  padding: 1rem 0.75rem;
  border-radius: 15px;
  font-family: ${({ theme }) => theme.fonts.RobotoRegular};
  color: ${({ theme }) => theme.colors.primary200};
  font-size: 1em;
  line-height: 24px;
  letter-spacing: 0.15px;
  resize: none;
  outline: 0;
  box-sizing: border-box;

  border: solid 2.5px
    ${({ theme, error, focus }) =>
      error
        ? 'red'
        : focus
        ? theme.colors.primary100
        : theme.colors.primary200};
  &::placeholder {
    color: ${({ theme }) => theme.colors.black48};
  }
  &:focus {
    border: solid 2.5px
      ${({ theme, error }) => (error ? 'red' : theme.colors.primary100)};
  }
  &:focus::placeholder {
    color: ${({ theme, error }) =>
      error ? theme.colors.black48 : theme.colors.primary30};
  }
  ::-webkit-scrollbar {
    width: 0.45em;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background: #7f7f7f;
  }
  ${space};
  ::-webkit-scrollbar {
    width: 0.45em;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background: #7f7f7f;
  }
  ${space};
`;
const ErrorMessageWrapper = styled.span`
  display: flex;
  align-items: center;
  color: ${({ theme }) => 'red'};
  font-family: ${({ theme }) => theme.fonts.RobotoRegular};
  font-size: 0.75rem;
  line-height: 14px;
  letter-spacing: 0.4px;
  padding-left: 1rem;
  height: 1em;
  ${space};
  ${color};
`;

const Textarea = forwardRef(
  (
    {
      width = '100%',
      height = '10rem',
      label,
      error,
      dirty = false,
      placeholder,
      disabled,
      withCounter = false,
      ...props
    }: ITextarea,
    ref?
  ) => {
    const [textLength, setTextLength] = useState(0);
    const [focus, setFocus] = useState(false);
    const handleBlur = useCallback(() => {
      setFocus(false);
    }, []);
    const handleFocus = useCallback(() => {
      setFocus(true);
    }, []);
    return (
      <Container width={width} height={height}>
        <StyledTextarea
          label={label}
          error={error}
          dirty={dirty}
          onFocus={handleFocus}
          onBlur={handleBlur}
          disabled={disabled}
          placeholder={placeholder}
          ref={ref}
          {...props}
          onChange={(e) => setTextLength(e.target.value.length)}
        />
        {withCounter && (
          <div style={{ position: 'absolute', right: '12px', bottom: '16px' }}>
            {textLength.toString() + '/280'}
          </div>
        )}
        <ErrorMessageWrapper>{error ? error.message : ''}</ErrorMessageWrapper>
      </Container>
    );
  }
);

export default Textarea;
