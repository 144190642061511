import React from 'react';

const EllipsesIcon = () => {
  return (
    <svg
      width='19'
      height='3'
      viewBox='0 0 19 3'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M8.038 1.462c0 .807.646 1.461 1.443 1.461.796 0 1.442-.654 1.442-1.461C10.923.654 10.277 0 9.481 0c-.797 0-1.443.654-1.443 1.462z'
        fill='#fff'
      />

      <path
        d='M16.077 1.462c0 .807.646 1.461 1.442 1.461.797 0 1.443-.654 1.443-1.461C18.962.654 18.316 0 17.518 0c-.796 0-1.442.654-1.442 1.462z'
        fill='#fff'
      />

      <path
        d='M0 1.462c0 .807.646 1.461 1.442 1.461.797 0 1.442-.654 1.442-1.461C2.884.654 2.24 0 1.442 0 .646 0 0 .654 0 1.462z'
        fill='#fff'
      />

      <defs>
        <filter
          id='a'
          x='-15.799'
          y='-23.838'
          width='50.56'
          height='50.598'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feGaussianBlur in='BackgroundImage' stdDeviation='11.919' />
          <feComposite
            in2='SourceAlpha'
            operator='in'
            result='effect1_backgroundBlur_859_73135'
          />
          <feBlend
            in='SourceGraphic'
            in2='effect1_backgroundBlur_859_73135'
            result='shape'
          />
        </filter>
        <filter
          id='b'
          x='-7.761'
          y='-23.838'
          width='50.56'
          height='50.598'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feGaussianBlur in='BackgroundImage' stdDeviation='11.919' />
          <feComposite
            in2='SourceAlpha'
            operator='in'
            result='effect1_backgroundBlur_859_73135'
          />
          <feBlend
            in='SourceGraphic'
            in2='effect1_backgroundBlur_859_73135'
            result='shape'
          />
        </filter>
        <filter
          id='c'
          x='-23.838'
          y='-23.838'
          width='50.56'
          height='50.598'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feGaussianBlur in='BackgroundImage' stdDeviation='11.919' />
          <feComposite
            in2='SourceAlpha'
            operator='in'
            result='effect1_backgroundBlur_859_73135'
          />
          <feBlend
            in='SourceGraphic'
            in2='effect1_backgroundBlur_859_73135'
            result='shape'
          />
        </filter>
      </defs>
    </svg>
  );
};

export default EllipsesIcon;
