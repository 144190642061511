import React, { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { userSelector } from 'reducers/rootReducer';
import styled from 'styled-components';
import useModal from 'hooks/useModal';
import LinkOut from 'icons/LinkOutIcon';
import NavLink from 'components/atoms/NavLink';
import CurateNFTModal from 'components/organisms/CurateNFTModal';
import { useMediaQuery } from 'react-responsive';
const MobileSandwichToggle = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 1;
  -webkit-user-select: none;
  user-select: none;
  margin-left: 1rem;
  input {
    display: flex;
    width: 40px;
    height: 32px;
    position: absolute;
    cursor: pointer;
    opacity: 0;
    z-index: 3;
  }
  input:checked ~ span {
    opacity: 1;
    transform: rotate(45deg) translate(-3px, -1px);
    background: ${({ theme }) => theme.colors.white};
  }
  input:checked ~ span:nth-last-child(3) {
    opacity: 0;
    transform: rotate(0deg) scale(0.2, 0.2);
  }
  input:checked ~ span:nth-last-child(2) {
    transform: rotate(-45deg) translate(0, -1px);
  }
  input:checked ~ ul {
    transform: translate(-20rem, 0);
  }

  span:first-child {
    transform-origin: 0% 0%;
  }

  span:nth-last-child(2) {
    transform-origin: 0% 100%;
  }
`;
const MobileSandwichLine = styled.span`
  display: flex;
  width: 29px;
  height: 2px;
  margin-bottom: 5px;
  position: relative;
  background: #ffffff;
  border-radius: 3px;
  z-index: 2;
  transform-origin: 5px 0px;
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
    background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
`;
const MobileMenu = styled.ul`
  position: absolute;
  padding: 3rem 3rem 2rem 3rem;
  border-radius: 24px;
  width: 20rem;
  top: 0;
  left: 100vw;
  background-color: ${({ theme }) => theme.colors.transparentDark};
  -webkit-font-smoothing: antialiased;
  transform: translateX(20rem);
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
  z-index: 1;
  li {
    /* padding: 0.25rem 0; */
    line-height: 2.5em;
    display: flex;
    justify-content: flex-start;
  }
  li a {
    color: ${({ theme }) => theme.colors.white};
    font-size: 1.25rem;
    font-weight: bold;
    display: flex;
  }
`;

const HeaderMenu = () => {
  const user = useSelector(userSelector);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 425px)' });
  const { openModal } = useModal();
  const toggleMobileMenu = useCallback(() => {
    setShowMobileMenu((s) => !s);
  }, []);
  const openCurationDialog = useCallback(() => {
    openModal(<CurateNFTModal />);
  }, [openModal]);
  return (
    <MobileSandwichToggle>
      <input
        type='checkbox'
        checked={showMobileMenu}
        onChange={toggleMobileMenu}
      />
      <MobileSandwichLine></MobileSandwichLine>
      <MobileSandwichLine></MobileSandwichLine>
      <MobileSandwichLine></MobileSandwichLine>
      <MobileMenu style={{ listStyleType: 'none' }}>
        {isTabletOrMobile && (
          <>
            {user && user.isCurator && (
              <li onClick={openCurationDialog}>
                <span
                  style={{
                    color: 'rgba(13, 236, 182, 0.8)',
                    fontSize: '1.25rem',
                    fontWeight: 'bold',
                  }}>
                  Curate
                </span>
              </li>
            )}
            <li>
              <NavLink to='/'>
                {' '}
                <span
                  style={{
                    color: 'rgba(13, 236, 182, 0.8)',
                    fontSize: '1.25rem',
                    fontWeight: 'bold',
                  }}>
                  RA!CE
                </span>
              </NavLink>
            </li>
            <li>
              <NavLink to='/leaderboard'>
                {' '}
                <span
                  style={{
                    color: 'rgba(13, 236, 182, 0.8)',
                    fontSize: '1.25rem',
                    fontWeight: 'bold',
                  }}>
                  Leaderboard
                </span>
              </NavLink>
            </li>
            <li>
              <a
                style={{
                  margin: '.25rem .5rem .25rem 0rem',
                  textAlign: 'center',
                  color: 'rgba(13, 236, 182, 0.8)',
                  // fontSize: '1.25rem',
                  fontWeight: 'bold',
                }}
                href='https://www.cryptovoxels.com/play?coords=W@3W,230S'
                target='_blank'
                rel='noreferrer'>
                Gallery
              </a>
            </li>
          </>
        )}
        <li>
          <a
            href='https://rarasocial.notion.site/FAQ-16cec19af71a4ab1bacbe27afa105e01'
            target='_blank'
            rel='noopener noreferrer'>
            FAQ&nbsp;{' '}
            <div style={{ height: '.8em', width: '.8em' }}>
              <LinkOut />
            </div>
          </a>
        </li>
        <li>
          <a
            href='https://docs.google.com/forms/d/1XmnMejgtWySe7_bgqSWtgGK651EGZcQJZmS7NL3I0QM/viewform?edit_requested=true'
            target='_blank'
            rel='noopener noreferrer'>
            Feedback&nbsp;{' '}
            <div style={{ height: '.8em', width: '.8em' }}>
              <LinkOut />
            </div>
          </a>
        </li>
        <li>
          <a
            href='https://discord.com/invite/cFNyya8YuA'
            target='_blank'
            rel='noopener noreferrer'>
            Discord &nbsp;{' '}
            <div style={{ height: '.8em', width: '.8em' }}>
              <LinkOut />
            </div>
          </a>
        </li>
        <li>
          <a
            target='_blank'
            rel='noreferrer'
            href={window.location.origin + '/terms.pdf'}>
            Terms & Conditions &nbsp;{' '}
            <div style={{ height: '.8em', width: '.8em' }}>
              <LinkOut />
            </div>
          </a>
        </li>
        <li>
          <a
            target='_blank'
            rel='noreferrer'
            href={window.location.origin + '/privacy.pdf'}>
            Privacy Policy &nbsp;{' '}
            <div style={{ height: '.8em', width: '.8em' }}>
              <LinkOut />
            </div>
          </a>
        </li>
      </MobileMenu>
    </MobileSandwichToggle>
  );
};

export default HeaderMenu;
