import React from 'react';
import Blockies from 'react-blockies';
import UserIcon from 'icons/UserIcon';
import { Image, Transformation } from 'cloudinary-react';

const UserImage = ({ user }) => {
  if (!user) {
    return <UserIcon />;
  }

  if (user.imageId) {
    return (
      <Image cloudName='rara-social' publicId={user.imageId + '.webp'}>
        <Transformation width='104' height='104' gravity='face' crop='thumb' />
      </Image>
    );
  }

  // profile image
  if (user.avatarURL && !user.avatarURL.endsWith('null.png')) {
    return <img src={user.avatarURL} alt='avatar' height='34px' width='34px' />;
  }
  // Blockie (scale = pxs/block, size=# of blocks => 4*10=40px)
  if (user.publicAddress) {
    return <Blockies seed={user.publicAddress} scale={4} size={10} />;
  }
  // no user
  return <UserIcon />;
};
export default UserImage;
