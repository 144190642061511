import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Image = styled.img`
display: flex,
position: absolute;
width: ${({ width }) => (width ? width : '100%')};
height: ${({ height }) => (height ? height : '100%')};
max-height: 100%;
object-fit: contain;
border-radius: 24px;
`;

const AssetImage = ({ imgUrl, name, cropped, width, height, onClick }) => {
  return (
    <Image
      src={imgUrl}
      alt={name}
      height={height}
      width={width}
      cropped={cropped}
      onClick={onClick}
    />
  );
};

AssetImage.propTypes = {
  imgUrl: PropTypes.string,
  name: PropTypes.string,
  cropped: PropTypes.bool,
};

export default AssetImage;
