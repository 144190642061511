import React, { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { userSelector } from 'reducers/rootReducer';
import useModal from 'hooks/useModal';
import ModalPage from 'components/atoms/ModalPage';
import CopyArea from 'components/atoms/CopyArea';
import useRequest from 'hooks/useRequest';
import Loader from 'components/atoms/Loader';

const ReferralLinkModal = () => {
  const user = useSelector(userSelector);
  const callApi = useRequest();
  const { closeModal } = useModal();
  const [refLink, setRefLink] = useState(
    `${process.env.REACT_APP_URL}/?ref=${
      user.referralCode
        ? user.referralCode
        : '[Generate a new referral code below]'
    }`
  );
  const [isLoading, setIsLoading] = useState(false);
  const BtnAction = useCallback(() => {
    if (
      user.referralCode ||
      !refLink.endsWith('[Generate a new referral code below]')
    ) {
      closeModal();
    } else {
      setIsLoading(true);
      callApi({ path: '/api/user/referrallink', method: 'GET' })
        .then((res) => {
          if (res.link)
            setRefLink(
              `${process.env.REACT_APP_URL}/?ref=${res.link.substring(
                res.link.lastIndexOf('share')
              )}`
            );
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [callApi, closeModal, user.referralCode, refLink]);
  return (
    <div style={{ color: 'black' }}>
      <ModalPage
        title={'Share to Earn'}
        closeAction={closeModal}
        headerNav={'close'}
        primaryBtnText={
          user.referralCode ||
          !refLink.endsWith('[Generate a new referral code below]') ? (
            'Close'
          ) : isLoading ? (
            <Loader type='bounce' />
          ) : (
            'Generate Link'
          )
        }
        primaryBtnDisabled={isLoading}
        primaryBtnAction={BtnAction}
        size={'m'}
      >
        <div>
          <p>
            Share your referral link with friends. For every new user signing up
            with this link, you and the new user will each earn 50 $RA!
          </p>
          {user.referralCode ||
          !refLink.endsWith('[Generate a new referral code below]') ? (
            <CopyArea value={refLink} p={'0'} />
          ) : (
            <></>
          )}
        </div>
      </ModalPage>
    </div>
  );
};

export default ReferralLinkModal;
