import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { AppActions } from 'reducers/appReducer';

const useModal = () => {
  const dispatch = useDispatch();
  const openModal = useCallback(
    (content) => {
      dispatch(AppActions.SET_MODAL(content));
    },
    [dispatch]
  );
  const closeModal = useCallback(() => {
    dispatch(AppActions.SET_MODAL(null));
  }, [dispatch]);
  return useMemo(() => ({ openModal, closeModal }), [openModal, closeModal]);
};

export default useModal;
