import styled, { css, keyframes } from 'styled-components';
import {
  layout,
  color,
  variant,
  border,
  space,
  typography,
} from 'styled-system';

const CycleGradient = keyframes`
  0% {
    background-position: 0% 0%;
  }
  50% {
    background-position: 100% 100%;
  }
  100% {
    background-position: 0% 0%;
  }
`;
const Vibrate = keyframes`
  0% {
    transform: translateY(0);
  }
  2.5% {
    transform: translateY(-.75px);
  }
  5% {
    transform: translateY(.75px);
  }
  7.5% {
    transform: translateY(-.75px);
  }
  10% {
    transform: translateY(.75px);
  }
  12.5% {
    transform: translateY(-.75px);
  }
  15% {
    transform: translateY(.75px);
  }
  17.5% {
    transform: translateY(-.75px);
  }
  20% {
    transform: translateY(.75px);
  }
  22.5% {
    transform: translateY(-.75px);
  }
  25% {
    transform: translateY(0);
  }
`;

const AnimationMixin = css`
  background-size: 400%;
  animation: ${CycleGradient} 4s ease-in-out infinite;
`;

const Badge = styled.div`
  border: 2px solid transparent;
  border-radius: 4rem;
  height: ${({ height }) => (height ? height : '100%')};
  width: ${({ width }) => (width ? width : '100%')};
  background: #000000;
  background-position: 100% 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ withAnimation }) => (withAnimation ? AnimationMixin : null)}
  ${layout}
  ${color}
  ${border}
  ${space}
  ${typography}
  ${variant({
    variants: {
      'gradient-bg': {
        background:
          'linear-gradient(45deg, #e901e9, #0decb6) padding-box, linear-gradient(45deg, #e901e9, #0decb6) border-box',
      },
      'gradient-border': {
        background:
          'linear-gradient(to top, rgba(12, 18, 38, 90%), #000000) padding-box, linear-gradient(45deg, #e901e9, #0decb6) border-box',
      },
      'gradient-border-light': {
        background:
          'linear-gradient(to top, #d4d5e1, #d4d5e1) padding-box, linear-gradient(45deg, #e901e9, #0decb6) border-box',
        color: 'black',
      },
    },
  })}
`;

export default Badge;
