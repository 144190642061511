export const SessionActions = {
  LOGOUT: () => ({ type: 'LOGOUT', payload: null }),
  SET_USER: (user) => ({ type: 'SET_USER', payload: user }),
  SET_WEB3: (web3) => ({ type: 'SET_WEB3', payload: web3 }),
  SET_SEAPORT: (seaport) => ({ type: 'SET_SEAPORT', payload: seaport }),
  SET_CONNECTED_ACCOUNT: (account) => ({
    type: 'SET_CONNECTED_ACCOUNT',
    payload: account,
  }),
  SET_NETWORK: ({ id, name, etherScanUrl }) => ({
    type: 'SET_NETWORK',
    payload: { id, name, etherScanUrl },
  }),
  SET_BALANCE: (walletPartial) => ({
    type: 'SET_BALANCE',
    payload: walletPartial,
  }),
  SET_WEB3_STATUS: (status) => ({ type: 'SET_WEB3_STATUS', payload: status }),
  SET_WEB3_ERROR: (error) => ({
    type: 'SET_WEB3_ERROR',
    payload: error ? { message: error } : null,
  }),
  SET_USER_STATUS: (status) => ({ type: 'SET_USER_STATUS', payload: status }),
  SET_USER_ERROR: (error) => ({
    type: 'SET_USER_ERROR',
    payload: error ? { message: error } : null,
  }),
  SET_PREFERENCES: (options) => ({ type: 'SET_PREFERENCES', payload: options }),
};

const initialState = {
  user: null, // User || null,
  preferences: {
    raIncrement: 1,
  },
  status: 'disconnected', // 'connecting' | 'connected' | 'error'
  error: null, // {message: String}
  web3: {
    instance: null, // Web3 || null
    seaport: null, // Seaport || null
    connectedAccount: '', // String
    network: null, // {id: Number, name: String} || null
    wallet: { eth: 0, weth: 0, ra: 0 }, // {eth: Number, weth: Number, ra: Number}
    status: 'disconnected', // 'connecting' | 'connected' | 'error'
    error: null, // {message: String}
  },
};

const sessionReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'LOGOUT':
      return initialState;
    case 'SET_USER': {
      return {
        ...state,
        status: 'connected',
        user: action.payload,
        error: null,
      };
    }
    case 'SET_PREFERENCES': {
      return {
        ...state,
        preferences: { ...state.preferences, ...action.payload },
      };
    }
    case 'SET_WEB3': {
      return {
        ...state,
        web3: { ...state.web3, instance: action.payload },
      };
    }
    case 'SET_SEAPORT': {
      return {
        ...state,
        web3: { ...state.web3, seaport: action.payload },
      };
    }
    case 'SET_CONNECTED_ACCOUNT':
      return {
        ...state,
        web3: { ...state.web3, connectedAccount: action.payload },
      };
    case 'SET_NETWORK':
      return {
        ...state,
        web3: { ...state.web3, network: action.payload },
      };
    case 'SET_BALANCE':
      return {
        ...state,
        web3: {
          ...state.web3,
          wallet: { ...state.web3.wallet, ...action.payload },
        },
      };
    case 'SET_WEB3_STATUS':
      return {
        ...state,
        web3: { ...state.web3, status: action.payload },
      };
    case 'SET_WEB3_ERROR':
      return {
        ...state,
        web3: { ...state.web3, error: action.payload },
      };
    default:
      return state;
  }
};

export default sessionReducer;
