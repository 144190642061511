export const AppActions = {
  SET_SOCKET: (socket) => ({ type: 'SET_SOCKET', payload: socket }),
  SET_PAGE: (page) => ({ type: 'SET_PAGE', payload: page }),
  SET_MUTE: (mute) => ({ type: 'SET_MUTE', payload: mute }),
  SET_MODAL: (content) => ({ type: 'SET_MODAL', payload: content }),
  SET_STATUS: (status) => ({ type: 'SET_STATUS', payload: status }),
  SET_ERROR: (error) => ({
    type: 'SET_ERROR',
    payload: error ? { message: error } : null,
  }),
};

const initialState = {
  socket: null, // Socket || null
  page: 'landing', // 'splash' | 'detail' | 'profile'
  muteSounds: false, // boolean
  modal: { isVisible: false, content: null },
  status: 'disconnected', // 'connecting' | 'connected' | 'error'
  error: null, // {message: string} || null
};

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_STATUS':
      return { ...state, status: action.payload };
    case 'SET_SOCKET':
      return {
        ...state,
        socket: action.payload,
        error: null,
      };
    case 'SET_ERROR':
      return {
        ...state,
        error: action.payload,
      };
    case 'SET_MUTE':
      return {
        ...state,
        muteSounds: action.payload,
      };
    case 'SET_PAGE':
      return {
        ...state,
        page: action.payload,
      };
    case 'SET_MODAL': {
      return {
        ...state,
        modal: {
          isVisible: !!action.payload ? true : false,
          content: action.payload,
        },
      };
    }
    default:
      return state;
  }
};

export default appReducer;
