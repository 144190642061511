import React from 'react';
import styled from 'styled-components';
import Button from 'components/atoms/Button';
import ArrowLeftIcon from 'icons/ArrowLeftIcon';
import XIcon from 'icons/XIcon';

const Page = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0.25rem 1.5rem;
  position: relative;
  height: ${({ size }) =>
    size === 'xs'
      ? '10rem'
      : size === 's'
      ? '13rem'
      : size === 'm'
      ? '16rem'
      : size === 'l'
      ? '22rem'
      : size === 'xl'
      ? '26rem'
      : size === 'xxl'
      ? '36rem'
      : '10rem'};
  max-height: ${({ size }) =>
    size === 'xs'
      ? '10rem'
      : size === 's'
      ? '13rem'
      : size === 'm'
      ? '16rem'
      : size === 'l'
      ? '22rem'
      : size === 'xl'
      ? '26rem'
      : size === 'xxl'
      ? '36rem'
      : '10rem'};
  min-height: ${({ size }) =>
    size === 'xs'
      ? '10rem'
      : size === 's'
      ? '13rem'
      : size === 'm'
      ? '16rem'
      : size === 'l'
      ? '22rem'
      : size === 'xl'
      ? '26rem'
      : size === 'xxl'
      ? '36rem'
      : '10rem'};
`;
const Header = styled.header`
  margin-bottom: 1rem;
  text-align: center;
`;
const BackBtn = styled.button`
  background: transparent;
  position: absolute;
  top: 0;
  left: 0;
`;
const CloseBtn = styled.button`
  background: transparent;
  position: absolute;
  top: 0;
  right: 0;
`;
const Title = styled.h3`
  margin: 0;
  margin-top: 1rem;
  padding: 0;
  color: black;
`;
const Content = styled.div`
  width: 100%;
  @media (max-width: 425px) {
    font-size: smaller;
  }
`;
const Footer = styled.footer`
  position: absolute;
  bottom: 0;
`;

const ModalPage = ({
  title,
  headerNav = true, // 'back', 'close', false
  primaryBtnText,
  primaryBtnAction,
  primaryBtnDisabled = false,
  secondaryBtnText,
  secondaryBtnAction,
  backAction,
  closeAction,
  size = 'xs', // 's', 'm', 'l'
  children,
}) => {
  return (
    <Page size={size}>
      <Header>
        {headerNav && headerNav !== 'close' && (
          <BackBtn onClick={backAction}>
            <ArrowLeftIcon />
          </BackBtn>
        )}
        <Title>{title}</Title>
        {headerNav && headerNav !== 'back' && (
          <CloseBtn onClick={closeAction}>
            <XIcon />
          </CloseBtn>
        )}
      </Header>
      <Content>{children}</Content>
      <Footer>
        {primaryBtnText && primaryBtnAction && (
          <Button
            borderRadius={'8px'}
            height={'3rem'}
            width={'100%'}
            bg={'rgba(13, 236, 182, 0.8)'}
            onClick={primaryBtnAction}
            disabled={primaryBtnDisabled}
          >
            {primaryBtnText}
          </Button>
        )}
        {secondaryBtnText && secondaryBtnAction && (
          <Button
            borderRadius={'8px'}
            height={'3rem'}
            width={'100%'}
            bg={'rgba(13, 236, 182, 0.8)'}
            mt={'1rem'}
            onClick={secondaryBtnAction}
          >
            {secondaryBtnText}
          </Button>
        )}
      </Footer>
    </Page>
  );
};
export default ModalPage;
