import React, { useState, useMemo } from 'react';
import styled, { withTheme } from 'styled-components';
import UserWallet from 'components/molecules/UserWallet.jsx';
import PageSlider from 'components/atoms/PageSlider';
import TransferAssetForm from 'components/molecules/transferAssetForm';

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.transparentDark};
  border-radius: 20px;
  width: 100%;
`;
const WalletModal = ({ closeModal, theme }) => {
  const [page, setPage] = useState('wallet'); // eth, weth
  const PAGES = useMemo(
    () => [
      (next, prev, key) => (
        <div key={key}>
          <UserWallet
            setPage={(p) => {
              setPage(p);
              next();
            }}
          />
        </div>
      ),
      (next, prev, key) =>
        page !== 'wallet' ? (
          <TransferAssetForm navBack={prev} assetType={page} key={key} />
        ) : null,
    ],
    [page]
  );
  return (
    <Wrapper>
      <PageSlider pages={PAGES} />
    </Wrapper>
  );
};

export default withTheme(WalletModal);
