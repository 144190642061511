import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import {
  userSelector,
  walletSelector,
  preferenceSelector,
} from 'reducers/rootReducer';
import Unit from 'components/atoms/Unit';
import Badge from '../_particles/Badge';
import AuthWrapper from 'components/atoms/AuthWrapper';
import RAForm from 'components/molecules/RAForm';

const Wrapper = styled(Badge)`
  display: flex;
  position: relative;
  height: ${({ size }) => (size === 'large' ? '3.5rem' : '40px')};
  width: ${({ size }) => (size === 'large' ? '100%' : '100%')};
`;

const Amount = styled.div`
  display: flex;
  align-items: center;
  align-self: center;
  text-align: center;
  justify-content: flex-start;
  padding-left: 8px;
  color: ${({ theme, textColor, valid }) => (!valid ? 'crimson' : textColor)};
  font-size: ${({ size }) => (size === 'large' ? '2em' : '1.1em')};
  font-weight: 800;
  margin-right: ${({ size }) => (size === 'large' ? '1.5rem' : '2.5rem')};
  cursor: default;
  transition: all 0.2s ease;
  /* @media (max-width: 1025px) {
    font-size: 1.8em;
  } */
  @media (max-width: 375px) {
    font-size: 1em;
  }
`;

export default function RaButton({ auction, size, setShowEarningsDropdown }) {
  const wallet = useSelector(walletSelector);
  const user = useSelector(userSelector);
  const preferences = useSelector(preferenceSelector);
  const [textColor, setTextColor] = useState('white');
  const earningsRef = useRef(0);
  // Effects for Text color switching
  useEffect(() => {
    if (
      auction &&
      auction.auctionResults &&
      auction.auctionResults[user?.publicAddress]
    ) {
      if (
        earningsRef.current !==
        auction.auctionResults[user.publicAddress].raEarned
      ) {
        setTextColor('rgba(13, 236, 182, 1)');
        earningsRef.current =
          auction.auctionResults[user.publicAddress].raEarned;
      }
    }
  }, [auction, user]);
  useEffect(() => {
    const flashTimer = setTimeout(() => {
      if (textColor !== 'white') {
        setTextColor('white');
      }
    }, 1000);
    return () => {
      clearTimeout(flashTimer);
    };
  }, [textColor]);

  return (
    <AuthWrapper>
      <Wrapper
        size={size}
        onMouseOver={() => {
          setShowEarningsDropdown(true);
        }}
        onMouseOut={() => {
          setShowEarningsDropdown(false);
        }}
      >
        <Amount
          size={size}
          textColor={textColor}
          valid={
            preferences &&
            preferences.raIncrement > 0 &&
            ((wallet.ra && preferences.raIncrement <= wallet.ra) || !wallet.ra)
          }
        >
          <span>
            {auction?.ra || 0}&nbsp;
            <Unit length={'abrv'} type={'ra'} fontSize='x-small' />
          </span>
        </Amount>
        <div>
          <RAForm
            auctionId={auction?._id}
            isDisabled={
              auction?.curator?.publicAddress === user?.publicAddress ||
              auction?.state !== 'ACTIVE'
            }
            size={size}
          />
          {/* <RaLaunchpad>
            {sentClapCount > 0 && (
              <RaAvatar
                numToDisplay={sentClapCount}
                // key={'ra-avatar' + i}
              />
            )}
          </RaLaunchpad> */}
        </div>
      </Wrapper>
    </AuthWrapper>
  );
}
