import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { useLocation, useParams } from '@reach/router';
import { useMediaQuery } from 'react-responsive';
import { AppActions } from 'reducers/appReducer';
import { appSelector } from 'reducers/rootReducer';
import AuctionCard from 'components/organisms/AuctionCard';
import RaraChat from 'components/organisms/RaraChat';
import Lightbox from 'components/atoms/Lightbox';
import ShareButton from 'components/atoms/ShareButton';
import SendIcon from 'icons/SendIcon';

const AuctionGrid = styled.div`
  display: grid;
  width: 100%;
  gap: 0.75rem 3rem;
  grid-template-areas:
    'community empty'
    'content chat';
  min-height: calc(100% - 1rem);
  padding: 0 clamp(1rem, 5%, 15%);
  padding-top: 1rem;
  @media (min-width: 900px) {
    grid-template-columns: 7fr 4fr;
    grid-template-rows: auto auto 1fr;
    grid-template-areas:
      'community  empty'
      'content  chat';
  }
  @media (max-width: 900px) {
    gap: 0.75rem 1rem;
    grid-template-rows: auto 1fr;
    grid-template-columns: auto;
    grid-template-areas:
      'community'
      'content'
      'chat';
    justify-items: center;
  }
`;
const Community = styled.div`
  grid-area: community;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`;
const Content = styled.div`
  grid-area: content;
`;
const Chat = styled.div`
  grid-area: chat;
  grid-template-rows: 1fr auto;
  place-items: center;
`;

const Auction = () => {
  const dispatch = useDispatch();
  const { page } = useSelector(appSelector);
  const location = useLocation();
  const { auctionId } = useParams();
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 900px)' });
  const [showLightbox, setShowLightbox] = useState(false);
  const [lightboxAsset, setLightboxAsset] = useState(null);
  const [auctionInfo, setAuctionInfo] = useState(null); // {assetName, curator}
  useEffect(() => {
    if (page !== 'detail') dispatch(AppActions.SET_PAGE('detail'));
  }, [page, dispatch]);
  const displayLightbox = useCallback((asset) => {
    setLightboxAsset(asset);
    setShowLightbox(true);
  }, []);
  const closeLightbox = useCallback(() => {
    setLightboxAsset(null);
    setShowLightbox(false);
  }, []);
  return (
    <AuctionGrid>
      <Community>
        <div></div>
        {!isTabletOrMobile && (
          <ShareButton
            title='SHARE NFT CURATION'
            successTitle='COPIED LINK!'
            icon={<SendIcon stroke='white' height='100%' width='100%' />}
            value={location.href}
          />
        )}
      </Community>
      <Content>
        <AuctionCard
          auctionId={auctionId}
          view={isTabletOrMobile ? 'mobile' : 'desktop'}
          withCommunity={false}
          page='detail'
          displayLightbox={displayLightbox}
          setAuctionInfo={setAuctionInfo}
          header={'curator'}
        />
      </Content>
      <Chat>
        {auctionInfo && auctionInfo.curator && !isTabletOrMobile && (
          <>{auctionInfo.curator}</>
        )}
        <RaraChat auctionId={auctionId} isMobile={isTabletOrMobile} />
        {isTabletOrMobile && (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              marginTop: '1rem',
            }}
          >
            <ShareButton
              title='SHARE NFT'
              successTitle='COPIED LINK!'
              value={location.href}
              icon={<SendIcon stroke='white' height='100%' width='100%' />}
            />
          </div>
        )}
      </Chat>
      {showLightbox && (
        <Lightbox
          size={isTabletOrMobile ? 'small' : 'large'}
          closeLightbox={closeLightbox}
          asset={lightboxAsset}
        ></Lightbox>
      )}
    </AuctionGrid>
  );
};

export default Auction;
