import React from 'react';
import styled from 'styled-components';
import BidSVG from 'icons/BidSVGIcon';
import Unit from 'components/atoms/Unit';
import AuthWrapper from 'components/atoms/AuthWrapper';
import Badge from '../_particles/Badge';
const Wrapper = styled(Badge)`
  display: flex;
  position: relative;
  height: ${({ size }) => (size === 'large' ? '3.5rem' : '40px')};
  width: ${({ size }) => (size === 'large' ? '100%' : '100%')};
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  position: absolute;
  height: ${({ size }) => (size === 'large' ? '4.3rem' : '3rem')};
  width: ${({ size }) => (size === 'large' ? '6rem' : '3rem')};
  left: ${({ size }) => (size === 'large' ? '-1.5rem' : '-.5rem')};
  top: ${({ size }) => (size === 'large' ? '' : '')};
  padding: 0;
  background: transparent;
  cursor: pointer;
`;

const Amount = styled.div`
  display: flex;
  align-items: center;
  align-self: center;
  text-align: center;
  justify-content: flex-start;
  padding-left: 8px;
  color: #ffffff;
  font-size: ${({ size }) => (size === 'large' ? '2em' : '1.1em')};
  font-weight: 800;
  margin-left: ${({ size }) => (size === 'large' ? '1.5rem' : '1.3rem')};
  cursor: default;
  @media (max-width: 375px) {
    font-size: 1em;
  }
`;

export default function BidForm({ auction, size }) {
  return (
    <AuthWrapper>
      <a href={auction.asset.externalLink} rel='noreferrer' target='_blank'>
        <Wrapper size={size}>
          <Button size={size}>
            <BidSVG />
          </Button>
          <Amount size={size}>
            <span>
              {auction.nextBid}&nbsp;
              {auction.source === 'Foundation' ? (
                <Unit length={'abrv'} type={'eth'} fontSize='x-small' />
              ) : (
                <Unit length={'abrv'} type={'weth'} fontSize='x-small' />
              )}
            </span>
          </Amount>
        </Wrapper>
      </a>
    </AuthWrapper>
  );
}
