import styled from 'styled-components';
import { layout } from 'styled-system';
const Row = styled.div`
  ${layout}
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: ${({ width }) => (width ? width : '100%')};
  height: 2.5rem;
  content-size: border-box;
  margin-top: ${({ mt }) => (mt ? mt : '0.25rem')};
  cursor: default;
  @media (max-width: 320px) {
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`;

export default Row;
