import { useState, useEffect, useCallback, useMemo } from 'react';
import { SessionActions } from 'reducers/sessionReducer';
import { useDispatch } from 'react-redux';
import getWalletBalance from 'actions//getWalletBalance';
import * as Sentry from '@sentry/react';
import io from 'socket.io-client';
import { toast } from 'react-toastify';
import Toast from 'components/molecules/Toast';

const useAuthSocket = () => {
  const dispatch = useDispatch();
  const [socket, setSocket] = useState(null);
  const [status, setStatus] = useState('disconnected');
  const [error, setError] = useState(null);

  const connect = useCallback(() => {
    setStatus('connecting');
    const userSocket = io('/user');
    if (userSocket) {
      userSocket.on('connect', (info) => {
        setStatus('connected');
      });
      userSocket.on('connect_error', (info) => {
        console.log('auth socket connect_error', info);
        toast.error(
          <Toast title='Error' text="Couldn't connect to the RARA server" />
        );
        setStatus('error');
        setError(info);
      });
      userSocket.on('disconnect', (info) => {
        // console.log('disconnect user socket', info);
        userSocket.disconnect();
      });
      userSocket.on('user-update', async (msg) => {
        dispatch(SessionActions.SET_USER(msg.user));
        dispatch(getWalletBalance());
      });
      setSocket(userSocket);
      userSocket.emit('user');
    }
  }, [dispatch]);

  const disconnect = useCallback(() => {
    socket && socket.disconnect();
    setSocket(null);
    setStatus('disconnected');
  }, [socket]);

  useEffect(() => {
    if (error) {
      Sentry.captureException('userSocket error:', error);
      setError(null);
    }
  }, [error]);

  return useMemo(
    () => ({
      socket,
      status,
      connect,
      disconnect,
    }),
    [socket, status, connect, disconnect]
  );
};

export default useAuthSocket;
