import React, { useMemo } from 'react';
import styled from 'styled-components';
import AuctionTopUser from '../molecules/AuctionTopUser';
import AuctionCardRow from '../molecules/AuctionCardRow';
import AuctionCardColumn from '../molecules/AuctionCardColumn';
import useAuctionContext from 'hooks/useAuctionContext';

const Wrapper = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: ${({ view }) =>
    view === 'list' || view === 'mobile'
      ? '100%'
      : 'calc(40% - .5rem) calc(40% - .5rem)'};
  grid-column-gap: calc(20% + 1rem);
  padding: ${({ view }) =>
    view === 'list' || view === 'mobile'
      ? '0 1rem 1rem 1rem'
      : '.5rem 3rem 1rem 3rem'};
  @media (max-width: 1280px) {
    grid-template-columns: ${({ view }) =>
      view === 'list' || view === 'mobile'
        ? '100%'
        : 'calc(50% - .5rem) calc(50% - .5rem)'};
    grid-column-gap: calc(1rem);
  }
  @media (max-width: 320px) {
    padding: 0;
    margin: 0 1rem 1rem 1rem;
    width: calc(100% - 2rem);
    justify-self: start;
  }
`;

const AuctionSocials = () => {
  const { auction, view } = useAuctionContext();
  const TopFans = useMemo(() => {
    if (auction && auction.fans) {
      return auction.fans
        .sort((a, b) => {
          return b.ras?.spent - a.ras?.spent;
        })
        .filter((f) => {
          return f.user.publicAddress !== auction.curator.publicAddress;
        })
        .slice(0, 5)
        .map((u) => {
          let fan = { user: { ...u.user } };
          if (u.ras) fan.amount = u.ras.spent;
          return fan;
        });
    } else {
      return [];
    }
  }, [auction]);
  const FirstFans = useMemo(() => {
    if (auction && auction.fans) {
      return auction.fans
        .filter((f) => f.user.publicAddress !== auction.curator.publicAddress)
        .sort((a, b) => Date.parse(a.firstRa) - Date.parse(b.firstRa))
        .slice(0, 5);
    }
  }, [auction]);
  const TopBids = useMemo(
    () =>
      auction && auction.bids
        ? auction.bids
            .slice()
            .sort((a, b) => b.bidAmount - a.bidAmount)
            .map((b) => ({ ...b, id: b.user }))
        : [],
    [auction]
  );
  const UniqueBidders = useMemo(() => {
    return auction && auction.bids
      ? Object.values(
          auction.bids.reduce((a, c) => {
            if (a[c.user]) {
              a[c.user].bidAmount = Math.max(c.bidAmount, a[c.user].bidAmount);
            } else {
              a[c.user] = c;
            }
            return a;
          }, {})
        ).sort((a, b) => b.bidAmount - b.bidAmount)
      : [];
  }, [auction]);
  return (
    <Wrapper view={view}>
      {view === 'list' && (
        <>
          {/* <AuctionTopUser
            type={'Bid'}
            user={
              auction && auction.highBid_displayName
                ? {
                    avatarURL: auction.highBid_avatarURL,
                    displayName: auction.highBid_displayName,
                    publicAddress: auction.highBid_publicAddress,
                    imageId: auction.highBid_imageId,
                  }
                : null
            }
            emptyLabel={'Be the first to bid'}
            amount={
              auction && auction.highBid_amount ? auction.highBid_amount : 0
            }
            label='Top Offers'
          /> */}
          <AuctionCardRow
            label={
              auction && auction.fans ? `${auction.fans.length - 1} Fans` : ''
            }
            users={TopFans}
            emptyLabel={auction ? 'Become the first fan' : ''}
          />
        </>
      )}
      {view === 'mobile' && (
        <>
          <AuctionTopUser
            type={'Bid'}
            user={
              auction && auction.highBid_displayName
                ? {
                    avatarURL: auction.highBid_avatarURL,
                    displayName: auction.highBid_displayName,
                    publicAddress: auction.highBid_publicAddress,
                    imageId: auction.highBid_imageId,
                  }
                : null
            }
            amount={auction ? auction.highBid_amount : null}
            label='Top Offers'
            emptyLabel=''
          />
          <AuctionCardRow users={UniqueBidders.slice(1)} />
          <AuctionCardRow
            label={
              auction && auction.fans ? `${auction.fans.length - 1} Fans` : ''
            }
            users={TopFans}
            emptyLabel={'Be the first fan'}
          />
          <AuctionCardRow label='Early Fans' users={FirstFans} />
        </>
      )}
      {view === 'desktop' && (
        <>
          <div style={{ gridColumn: '1' }}>
            <AuctionCardColumn
              users={TopBids.slice(0, 3)}
              highlightCount={3}
              label={''}
              type='Bid'
              showAdditional={false}
            />
          </div>
          <div style={{ gridColumn: '2' }}>
            <AuctionCardColumn
              label={
                auction && auction.fans ? `${auction.fans.length - 1} Fans` : ''
              }
              type='Fans'
              users={TopFans}
              highlightCount={1}
              showAdditional={true}
            />
            <AuctionCardRow
              mt={'0rem'}
              label='First Fans'
              users={FirstFans}
              numToDisplay={3}
            />
          </div>
        </>
      )}
    </Wrapper>
  );
};

export default AuctionSocials;
