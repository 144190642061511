import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import styled, { withTheme } from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import { sessionSelector, userSelector } from 'reducers/rootReducer';
import useModal from 'hooks/useModal';
import NavLink from 'components/atoms/NavLink';
import RaLogo from 'icons/RARALogo';
import LinkOutIcon from 'icons/LinkOutIcon';
import UserProfileSmall from 'components/atoms/UserProfileSmall';
import { AppActions } from 'reducers/appReducer';
import AuthModal from 'components/molecules/ConnectWallet';
import HeaderMenu from 'components/molecules/HeaderMenu';
import HeaderItem from 'components/atoms/HeaderItem';
import Badge from 'components/_particles/Badge';
import CurateNFTModal from 'components/organisms/CurateNFTModal';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 2rem 0.5rem 2rem;
  /* border-bottom: 1px solid grey; */
  box-sizing: border-box;
  box-shadow: 0 1px 25px rgba(13, 86, 195, 0.25);
`;

const NavList = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  list-style: none;
`;
const NavListItem = styled.li`
  padding-left: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

function Header({ theme }) {
  const session = useSelector(sessionSelector);
  const user = useSelector(userSelector);
  const dispatch = useDispatch();
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 425px)' });
  const { openModal } = useModal();
  const openAuthDialog = useCallback(() => {
    openModal(<AuthModal />);
  }, [openModal]);
  const onNavigate = useCallback(
    (page) => {
      dispatch(AppActions.SET_PAGE(page));
    },
    [dispatch]
  );
  const openCurationDialog = useCallback(() => {
    openModal(<CurateNFTModal />);
  }, [openModal]);

  return (
    <header>
      <Wrapper>
        <NavLink
          to='/'
          style={{ padding: 0 }}
          onClick={() => onNavigate('landing')}>
          <div style={{ height: '50px', width: '50px' }}>
            <RaLogo />
          </div>
        </NavLink>
        <NavList>
          {user && user.isCurator && !isTabletOrMobile && (
            <HeaderItem>
              <Badge
                bg={'transparent'}
                border={'2px solid orange'}
                borderColor={'green'}
                p={'.4rem 2.5rem'}
                style={{ cursor: 'pointer', fontWeight: 'bold' }}
                onClick={openCurationDialog}>
                Curate
              </Badge>
            </HeaderItem>
          )}
          {!isTabletOrMobile && (
            <>
              <HeaderItem tab={'landing'}>
                <NavLink to='/' onClick={() => onNavigate('landing')}>
                  <div
                    style={{
                      margin: '.25rem 1rem',
                      fontSize: '1.25rem',
                      fontWeight: 'bold',
                      textAlign: 'center',
                      color: 'white',
                    }}>
                    RA!CE
                  </div>
                </NavLink>
              </HeaderItem>
              <HeaderItem tab={'leaderboard'}>
                <NavLink
                  to='/leaderboard'
                  onClick={() => onNavigate('leaderboard')}>
                  <div
                    style={{
                      margin: '.25rem 1rem',
                      fontSize: '1.25rem',
                      fontWeight: 'bold',
                      textAlign: 'center',
                      color: 'white',
                    }}>
                    Leaderboard
                  </div>
                </NavLink>
              </HeaderItem>
              <HeaderItem
                tab={'gallery'}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}>
                <a
                  style={{
                    margin: '.25rem .5rem .25rem 0rem',
                    fontSize: '1.25rem',
                    fontWeight: 'bold',
                    textAlign: 'center',
                    color: 'white',
                  }}
                  href='https://www.cryptovoxels.com/play?coords=W@3W,230S'
                  target='_blank'
                  rel='noreferrer'>
                  Gallery
                </a>
                <div
                  style={{
                    height: '1rem',
                    width: '1rem',
                  }}>
                  <LinkOutIcon />
                </div>
              </HeaderItem>
            </>
          )}
          {session.status === 'connected' ? (
            <HeaderItem tab={'profile'}>
              <NavLink
                to={'/profile/' + user.publicAddress}
                style={{ padding: 0, color: 'white' }}
                onClick={() => onNavigate('profile')}>
                <UserProfileSmall />
              </NavLink>
            </HeaderItem>
          ) : (
            <NavListItem>
              <button
                onClick={openAuthDialog}
                style={{ background: '#f2f2f2' }}>
                Connect
              </button>
            </NavListItem>
          )}
          <HeaderMenu />
        </NavList>
      </Wrapper>
    </header>
  );
}

export default withTheme(Header);
