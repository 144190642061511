const getParams = (location) => {
  if (!location.search) {
    return '';
  }

  return getJsonFromUrl(location.search);
};

const getJsonFromUrl = (search) => {
  var query = search.substr(1);
  var result = {};
  query.split('&').forEach(function (part) {
    var item = part.split('=');
    result[item[0]] = decodeURIComponent(item[1]);
  });
  return result;
};

export default getParams;
