import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { networkSelector } from 'reducers/rootReducer';
import './TransferAssets.scss';

import { Button, Form, PageHeader } from 'antd';
import AddressInput from 'components/molecules/AddressInput';
import getParams from 'helpers/getURLParams';
import ArrowRightIcon from 'icons/ArrowRightIcon';
import Loader from 'components/atoms/Loader';
import Unit from 'components/atoms/Unit';

// Context
import * as Sentry from '@sentry/react';
import useWeb3 from 'hooks/useWeb3';
import useRequest from 'hooks/useRequest';

export default function SendForm({ assetType }) {
  const callApi = useRequest();
  const { etherScanUrl } = useSelector(networkSelector);
  const { transferAsset } = useWeb3();

  const { id } = getParams(window.location);
  const [asset, setAsset] = useState(null);
  const [loadingAsset, setLoadingAsset] = useState(true);
  const [loadingError, setLoadingError] = useState(null);
  useEffect(() => {
    callApi({ method: 'GET', path: '/api/user/asset/' + id })
      .then((bid) => {
        console.log(bid);
        if (bid.auction) setAsset(bid.auction.asset);
        console.log(bid.auction.asset);
        setLoadingAsset(false);
      })
      .catch((error) => {
        console.log(error);
        setLoadingError(error.toString());
        setLoadingAsset(false);
      });
  }, [id, callApi]);

  // Form Status
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [complete, setComplete] = useState(false);
  const [error, setError] = useState(null);
  const [txnHash, setTxnHash] = useState(null);
  const onSubmit = async ({ destinationAddress }) => {
    setLoading(true);
    setComplete(false);
    setTxnHash(null);
    setError(null);

    // ERC721
    try {
      const receipt = await transferAsset({
        asset: {
          tokenId: asset.tokenId,
          tokenAddress: asset.tokenAddress,
          schemaName: asset.assetContract.schemaName,
        },
        destinationAddress,
        assetType: 'nft',
      });
      setTxnHash(receipt.transactionHash ? receipt.transactionHash : receipt);
      setComplete(true);
      setLoading(false);
    } catch (error) {
      console.log(error);
      Sentry.captureException(error);
      setError(error.message);
      setLoading(false);
    }
  };
  const onSubmitFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <React.Fragment>
      {loadingAsset ? <Loader type='bounce' /> : null}
      {loadingError ? <p>{loadingError}</p> : null}
      {asset ? (
        <Form
          name='sendForm'
          layout={'vertical'}
          form={form}
          onFinish={onSubmit}
          onFinishFailed={onSubmitFailed}
          className='transfer-form'
          requiredMark={false}
        >
          <PageHeader
            title={
              <h2 style={{ margin: 0 }}>
                Send <Unit type={'nft'} length='abrv' />
              </h2>
            }
          ></PageHeader>
          <a
            href={asset.openseaLink}
            target='_blank'
            rel='noreferrer'
            className='content-card asset-preview'
            // style={{ display: 'grid', gridTemplateRows: 'auto 1fr auto' }}
          >
            <aside className='panel'>
              <img
                src={asset.imageUrl}
                alt={asset.name}
                className='item-image'
              />
            </aside>
            <article className='item-image-label'>
              <h3 style={{ color: 'white', margin: 0 }}>{asset.name}</h3>
              <span
                style={{
                  color: '#90A0D3',
                  fontSize: '.9em',
                  fontWeight: 'bold',
                }}
              >
                {asset.collection.name}
              </span>
            </article>
          </a>
          <div className='mb-1' />

          <Form.Item
            label={<FormItemHeader text={'Destination Address'} />}
            name='destinationAddress'
            rules={[
              {
                required: true,
                message: 'Please enter destination address',
              },
            ]}
          >
            <AddressInput />
          </Form.Item>

          {loading && (
            <div className='transfer-form-loading-info'>
              <p style={{ margin: 0 }}>
                Your transaction is being broadcast to the blockchain. Please
                sign transaction.
              </p>
            </div>
          )}
          <Form.Item
            style={{
              marginTop: '1rem',
              alignSelf: 'flex-end',
            }}
          >
            <Button
              type='primary'
              htmlType='submit'
              size='large'
              className='transfer-form-send-btn'
              icon={
                loading ? (
                  <Loader type='bounce' />
                ) : (
                  <div className='flex-center'>
                    <ArrowRightIcon />
                  </div>
                )
              }
              shape={'circle'}
              disabled={loading || complete}
            ></Button>
          </Form.Item>

          {error && <code>{error}</code>}
          {complete && (
            <div>
              <p className='dont-break-out'>
                Transaction Complete.
                <a
                  href={etherScanUrl + txnHash}
                  rel='noreferrer'
                  target='_blank'
                >
                  View on Etherscan
                </a>
              </p>
            </div>
          )}
          <div className='mb-4'></div>
        </Form>
      ) : null}
    </React.Fragment>
  );
}

const FormItemHeader = ({ text, color = '#90A0D3' }) => {
  return <h3 className='form-item-header'>{text}</h3>;
};
