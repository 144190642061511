import React from 'react';
const ArrowRightIcon = () => {
  return (
    <svg
      width='25'
      height='18'
      viewBox='0 0 25 18'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M19.1716 10C20.0625 10 20.5086 11.0772 19.8787 11.7071L16 15.5858C15.6095 15.9763 15.6095 16.6095 16 17C16.3905 17.3905 17.0237 17.3905 17.4142 17L24.7071 9.70712C25.0976 9.3166 25.0976 8.68343 24.7071 8.29291L17.4142 1.00002C17.0237 0.609492 16.3905 0.609492 16 1.00002C15.6095 1.39054 15.6095 2.0237 16 2.41423L19.8787 6.29291C20.5086 6.92287 20.0625 8.00002 19.1716 8.00002H1C0.447715 8.00002 0 8.44773 0 9.00002C0 9.5523 0.447713 10 0.999998 10H19.1716Z'
        fill='white'
      />
    </svg>
  );
};

export default ArrowRightIcon;
