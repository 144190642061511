import React, { useMemo } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import AssetImage from '../atoms/AssetImage';
import ReactPlayer from 'react-player';
import InfoPanel from '../_particles/InfoPanel';
import useAuctionContext from 'hooks/useAuctionContext';
import RaLogo from 'icons/RARALogo';
import MediaPlayer from 'components/molecules/MediaPlayer';

const Wrapper = styled.div`
  position: relative;
  height: ${({ cropped }) => (cropped ? 'auto' : '45vh')};
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(110, 119, 149, 0.1);
  border-radius: 0 0 24px 24px;
  overflow: hidden;
  cursor: pointer;
  /* border: 1px solid white; */
  margin-bottom: ${({ view, page }) => (page === 'detail' ? '1rem' : '0rem')};
`;

const BlankAsset = styled(InfoPanel)`
  height: ${({ cropped }) => (cropped ? '300px' : '100%')};
  width: 100%;
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const AuctionAsset = ({ clickHandler }) => {
  const { auction, view, page } = useAuctionContext();
  const imageCropped = useMemo(() => view !== 'desktop', [view]);
  if (!auction)
    return (
      <Wrapper cropped={imageCropped} view={view} page={page}>
        <BlankAsset cropped={imageCropped}>
          {/* <div style={{ height: '20%', width: '20%' }}>
            <RaLogo />
          </div> */}
          {/* Auction Loading... */}
        </BlankAsset>
      </Wrapper>
    );
  return (
    <Wrapper cropped={imageCropped} view={view} page={page}>
      {auction.asset &&
      auction.asset.animationURL &&
      ReactPlayer.canPlay(auction.asset.animationURL) ? (
        <MediaPlayer
          asset={auction.asset}
          imageCropped={imageCropped}
          clickHandler={clickHandler}
        />
      ) : auction.asset && auction.asset.imageUrl ? (
        <AssetImage
          imgUrl={auction.asset.imageUrl}
          name={auction.asset.name}
          cropped={imageCropped}
          // height={imageCropped ? '80%' : '300px'}
          height={imageCropped ? '300px' : '300px'}
          width={imageCropped ? '100%' : '80%'}
          onClick={clickHandler}
        />
      ) : (
        <BlankAsset cropped={imageCropped}>
          <div style={{ height: '20%', width: '20%' }}>
            <RaLogo />
          </div>
          Asset Image Error
        </BlankAsset>
      )}
    </Wrapper>
  );
};

AuctionAsset.propTypes = {
  clickHandler: PropTypes.func.isRequired,
};

export default AuctionAsset;
