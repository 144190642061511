import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';
import DiscordMessage from 'components/atoms/DiscordMessage';
import useChatRoom from 'hooks/useChatRoom';
import './Chat.scss';

const ChatContainer = styled.div`
  color: #fff;
  background-color: #36393e;
  border-radius: 20px;
  overflow: hidden;
  width: 30vw;
  @media (max-width: 900px) {
    width: 23rem;
  }
  @media (max-width: 375px) {
    width: 20rem;
  }
  @media (max-width: 320px) {
    width: 18rem;
  }
`;
const ChannelContainer = styled.div`
  padding: 0.667rem 0.667rem 0.5rem 1rem;
  background-color: rgb(47, 49, 54);
  box-shadow: 0 0px 5px 1px #1a1b1d;
  margin-bottom: 3px;
  display: flex;
  align-content: center;
  font-size: 17px;
`;
const ChannelName = styled.div`
  color: #fff;
  font-weight: 700;
  line-height: 28px;
`;
const DiscordMessages = styled.div`
  display: block;
  font-size: 16px;
  font-family: Whitney, Roboto, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  line-height: 170%;
  border: 1px solid rgba(255, 255, 255, 0.05);
  border-top: none;
  // added by mgl
  flex-grow: 1;
  /* height: calc(68vh - 1px); */
  height: calc(45vh - 1px);
  overflow-y: auto;
`;
const DiscordInputContainer = styled.div`
  padding: 0.667rem;
  background-color: rgb(47, 49, 54);
  text-align: center;
  line-height: 28px;
`;

function RaraChat({ auctionId, isMobile }) {
  const { chat, chatStatus, chatError, channel } = useChatRoom(auctionId);
  return (
    <div>
      <ChatContainer>
        <ChannelContainer>
          <span
            style={{
              color: '#aaa',
              fontWeight: '400',
              fontSize: '22px',
              paddingRight: '6px',
            }}
          >
            #{' '}
          </span>
          {channel && channel.name ? (
            <ChannelName>{channel && channel.name}</ChannelName>
          ) : null}
        </ChannelContainer>

        {chatStatus === 'error' ? (
          <p className='robot-label' style={{ textAlign: 'center' }}>
            {chatError}
          </p>
        ) : null}

        <div>
          <DiscordMessages>
            {chat.map((item) => {
              return (
                <DiscordMessage
                  key={item.id}
                  message={item}
                  emojiId={channel && channel.raEmojiId}
                ></DiscordMessage>
              );
            })}
            {!isMobile && <AlwaysScrollToBottom />}
            <div className='mb-3'></div>
          </DiscordMessages>
        </div>

        <DiscordInputContainer>
          {channel && channel.guild ? (
            <ChannelName className='highlight'>
              <a
                href={'http://discord.gg/' + (channel && channel.inviteCode)}
                target='_blank'
                rel='noopener noreferrer'
              >
                🔗 Join {channel.guild.name} to chat!
              </a>
            </ChannelName>
          ) : null}
        </DiscordInputContainer>
      </ChatContainer>
    </div>
  );
}

const AlwaysScrollToBottom = () => {
  const elementRef = useRef();
  useEffect(() => elementRef.current.scrollIntoView());
  return <div ref={elementRef} />;
};

export default RaraChat;
