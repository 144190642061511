import React, { useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { userSelector } from 'reducers/rootReducer';
import { AppActions } from 'reducers/appReducer';
import { SessionActions } from 'reducers/sessionReducer';
import * as Sentry from '@sentry/react';
import { Input, Button, Form, PageHeader, Upload, message } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import useModal from 'hooks/useModal';
import UserImage from 'components/atoms/UserImage';
import ArrowRightIcon from 'icons/ArrowRightIcon';
import { Spin } from 'antd';

// import { UsersModule } from '@magic-sdk/admin/dist/modules/users';
import useRequest from 'hooks/useRequest';
export default function ProfileUpdateForm() {
  const callApi = useRequest();
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const { closeModal } = useModal();
  // const
  const [cloudinaryResponse, setCloudinaryResponse] = useState({});
  const [localImage, setLocalImage] = useState(user.profile.imageId);

  // Form Functions
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [complete, setComplete] = useState(false);
  const [error, setError] = useState(null);
  const [uploadingImage, setUploadingImage] = useState(false);
  // Submit
  const onSubmit = async ({ displayName, avatarURL }) => {
    setLoading(true);
    setComplete(false);
    setError(null);

    callApi({
      method: 'PUT',
      path: '/api/user/profile',
      body: { displayName, imageId: cloudinaryResponse.public_id },
    })
      .then((user) => {
        setLoading(false);
        setComplete(true);
        dispatch(SessionActions.SET_USER(user));
        closeModal();
      })
      .catch((error) => {
        console.log(error);
        setError(error.message);
        Sentry.captureException(error);
        setLoading(false);
      });
  };

  const onSubmitFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  function beforeUpload(file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('Image must be JPG or PNG format');
    }
    const isLt2M = file.size / 1024 / 1024 < 4;
    if (!isLt2M) {
      message.error('Image must smaller than 4MB');
    }
    return isJpgOrPng && isLt2M;
  }

  const handleChange = (info) => {
    if (info.file.status === 'uploading') {
      setUploadingImage(true);
      return;
    }
    if (info.file.status === 'done') {
      setUploadingImage(false);
    }
  };

  const uploadImage = async (options) => {
    const { onSuccess, onError, file } = options;

    // get signature for upload
    let signResponse,
      signData = null;
    try {
      signResponse = await fetch('/api/user/uploadsignature');
      signData = await signResponse.json();
    } catch (error) {
      onError({ event: error });
    }

    // build form data
    const formData = new FormData();
    formData.append('upload_preset', 'ml_default');
    formData.append('file', file);
    formData.append('api_key', signData.apiKey);
    formData.append('timestamp', signData.timestamp);
    formData.append('signature', signData.signature);

    // send to cloudinary
    const cloudName = 'rara-social';
    const uploadType = 'image';
    fetch(`https://api.cloudinary.com/v1_1/${cloudName}/${uploadType}/upload`, {
      method: 'POST',
      body: formData,
    })
      .then((res) => {
        onSuccess(file);
        return res.json();
      })
      .then((res) => {
        setCloudinaryResponse(res);
        setLocalImage(res.public_id);
      })
      .catch((err) => {
        const error = new Error(err);
        onError({ event: error });
      });
  };

  if (!user) {
    return null;
  }

  const uploadButton = (
    <div>
      {uploadingImage ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>
        {uploadingImage ? 'Uploading...' : 'Upload'}
      </div>
    </div>
  );

  return (
    <Form
      name='profileForm'
      layout={'vertical'}
      form={form}
      initialValues={{
        displayName: user.profile.displayName,
        avatarURL: user.profile.avatarURL,
      }}
      onFinish={onSubmit}
      onFinishFailed={onSubmitFailed}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        position: 'relative',
      }}
      requiredMark={false}
    >
      <PageHeader
        title={<h2 style={{ margin: 0 }}>Update Profile</h2>}
      ></PageHeader>

      <Form.Item
        label={<FormItemHeader text={'Display Name'} />}
        rules={[
          { max: 24, message: 'Username must be less than 24 characters.' },
        ]}
        name='displayName'
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Input
          style={{
            boxSizing: 'border-box',
            height: '2.75rem',
            alignItems: 'center',
          }}
          size={'large'}
        />
      </Form.Item>

      <Form.Item
        label={<FormItemHeader text={'Profile Photo (click to change)'} />}
        name='avatarUrl'
        style={{
          marginBottom: 0,
          textAlign: 'center',
          borderRadius: '50%',
        }}
      >
        <Upload
          name='avatar'
          listType='picture-card'
          className='avatar-uploader'
          showUploadList={false}
          customRequest={uploadImage}
          beforeUpload={beforeUpload}
          onChange={handleChange}
        >
          {localImage && !uploadingImage ? (
            <UserImage
              user={{
                publicAddress: user.publicAddress,
                imageId: localImage,
              }}
            />
          ) : (
            uploadButton
          )}
        </Upload>
      </Form.Item>

      <Form.Item
        style={{
          alignSelf: 'flex-end',
        }}
      >
        <Button
          type='primary'
          htmlType='submit'
          size='large'
          style={{
            background: 'linear-gradient(to bottom right, #470DC3, #0D56C3)',
            borderColor: 'white',
            height: '3rem',
            width: '3rem',
            alignSelf: 'flex-end',
          }}
          icon={
            loading ? (
              <Spin
                indicator={antIcon}
                size={'large'}
                style={{
                  marginTop: '2px',
                }}
              />
            ) : (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <ArrowRightIcon />
              </div>
            )
          }
          shape={'circle'}
          disabled={uploadingImage || loading || complete}
        ></Button>
      </Form.Item>

      {error && <code>{error}</code>}
      {complete && (
        <div>
          <p className='dont-break-out'>Complete.</p>
        </div>
      )}
      <div className='mb-4'></div>
    </Form>
  );
}

const FormItemHeader = ({ text, color = '#90A0D3' }) => {
  return (
    <h3
      style={{
        color,
        margin: '0 0 0 1rem',
        padding: 0,
        // border: '1px solid green',
        flex: 1,
      }}
    >
      {text}
    </h3>
  );
};

const antIcon = <LoadingOutlined style={{ color: '#ffffff' }} spin />;

// import { Upload, message } from 'antd';
// import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';

// class Avatar extends React.Component {
//   state = {
//     loading: false,
//   };

//   render() {
//     const { loading, imageUrl } = this.state;
// const uploadButton = (
//   <div>
//     {loading ? <LoadingOutlined /> : <PlusOutlined />}
//     <div style={{ marginTop: 8 }}>Upload</div>
//   </div>
// );
//     return (
//       <Upload
//         name='avatar'
//         listType='picture-card'
//         className='avatar-uploader'
//         showUploadList={false}
//         action='https://www.mocky.io/v2/5cc8019d300000980a055e76'
//         beforeUpload={beforeUpload}
//         onChange={this.handleChange}>
//         {imageUrl ? (
//           <img src={imageUrl} alt='avatar' style={{ width: '100%' }} />
//         ) : (
//           uploadButton
//         )}
//       </Upload>
//     );
//   }
// }

// ReactDOM.render(<Avatar />, mountNode);
