import React from 'react';
import Track from '../_particles/Track';
import AuctionAvatar from '../atoms/AuctionAvatar';
// user props for AuctionAvatar are based off of user.profile virtual, derived from userReactions on the auction record
const UserTrack = ({ users, numToDisplay = 6, emptyLabel, margin }) => {
  const withEllipse = users ? users.length >= 7 : false;
  const emptyRowStyle =
    users.length === 0 ? { justifyContent: 'center' } : null;
  return (
    <Track
      style={{
        ...emptyRowStyle,
      }}
    >
      {users && users.length ? (
        withEllipse ? (
          <>
            {users.slice(0, numToDisplay).map((u, i) => {
              return (
                <AuctionAvatar
                  mx={'.5rem'}
                  user={u.user}
                  key={`user-profile-${i}-${u.user.id}`}
                />
              );
            })}
            <>
              ...
              {/* TODO: replace this ellipse with a component/svg ellipse icon */}
            </>
          </>
        ) : (
          users.slice(0, numToDisplay).map((u, i) => {
            return (
              <AuctionAvatar
                mx={'.5rem'}
                user={u.user}
                key={`user-profile-${i}-${u.user.id}`}
              />
            );
          })
        )
      ) : (
        <span
          style={{
            color: 'white',
          }}
        >
          {emptyLabel}
        </span>
      )}
    </Track>
  );
};

export default UserTrack;
