import React from 'react';
import styled from 'styled-components';
import ToastSuccessIcon from 'icons/ToastSuccessIcon';
import ToastErrorIcon from 'icons/ToastErrorIcon';
import ToastInfoIcon from 'icons/ToastInfoIcon';
import ToastWarnIcon from 'icons/ToastWarnIcon';
const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 5fr;
  gap: 0.5rem;
  place-items: center;
`;
const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  font-size: 0.9em;
  line-height: 1.3em;
`;
const Title = styled.h3`
  margin: 0;
  font-weight: bold;
`;
const Text = styled.span``;

const toastIcons = {
  success: <ToastSuccessIcon />,
  error: <ToastErrorIcon />,
  info: <ToastInfoIcon />,
  warning: <ToastWarnIcon />,
  default: 'bg-indigo-600',
  dark: 'bg-white-600 font-gray-300',
};

const Toast = ({ closeToast, toastProps, title, text }) => {
  return (
    <Wrapper onClick={() => closeToast()}>
      <Icon>{toastIcons[toastProps.type]}</Icon>
      <Content>
        <Title>{title}</Title>
        <Text>{text}</Text>
      </Content>
    </Wrapper>
  );
};

export default Toast;
