import React, { useState, useMemo, useCallback } from 'react';
import styled, { withTheme } from 'styled-components';
import useModal from 'hooks/useModal';
import useMeme from 'hooks/useMeme';
import useRequest from 'hooks/useRequest';
import PageSlider from 'components/atoms/PageSlider';
import ModalPage from 'components/atoms/ModalPage';
import TwitterVerificationForm from 'components/molecules/TwitterVerificationForm';
import TwitterVerificationPreview from 'components/molecules/TwitterVerificationPreview';
import CopyArea from 'components/atoms/CopyArea';
import Loader from 'components/atoms/Loader';

const Wrapper = styled.div`
  width: 100%;
  color: black;
  padding: 0.25rem;
`;
const CurateNFTModal = ({ theme }) => {
  const callApi = useRequest();
  const [isVerifying, setIsVerifying] = useState(false);
  const [page, setPage] = useState('');
  const [signature, setSignature] = useState('');
  const welcomeMeme = useMeme({ type: 'welcome' });
  const { closeModal } = useModal();
  const tweet = useMemo(
    () =>
      `Entering%20RARA's%20NFT%20RA!CE.%0A${signature}%0Ahttps://app.rara.social`,
    [signature]
  );

  const verifyTweet = useCallback(() => {
    setIsVerifying(true);
    return callApi({
      path: '/api/user/verify/tweet',
      method: 'GET',
    }).then((result) => {
      setIsVerifying(false);
      if (result.success === true) {
        setPage('success');
      } else {
        setPage('verification-error');
      }
    });
  }, [callApi]);
  const PAGES = useMemo(
    () => [
      (next, prev, key) => (
        <TwitterVerificationForm
          key={key}
          onSuccess={(sig) => {
            setSignature(sig);
            next();
          }}
          closeModal={closeModal}
        />
      ),
      (next, prev, key) => (
        <ModalPage
          key={key}
          closeAction={closeModal}
          title={'Post to Twitter'}
          primaryBtnText={
            isVerifying ? (
              <div
                style={{
                  height: '100%',
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Loader type={'bounce'} />
              </div>
            ) : (
              'Verify Tweet'
            )
          }
          primaryBtnAction={() => {
            verifyTweet().then(() => next());
          }}
          backAction={prev}
          size={'xl'}
        >
          <p>
            Copy the message below and tweet it to verify your identity. Make
            sure your tweets are not set to private.
          </p>
          <CopyArea
            value={`Entering RARA's NFT RA!CE.
          ${signature}\nhttps://app.rara.social`}
          />
          <span style={{ fontSize: 'smaller', lineHeight: '2em' }}>
            Or follow{' '}
            <a
              href={`https://twitter.com/intent/tweet?text=${tweet}`}
              target={'_blank'}
              rel='noreferrer'
            >
              this link
            </a>{' '}
            to paste your signed message to Twitter
          </span>
        </ModalPage>
      ),
      // (next, prev, key) => (
      //   <TwitterVerificationPreview // TODO: rename this component
      //     key={key}
      //     signature={signature}
      //     closeModal={closeModal}
      //     onSuccess={() => {
      //       setPage('success');
      //       next();
      //     }}
      //     onError={() => {
      //       setPage('verification-error');
      //       next();
      //     }}
      //     backAction={prev}
      //   />
      // ),
      (next, prev, key) =>
        page === 'success' ? (
          <ModalPage
            key={key}
            closeAction={closeModal}
            headerNav={'close'}
            title={'Welcome to the NFT RA!CE'}
            primaryBtnText={'Enter the NFT RA!CE'}
            primaryBtnAction={closeModal}
            backAction={prev}
            size={'l'}
          >
            <p>
              You just earned your first <b>100 $RA!</b>
            </p>
            <img
              style={{
                width: '100%',
                borderRadius: '12px',
              }}
              alt='Welcome!'
              src={welcomeMeme}
            ></img>
          </ModalPage>
        ) : page === 'verification-error' ? (
          <ModalPage
            key={key}
            closeAction={closeModal}
            title={'Try again'}
            primaryBtnText={'Back'}
            primaryBtnAction={prev}
            backAction={prev}
            size={'s'}
          >
            <p>
              We weren't able to verify that you sent the tweet. Please go back
              and try verifying again. It may take up to 2 minutes. If the issue
              persists, join our{' '}
              <a
                href='https://discord.gg/zyeEYWDwuM'
                target='_blank'
                rel='noreferrer'
              >
                Discord
              </a>{' '}
              or{' '}
              <a
                href='https://t.me/rara_social'
                target='_blank'
                rel='noreferrer'
              >
                Telegram
              </a>{' '}
              for support.
            </p>
          </ModalPage>
        ) : (
          <div key={key}>UnhandledError</div>
        ),
    ],
    [closeModal, signature, tweet, page, isVerifying, verifyTweet, welcomeMeme]
  );
  return (
    <Wrapper>
      <PageSlider pages={PAGES} pageHeights={['l', 'xl', 'l']} />
    </Wrapper>
  );
};

export default withTheme(CurateNFTModal);
