import React from 'react';
import styled from 'styled-components';
import ReactPlayer from 'react-player';
import AssetImage from './AssetImage';
import MediaPlayer from 'components/molecules/MediaPlayer';

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  width: 100vw;
  padding: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 6;
  background: rgba(0, 0, 0, 0.6);
  @media (max-width: 600px) {
    padding: 5rem 1rem;
  }
`;
const AssetContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 0.5px solid rgba(144, 144, 144, 0.9);
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  max-height: 100%;
  border-radius: 20px;
`;

const Lightbox = ({ closeLightbox, asset, size }) => {
  return (
    <Wrapper onClick={closeLightbox} size={size}>
      <AssetContainer>
        <span style={{ fontSize: '1.5em' }}>
          {asset.name + ' | ' + asset.collection.name}
        </span>
        {(!!asset.animationOriginalURL || !!asset.animationURL) &&
        ReactPlayer.canPlay(asset.animationURL) ? (
          <MediaPlayer asset={asset} imageCropped={false} inLightbox={true} />
        ) : (
          <AssetImage
            imgUrl={asset.imageUrl}
            name={asset.name}
            cropped={false}
            onClick={(e) => e.stopPropagation()}
            height={size === 'large' ? '90%' : 'auto'}
            width={size === 'large' ? '50%' : '80%'}
          />
        )}
        <a
          href={asset.openseaLink || asset.externalLink}
          rel='noreferrer'
          target='_blank'>
          View Asset
        </a>
      </AssetContainer>
    </Wrapper>
  );
};

export default Lightbox;
