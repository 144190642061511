import { AppActions } from 'reducers/appReducer';

// similar to connect, this doesn't need to be a thunk.  I think I just like the idea of having these two actions tightly coupled to the store
const disconnectAppSocket = () => {
  return (dispatch, getState) => {
    const { app } = getState();
    const socket = app.socket;
    if (socket) {
      socket.disconnect();
      dispatch(AppActions.SET_SOCKET(null));
      dispatch(AppActions.SET_STATUS('disconnected'));
    }
  };
};

export default disconnectAppSocket;
