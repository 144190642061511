import { SessionActions } from 'reducers/sessionReducer';
import { AppActions } from 'reducers/appReducer';
import connectWeb3 from './connectWeb3';
import refreshWallet from './refreshWallet';
import TwitterVerification from 'components/organisms/TwitterVerificationModal';

const checkStatus = () => {
  return (dispatch) => {
    dispatch(SessionActions.SET_USER_STATUS('connecting'));
    fetch('/api/status', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then(async (response) => {
      const prevMagicConnection = localStorage.getItem('MAGIC_CACHED_PROVIDER');
      // TODO: confirm that there is a 'cachedProvider' for walletconnect before trying to connectWeb3 with it
      if (response.status === 200) {
        const user = await response.json();
        dispatch(
          connectWeb3(prevMagicConnection ? 'magic' : 'walletconnect')
        ).then(() => {
          dispatch(refreshWallet());
          dispatch(SessionActions.SET_USER(user));
          dispatch(SessionActions.SET_BALANCE({ ra: user.ra }));
          dispatch(SessionActions.SET_USER_STATUS('connected'));
          if (
            user &&
            (!user.twitter || (user.twitter && !user.twitter.isRaraVerified))
          ) {
            dispatch(AppActions.SET_MODAL(<TwitterVerification />));
          }
        });
      } else if (response.status === 401) {
        if (prevMagicConnection)
          localStorage.removeItem('MAGIC_CACHED_PROVIDER');
        dispatch(SessionActions.SET_USER_STATUS('disconnected'));
      }
      return;
    });
  };
};

export default checkStatus;
