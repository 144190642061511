import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  width: 100%;
  padding-top: 0.5rem;
`;

// controls height of page content and hides non current pages
const ContentFrame = styled.div`
  display: flex;
  overflow: hidden;
  width: 100%;
  height: ${({ currentHeight }) =>
    currentHeight === 'xs'
      ? '10rem'
      : currentHeight === 's'
      ? '13rem'
      : currentHeight === 'm'
      ? '16rem'
      : currentHeight === 'l'
      ? '22rem'
      : currentHeight === 'xl'
      ? '26rem'
      : currentHeight === 'xxl'
      ? '36rem'
      : 'auto'};
  padding-bottom: 0.5rem;
  transition: height 0.2s ease;
`;

// controls slide animation.
const Content = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: ${({ numSteps }) => `repeat(${numSteps}, 100%)`};
  transform: translateX(${({ offset }) => offset * -100}%);
  transition: transform 0.2s ease;
`;

const PageSlider = ({
  pages,
  pageHeights = new Array(pages.length).fill('auto'),
}) => {
  const [currPage, setCurrPage] = useState(0);
  const [currentHeight, setCurrentHeight] = useState(pageHeights[0]);
  const viewNext = () => {
    return currPage === pages.length - 1
      ? setCurrPage(0)
      : setCurrPage(currPage + 1);
  };
  const viewPrev = () => {
    return currPage !== 0 ? setCurrPage(currPage - 1) : null;
  };
  useEffect(() => {
    setCurrentHeight(pageHeights[currPage]);
  }, [currPage, pageHeights]);
  return (
    <Wrapper>
      <ContentFrame currentHeight={currentHeight}>
        <Content offset={currPage} numSteps={pages.length}>
          {pages.map((render, index) =>
            render(viewNext, viewPrev, `wallet-page-${index}`)
          )}
        </Content>
      </ContentFrame>
    </Wrapper>
  );
};

export default PageSlider;
