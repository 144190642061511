import React from 'react';
const MuteIcon = () => {
  return (
    <svg
      width='100%'
      height='100%'
      viewBox='0 0 45 45'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle
        cx='22.238'
        cy='22.238'
        r='22.238'
        fill='#6E7795'
        fillOpacity='.66'
      />
      <path
        d='M29.48 19.25v5.5M33 17.417v9.166M13.64 11.917l17.604 20.166M20.7 14.551 25.082 11v8.574M25.082 25.025V33l-7.921-6.416h-5.28a.863.863 0 0 1-.623-.269.936.936 0 0 1-.258-.648v-7.333c0-.244.093-.477.258-.648a.863.863 0 0 1 .622-.269h6.561'
        stroke='#fff'
        strokeWidth='1.873'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
export default MuteIcon;
