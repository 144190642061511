import React, { useState, useEffect, useCallback } from 'react';
import { Select } from 'react-functional-select';
import ModalPage from 'components/atoms/ModalPage';
import useRequest from 'hooks/useRequest';
import useModal from 'hooks/useModal';
const CurationRaceSelection = ({ prev, next, selectRace }) => {
  const callApi = useRequest();
  const { closeModal } = useModal();
  const [raceThemes, setRaceThemes] = useState([]);
  useEffect(() => {
    callApi({ path: '/api/race/themes', method: 'GET' }).then((themes) => {
      if (themes) {
        setRaceThemes(themes);
      }
    });
  }, [callApi]);
  const getOptionValue = useCallback((option): number => option._id, []); // get id of selected race
  const onOptionChange = useCallback(
    (option: Option | null): void => {
      selectRace(option);
    },
    [selectRace]
  );
  const getOptionLabel = useCallback(
    (option: Option): string => option.name,
    []
  );
  return (
    <ModalPage
      closeAction={closeModal}
      title={'Curate an NFT '}
      backAction={prev}
      headerNav={'close'}
      primaryBtnText={'Confirm'}
      primaryBtnAction={next}
      size={'xl'}
    >
      <p>Select the RA!CE you would like to curate for.</p>
      <Select
        isClearable
        // isInvalid={!selectedRace}
        maxMenuHeight={2}
        menuOverscanCount={1}
        lazyLoadMenu={true}
        options={raceThemes}
        onOptionChange={onOptionChange}
        getOptionValue={getOptionValue}
        getOptionLabel={getOptionLabel}
      />
    </ModalPage>
  );
};

export default CurationRaceSelection;
