import React from 'react';
const RaSVG = () => {
  return (
    <svg
      width='100%'
      height='100%'
      viewBox='0 0 68 68'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      {/* <g filter='url(#ra-svg-filter-1)'> */}
      <path
        d='M34 59C20.1929 59 9 47.8071 9 34V34C9 20.1929 20.1929 9 34 9V9C47.8071 9 59 20.1929 59 34V34C59 47.8071 47.8071 59 34 59V59Z'
        fill='#0C1226'
        fillOpacity='0.9'
      />
      <path
        d='M34 59C20.1929 59 9 47.8071 9 34V34C9 20.1929 20.1929 9 34 9V9C47.8071 9 59 20.1929 59 34V34C59 47.8071 47.8071 59 34 59V59Z'
        fill='url(#ra-svg-linear-gradient-1)'
        fillOpacity='0.2'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M32.6106 29.0611C32.8372 29.3372 32.8174 29.7402 32.5649 29.9928V29.9928C32.2746 30.283 31.7974 30.2596 31.537 29.9423L21.486 17.6967C21.2594 17.4206 21.2791 17.0176 21.5317 16.7651V16.7651C21.8219 16.4748 22.2992 16.4983 22.5595 16.8155L32.6106 29.0611Z'
        fill='#0DECB6'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M29.0947 32.3237C29.4225 32.5807 29.4518 33.0666 29.1572 33.3611V33.3611C28.9088 33.6095 28.514 33.6333 28.2376 33.4165L23.5771 29.7613L19.5332 26.5897C19.2314 26.353 19.1787 25.9165 19.4153 25.6147V25.6147C19.652 25.3129 20.0886 25.2601 20.3903 25.4968L24.4343 28.6685L29.0947 32.3237Z'
        fill='#D356FF'
      />
      {/* <g filter={'url(#ra-svg-filter-2)'}> */}
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M35.7865 25.2932C35.5167 25.4367 35.2373 25.5964 34.9497 25.7716C34.6699 25.9421 34.306 25.9026 34.0743 25.6709V25.6709C33.7643 25.3609 33.8116 24.8399 34.1854 24.611C36.5991 23.1328 38.5746 22.6091 39.3897 23.4241C40.8005 24.8349 38.2003 29.7225 33.582 34.3409C28.9637 38.9592 24.0761 41.5594 22.6653 40.1486C21.9187 39.402 22.2953 37.6818 23.4999 35.5431C23.7191 35.1538 24.2534 35.0988 24.5693 35.4147V35.4147C24.7948 35.6402 24.8395 35.9928 24.684 36.2711C24.6323 36.3636 24.5824 36.455 24.5344 36.5454C24.045 37.4651 23.7828 38.2001 23.6992 38.7182V38.7182C23.6599 38.9621 23.8518 39.1539 24.0956 39.1146V39.1146C24.6137 39.031 25.3487 38.7688 26.2685 38.2795C28.0921 37.3093 30.3601 35.5986 32.5999 33.3588C34.8397 31.119 36.5504 28.851 37.5206 27.0273C38.0099 26.1076 38.2722 25.3726 38.3557 24.8545V24.8545C38.395 24.6106 38.2032 24.4188 37.9594 24.4581V24.4581C37.4413 24.5417 36.7062 24.8039 35.7865 25.2932ZM38.4669 24.4513C38.4669 24.4514 38.4653 24.4508 38.4624 24.4493C38.4656 24.4504 38.467 24.4512 38.4669 24.4513ZM38.3646 24.3515C38.3631 24.3485 38.3625 24.3469 38.3625 24.3469C38.3626 24.3468 38.3634 24.3483 38.3646 24.3515ZM23.588 39.1214C23.5881 39.1213 23.5897 39.1219 23.5926 39.1235C23.5894 39.1223 23.5879 39.1215 23.588 39.1214ZM23.6924 39.2258C23.6925 39.2258 23.6919 39.2242 23.6904 39.2212C23.6911 39.2231 23.6916 39.2243 23.692 39.2251C23.6922 39.2256 23.6924 39.2259 23.6924 39.2258ZM23.2459 40.5028V40.5028C23.2091 40.4934 23.2223 40.4639 23.2591 40.4733C25.1584 40.9578 29.4769 38.4471 33.5827 34.3413C37.4908 30.4332 39.9538 26.3323 39.7639 24.3085C39.762 24.2875 39.7856 24.2825 39.7911 24.3029V24.3029L44.5092 41.9099C45.0285 43.848 43.2684 45.628 41.3246 45.1304L23.2459 40.5028Z'
        fill='white'
        fillOpacity='0.7'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M35.7865 25.2932C35.5167 25.4367 35.2373 25.5964 34.9497 25.7716C34.6699 25.9421 34.306 25.9026 34.0743 25.6709V25.6709C33.7643 25.3609 33.8116 24.8399 34.1854 24.611C36.5991 23.1328 38.5746 22.6091 39.3897 23.4241C40.8005 24.8349 38.2003 29.7225 33.582 34.3409C28.9637 38.9592 24.0761 41.5594 22.6653 40.1486C21.9187 39.402 22.2953 37.6818 23.4999 35.5431C23.7191 35.1538 24.2534 35.0988 24.5693 35.4147V35.4147C24.7948 35.6402 24.8395 35.9928 24.684 36.2711C24.6323 36.3636 24.5824 36.455 24.5344 36.5454C24.045 37.4651 23.7828 38.2001 23.6992 38.7182V38.7182C23.6599 38.9621 23.8518 39.1539 24.0956 39.1146V39.1146C24.6137 39.031 25.3487 38.7688 26.2685 38.2795C28.0921 37.3093 30.3601 35.5986 32.5999 33.3588C34.8397 31.119 36.5504 28.851 37.5206 27.0273C38.0099 26.1076 38.2722 25.3726 38.3557 24.8545V24.8545C38.395 24.6106 38.2032 24.4188 37.9594 24.4581V24.4581C37.4413 24.5417 36.7062 24.8039 35.7865 25.2932ZM38.4669 24.4513C38.4669 24.4514 38.4653 24.4508 38.4624 24.4493C38.4656 24.4504 38.467 24.4512 38.4669 24.4513ZM38.3646 24.3515C38.3631 24.3485 38.3625 24.3469 38.3625 24.3469C38.3626 24.3468 38.3634 24.3483 38.3646 24.3515ZM23.588 39.1214C23.5881 39.1213 23.5897 39.1219 23.5926 39.1235C23.5894 39.1223 23.5879 39.1215 23.588 39.1214ZM23.6924 39.2258C23.6925 39.2258 23.6919 39.2242 23.6904 39.2212C23.6911 39.2231 23.6916 39.2243 23.692 39.2251C23.6922 39.2256 23.6924 39.2259 23.6924 39.2258ZM23.2459 40.5028V40.5028C23.2091 40.4934 23.2223 40.4639 23.2591 40.4733C25.1584 40.9578 29.4769 38.4471 33.5827 34.3413C37.4908 30.4332 39.9538 26.3323 39.7639 24.3085C39.762 24.2875 39.7856 24.2825 39.7911 24.3029V24.3029L44.5092 41.9099C45.0285 43.848 43.2684 45.628 41.3246 45.1304L23.2459 40.5028Z'
        fill='white'
        fillOpacity='0.7'
      />
      {/* </g> */}
      <path
        d='M57.3333 34C57.3333 46.8866 46.8866 57.3333 34 57.3333L34 60.6667C48.7276 60.6667 60.6667 48.7276 60.6667 34L57.3333 34ZM34 10.6667C46.8866 10.6667 57.3333 21.1134 57.3333 34L60.6667 34C60.6667 19.2724 48.7276 7.33333 34 7.33333L34 10.6667ZM10.6667 34C10.6667 21.1134 21.1134 10.6667 34 10.6667L34 7.33333C19.2724 7.33333 7.33333 19.2724 7.33333 34L10.6667 34ZM7.33333 34C7.33333 48.7276 19.2724 60.6667 34 60.6667L34 57.3333C21.1134 57.3333 10.6667 46.8866 10.6667 34L7.33333 34Z'
        fill='black'
        fillOpacity='0.3'
      />
      <path
        d='M57.3333 34C57.3333 46.8866 46.8866 57.3333 34 57.3333L34 60.6667C48.7276 60.6667 60.6667 48.7276 60.6667 34L57.3333 34ZM34 10.6667C46.8866 10.6667 57.3333 21.1134 57.3333 34L60.6667 34C60.6667 19.2724 48.7276 7.33333 34 7.33333L34 10.6667ZM10.6667 34C10.6667 21.1134 21.1134 10.6667 34 10.6667L34 7.33333C19.2724 7.33333 7.33333 19.2724 7.33333 34L10.6667 34ZM7.33333 34C7.33333 48.7276 19.2724 60.6667 34 60.6667L34 57.3333C21.1134 57.3333 10.6667 46.8866 10.6667 34L7.33333 34Z'
        fill='#0CD4A3'
      />
      {/* </g> */}
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M62.0506 32.8751C62.0506 18.3909 50.3088 6.64915 35.8246 6.64915C35.3692 6.64915 35.0001 6.27997 35.0001 5.82457C35.0001 5.36917 35.3692 5 35.8246 5C51.2196 5 63.6997 17.4801 63.6997 32.8751C63.6997 33.3305 63.3305 33.6997 62.8751 33.6997C62.4197 33.6997 62.0506 33.3305 62.0506 32.8751Z'
        fill='#D356FF'
      />
      <defs>
        <filter
          id='ra-svg-filter-1'
          x='-13.2222'
          y='-13.2222'
          width='94.4445'
          height='94.4445'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feGaussianBlur in='BackgroundImage' stdDeviation='11.1111' />
          <feComposite
            in2='SourceAlpha'
            operator='in'
            result='effect1_backgroundBlur'
          />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset />
          <feGaussianBlur stdDeviation='4.16667' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.0509804 0 0 0 0 0.92549 0 0 0 0 0.713726 0 0 0 0.5 0'
          />
          <feBlend
            mode='normal'
            in2='effect1_backgroundBlur'
            result='effect2_dropShadow'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect2_dropShadow'
            result='shape'
          />
        </filter>
        <filter
          id='ra-svg-filter-2'
          x='13.6932'
          y='17.0753'
          width='37.0516'
          height='38.3121'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset />
          <feGaussianBlur stdDeviation='2.77778' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.2 0'
          />
          <feBlend
            mode='normal'
            in2='BackgroundImageFix'
            result='effect1_dropShadow'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_dropShadow'
            result='shape'
          />
        </filter>
        <linearGradient
          id='ra-svg-linear-gradient-1'
          x1='9'
          y1='34'
          x2='59'
          y2='34'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#3ACAC7' />
          <stop offset='1' stopColor='#3ACAC7' stopOpacity='0' />
        </linearGradient>
        <linearGradient
          id='paint1_linear'
          x1='22.1716'
          y1='38.9237'
          x2='42.2663'
          y2='33.5394'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='white' />
          <stop offset='1' stopColor='white' stopOpacity='0' />
        </linearGradient>
        <linearGradient
          id='to-do'
          x1='12.9474'
          y1='21.2807'
          x2='41.0175'
          y2='55.9298'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#0DECB6' />
          <stop offset='1' stopColor='#0CD4A3' />
        </linearGradient>
      </defs>
    </svg>
  );
};
export default RaSVG;
