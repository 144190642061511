import React from 'react';
const RaSVGBackground = () => {
  return (
    <svg
      width='100%'
      height='100%'
      viewBox='0 0 66 66'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      {/* <g filter='url(#ra-svg-background-filter)'> */}
      <path
        d='M8 33C8 19.1929 19.1929 8 33 8V8C46.8071 8 58 19.1929 58 33V33C58 46.8071 46.8071 58 33 58V58C19.1929 58 8 46.8071 8 33V33Z'
        fill='#0C1226'
        fillOpacity='0.9'
      />
      <path
        d='M8 33C8 19.1929 19.1929 8 33 8V8C46.8071 8 58 19.1929 58 33V33C58 46.8071 46.8071 58 33 58V58C19.1929 58 8 46.8071 8 33V33Z'
        fill='url(#ra-svg-background-linear-1)'
        fillOpacity='0.2'
      />
      <path
        d='M33 56.5288C20.0054 56.5288 9.47123 45.9946 9.47123 33H6.52877C6.52877 47.6197 18.3803 59.4712 33 59.4712L33 56.5288ZM56.5288 33C56.5288 45.9946 45.9946 56.5288 33 56.5288L33 59.4712C47.6197 59.4712 59.4712 47.6197 59.4712 33L56.5288 33ZM33 9.47123C45.9946 9.47123 56.5288 20.0054 56.5288 33L59.4712 33C59.4712 18.3803 47.6197 6.52877 33 6.52877V9.47123ZM33 6.52877C18.3803 6.52877 6.52877 18.3803 6.52877 33H9.47123C9.47123 20.0054 20.0054 9.47123 33 9.47123V6.52877Z'
        fill='black'
        fillOpacity='0.3'
      />
      <path
        d='M33 56.5288C20.0054 56.5288 9.47123 45.9946 9.47123 33H6.52877C6.52877 47.6197 18.3803 59.4712 33 59.4712L33 56.5288ZM56.5288 33C56.5288 45.9946 45.9946 56.5288 33 56.5288L33 59.4712C47.6197 59.4712 59.4712 47.6197 59.4712 33L56.5288 33ZM33 9.47123C45.9946 9.47123 56.5288 20.0054 56.5288 33L59.4712 33C59.4712 18.3803 47.6197 6.52877 33 6.52877V9.47123ZM33 6.52877C18.3803 6.52877 6.52877 18.3803 6.52877 33H9.47123C9.47123 20.0054 20.0054 9.47123 33 9.47123V6.52877Z'
        fill='url(#ra-svg-background-linear-2)'
      />
      {/* </g> */}
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M60.3259 31.7397C60.3259 17.3441 48.6559 5.67415 34.2603 5.67414C33.798 5.67414 33.4233 5.29937 33.4233 4.83707C33.4233 4.37477 33.798 4 34.2603 4C49.5805 4 62 16.4195 62 31.7397C62 32.202 61.6252 32.5767 61.1629 32.5767C60.7006 32.5767 60.3259 32.202 60.3259 31.7397Z'
        fill='#D356FF'
      />
      <defs>
        <filter
          id='ra-svg-background-filter'
          x='-11.6165'
          y='-11.6165'
          width='89.2329'
          height='89.2329'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feGaussianBlur in='BackgroundImage' stdDeviation='9.80823' />
          <feComposite
            in2='SourceAlpha'
            operator='in'
            result='effect1_backgroundBlur'
          />
          <feColorMatrix
            in='SourceAlpha'
            type='matrix'
            values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            result='hardAlpha'
          />
          <feOffset />
          <feGaussianBlur stdDeviation='3.67809' />
          <feColorMatrix
            type='matrix'
            values='0 0 0 0 0.0509804 0 0 0 0 0.92549 0 0 0 0 0.713726 0 0 0 0.5 0'
          />
          <feBlend
            mode='normal'
            in2='effect1_backgroundBlur'
            result='effect2_dropShadow'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect2_dropShadow'
            result='shape'
          />
        </filter>
        <linearGradient
          id='ra-svg-background-linear-1'
          x1='33'
          y1='8'
          x2='33'
          y2='58'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#3ACAC7' />
          <stop offset='1' stopColor='#3ACAC7' stopOpacity='0' />
        </linearGradient>
        <linearGradient
          id='ra-svg-background-linear-2'
          x1='45.7193'
          y1='11.9474'
          x2='11.0702'
          y2='40.0175'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#0DECB6' />
          <stop offset='1' stopColor='#0CD4A3' />
        </linearGradient>
      </defs>
    </svg>
  );
};
export default RaSVGBackground;
