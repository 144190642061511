import React, { useState, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { appSelector } from 'reducers/rootReducer';
import styled from 'styled-components';
import ReactPlayer from 'react-player';
import MuteIcon from 'icons/MuteIcon';
import UnmuteIcon from 'icons/UnmuteIcon';
import PlayIcon from 'icons/PlayIcon';
import PauseIcon from 'icons/PauseIcon';

const Wrapper = styled.div`
  display: flex;
  position: relative;
  align-items: ${({ inLightbox }) => (inLightbox ? 'flex-start' : 'center')};
  justify-content: center;
  height: ${({ imageCropped, height }) =>
    height ? height : imageCropped ? '300px' : '100%'};
  width: ${({ imageCropped }) => (imageCropped ? '100%' : '80%')};
  flex: 1;
`;
const ControlOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: ${({ backgroundImg, inLightbox }) =>
    backgroundImg
      ? `center / contain no-repeat url(${backgroundImg})`
      : 'transparent'};
`;
const PlayPauseWrapper = styled.div`
  position: absolute;
  top: 1rem;
  left: 1rem;
  height: 38px;
  width: 38px;
`;
const MuteUnmuteWrapper = styled.div`
  position: absolute;
  top: 1rem;
  left: 4rem;
  height: 38px;
  width: 38px;
`;
const FileType = styled.div`
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  background: ${({ theme }) => theme.colors.transparentDark};
  border: 1px solid white;
  border-radius: 12px;
  padding: 0.4rem;
  /* height: 38px;
  width: 38px; */
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MediaPlayer = ({
  asset,
  imageCropped,
  clickHandler,
  inLightbox,
  height,
}) => {
  const { page } = useSelector(appSelector);
  const [assetLoaded, setAssetLoaded] = useState(false);
  const [volume, setVolume] = useState(1);
  const [assetPlaying, setIsPlaying] = useState(page === 'detail');
  const mimeType = useMemo(() => {
    if (asset) {
      switch (asset.animationURL.slice(asset.animationURL.lastIndexOf('.'))) {
        case '.mp3': {
          return 'audio';
        }
        case '.wav': {
          return 'audio';
        }
        case '.weba': {
          return 'audio';
        }
        case '.mid': {
          return 'audio';
        }
        case '.midi': {
          return 'audio';
        }
        case '.oga': {
          return 'audio';
        }
        default: {
          return 'video';
        }
      }
    }
    return null;
  }, [asset]);
  const toggleMute = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setVolume((v) => (v === 1 ? 0 : 1));
  }, []);
  const togglePlayback = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsPlaying((p) => !p);
  }, []);
  const handleAssetClick = useCallback(() => {
    if (page === 'detail' && assetPlaying && !inLightbox) {
      setIsPlaying(false);
      clickHandler && clickHandler();
    } else {
      clickHandler && clickHandler();
    }
  }, [page, assetPlaying, inLightbox, clickHandler]);
  const PlayerStyles = useMemo(() => {
    let styleObj = {
      maxWidth: '100%',
      width: '100%',
      maxHeight: mimeType === 'audio' ? '3rem' : '100%',
    };
    if (inLightbox) {
      styleObj = { ...styleObj, minHeight: '100%' };
    }
    return styleObj;
  }, [mimeType, inLightbox]);
  return asset ? (
    <Wrapper imageCropped={imageCropped} height={height}>
      <ReactPlayer
        config={{ file: { attributes: { controlsList: 'nodownload' } } }}
        url={asset.animationURL}
        pip={false}
        playing={assetPlaying}
        volume={volume}
        style={PlayerStyles}
        loop={true}
        onReady={() => setAssetLoaded(true)}
      />
      {!assetLoaded ? (
        <div>Loading</div>
      ) : (
        <ControlOverlay
          page={page}
          backgroundImg={mimeType !== 'video' ? asset.imageUrl : null}
          inLightbox={inLightbox}
          onClick={handleAssetClick}
        >
          <>
            <PlayPauseWrapper onClick={togglePlayback}>
              {!assetPlaying ? <PlayIcon /> : <PauseIcon />}
            </PlayPauseWrapper>
            <MuteUnmuteWrapper onClick={toggleMute}>
              {volume === 0 ? <UnmuteIcon /> : <MuteIcon />}
            </MuteUnmuteWrapper>
            {/* <FileType>
            {asset &&
              asset.animationURL &&
              asset.animationURL.lastIndexOf('.') &&
              asset.animationURL
                .slice(asset.animationURL.lastIndexOf('.'))
                .toUpperCase()}
          </FileType> */}
          </>
        </ControlOverlay>
      )}
    </Wrapper>
  ) : null;
};
export default MediaPlayer;
