import io from 'socket.io-client';
import * as Sentry from '@sentry/react';
import { AppActions } from '../reducers/appReducer';
import { toast } from 'react-toastify';
import Toast from 'components/molecules/Toast';

// doesn't really need to be a thunk but I made it one anyway
const connectAppSocket = () => {
  return (dispatch) => {
    dispatch(AppActions.SET_STATUS('connecting'));
    const appSocket = io('/');
    if (appSocket) {
      appSocket.on('connect', (info) => {
        dispatch(AppActions.SET_STATUS('connected'));
      });
      appSocket.on('connect_error', (info) => {
        console.log('appsocket connect_error', info);
        toast.error(
          <Toast title='Error' text="Couldn't connect to the RARA server" />
        );
        Sentry.captureException('connectAppSocket error:', info);
        dispatch(AppActions.SET_STATUS('error'));
        dispatch(AppActions.SET_ERROR(info));
      });
      appSocket.on('disconnect', (info) => {
        appSocket.disconnect();
        dispatch(AppActions.SET_STATUS('disconnected'));
        dispatch(AppActions.SET_SOCKET(null));
      });

      dispatch(AppActions.SET_SOCKET(appSocket));
      dispatch(AppActions.SET_STATUS('connected'));
    }
  };
};

export default connectAppSocket;
