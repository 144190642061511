import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { sessionSelector } from 'reducers/rootReducer';
import useModal from 'hooks/useModal';
import AuthModal from 'components/molecules/ConnectWallet';

function AuthWrapper({ children }) {
  const session = useSelector(sessionSelector);
  const { openModal } = useModal();
  const openDialog = useCallback(() => {
    openModal(<AuthModal />);
  }, [openModal]);
  return (
    <div style={{ display: 'grid' }}>
      <div style={{ gridArea: '1/1' }}>{children}</div>
      {session.status === 'connected' ? null : (
        <button
          style={{
            gridArea: '1/1',
            background: 'transparent',
            zIndex: 2,
          }}
          onClick={openDialog}
        ></button>
      )}
    </div>
  );
}

export default AuthWrapper;
