import React from 'react';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import Input from 'components/atoms/Input';
import Textarea from 'components/atoms/Textarea';
import Checkbox from 'components/atoms/Checkbox';
import useRequest from 'hooks/useRequest';
import ModalPage from 'components/atoms/ModalPage';
const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0;
  margin: 0;
`;
const Fieldset = styled.fieldset`
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
  padding: 0;
  width: 100%;
`;
const Label = styled.label`
  margin-bottom: 1rem;
  /* font-weight: bold; */
`;

const CurateNFTForm = ({
  selectedRace,
  closeModal,
  setPage,
  onSubmit,
  navNext,
  navPrev,
}) => {
  const callApi = useRequest();
  const {
    register,
    handleSubmit,
    formState: { errors, dirtyFields, isValid },
  } = useForm({
    mode: 'onChange',
    defaultValues: { url: '', message: '', isNotOwner: false },
  });
  const submissionHandler = ({ isNotOwner, url, message }) => {
    callApi({
      method: 'POST',
      path: '/api/race/preview-asset',
      body: {
        url: url,
      },
    })
      .then((asset) => {
        if (asset.error) {
          const errorPageCodes = {
            'Missing URL': 'missingUrl',
            'Missing Asset': 'missingAsset',
            'Bad URL': 'badUrl',
            'OpenSea Error': 'opensea',
            'Foundation Error': 'foundation',
            'Zora Error': 'zora',
          };
          setPage(errorPageCodes[asset.error.name]);
          navNext();
        } else {
          onSubmit({ isNotOwner, url, message }, asset);
          setPage('preview');
          navNext();
        }
      })
      .catch((e) => {
        setPage('error');
        navNext();
        console.log('error getting asset preview', e);
      });
  };
  return (
    // <FormWrapper>
    <ModalPage
      title={'Curate an NFT'}
      primaryBtnText={'Preview Curation'}
      primaryBtnAction={handleSubmit(submissionHandler)}
      primaryBtnDisabled={!isValid}
      backAction={navPrev}
      closeAction={closeModal}
      size={'xxl'}
    >
      <div>
        <p>
          Enter an NFT into{' '}
          {selectedRace ? '"' + selectedRace.name + '"' : 'the RA!CE'} and tell
          the fans why. 25 RA!s will be invested in the NFT as a seed
          investment.
        </p>
        <Form>
          <Fieldset>
            <Label>
              {' '}
              <b>The NFT URL</b> (OpenSea, Foundation, or Zora){' '}
            </Label>
            <Input
              type='text'
              // placeholder='https://foundation.app/@artist/~/123'
              error={errors.url}
              dirty={dirtyFields.url}
              {...register('url', { required: 'You must include a valid URL' })}
            />
          </Fieldset>
          <Fieldset>
            <Label>
              {' '}
              <b>Why are you curating this NFT?</b>{' '}
            </Label>
            <Textarea
              // label='Why are you curating this NFT'
              withCounter={true}
              error={errors.message}
              dirty={dirtyFields.message}
              // placeholder='This one really speaks to me because...'
              {...register('message', {
                required: 'You must include a curation message',
                maxLength: {
                  value: 280,
                  message: 'Must be less than 280 characters',
                },
              })}
            />
          </Fieldset>
          <Fieldset
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              margin: '1rem 0',
            }}
          >
            <Checkbox
              type='checkbox'
              placeholder='Owner'
              dirty={dirtyFields.isNotOwner}
              error={errors.isNotOwner}
              {...register('isNotOwner', {
                required: true,
              })}
            />
            &nbsp;&nbsp;&nbsp;
            <Label
              style={{
                margin: 0,
                fontWeight: dirtyFields.isNotOwner ? 'bold' : 'inherit',
              }}
            >
              {' '}
              I do not own this NFT{' '}
            </Label>
          </Fieldset>
        </Form>
      </div>
    </ModalPage>
    // </FormWrapper>
  );
};

export default CurateNFTForm;
