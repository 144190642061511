import React, { useMemo } from 'react';
import styled from 'styled-components';
import BidForm from '../atoms/BidButton';
import ClosingBid from '../atoms/ClosingBid';
import Unit from 'components/atoms/Unit';
import RaButton from '../atoms/RaButton';
import Badge from '../_particles/Badge';
import useAuctionContext from 'hooks/useAuctionContext';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: ${({ size }) =>
    size === 'small' ? '45% 45%' : '40% 40%'};
  justify-content: space-between;
  width: 100%;
  height: ${({ size }) => (size === 'small' ? '2.5rem' : '4rem')};
  content-size: border-box;
  margin: 0 0 0.75rem; // pre-curator styling
  /* margin: 0rem 0 0.25rem; */
  align-items: center;
  padding: ${({ size }) => (size === 'small' ? '0 1rem' : '0 2.5rem')};
  @media (max-width: 1280px) {
    grid-template-columns: 49% 49%;
  }
  @media (max-width: 900px) {
    grid-template-columns: 45% 45%;
  }
  @media (max-width: 320px) {
    grid-template-columns: 48% 48%;
  }
`;

const AuctionControls = ({ setShowEarningsDropdown }) => {
  const { auction, view } = useAuctionContext();
  const size = useMemo(() => (view === 'desktop' ? 'large' : 'small'), [view]);
  if (!auction) {
    return (
      <Wrapper size={size}>
        <Badge />
        <Badge />
      </Wrapper>
    );
  } else {
    return (
      <Wrapper size={size}>
        {/* {auction.state !== 'ACTIVE' ? ( */}
        <ClosingBid auction={auction} size={size} />
        {/* <Badge>
          <span
            style={{
              fontSize: size === 'small' ? '1.1em' : '2em',
              fontWeight: 800,
              color: 'white',
              cursor: 'default',
              // height: size === 'small' ? '3.5rem' : null,
            }}
          >
            {auction?.ra}&nbsp;
            <Unit length={'abrv'} type={'ra'} fontSize='x-small' />
          </span>
        </Badge> */}
        {/* <RaButton auction={auction} size={size}></RaButton> */}
        {/* ) : (
          <BidForm auction={auction} size={size}></BidForm>
        )} */}
        <RaButton
          auction={auction}
          size={size}
          setShowEarningsDropdown={setShowEarningsDropdown}
        ></RaButton>
      </Wrapper>
    );
  }
};

export default AuctionControls;
