import React from 'react';

const EthIcon = () => {
  return (
    <svg
      width='100%'
      height='100%'
      viewBox='0 0 28 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M24.94 19.21a2 2 0 0 0 .722-2.842L15.672.914a2 2 0 0 0-3.36 0L2.321 16.368a2 2 0 0 0 .722 2.842l9.991 5.446a2 2 0 0 0 1.914 0l9.992-5.446zm-3.867 8.739c1.299-1.718-.662-3.993-2.552-2.963l-4.051 2.208a1 1 0 0 1-.957 0l-4.05-2.207c-1.89-1.03-3.85 1.245-2.552 2.962l5.485 7.257a2 2 0 0 0 3.191 0l5.486-7.257z'
        fill='#fff'
      />
      <path
        d='m14.756 24.595 10.194-5.46c1.202-.645 1.099-2.402-.171-2.9L14.585 12.23a1.6 1.6 0 0 0-1.17 0L3.221 16.235c-1.27.498-1.373 2.255-.17 2.9l10.193 5.46a1.6 1.6 0 0 0 1.512 0z'
        fill='#6E7795'
      />
    </svg>
  );
};

export default EthIcon;
