import React from 'react';
const PauseIcon = () => {
  return (
    <svg
      width='100%'
      height='100%'
      viewBox='0 0 66 66'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='33' cy='33' r='33' fill='#6E7795' fillOpacity='.66' />
      <path
        d='M46.5 15h-6.75c-.828 0-1.5.733-1.5 1.636v32.728c0 .903.672 1.636 1.5 1.636h6.75c.828 0 1.5-.733 1.5-1.636V16.636c0-.903-.672-1.636-1.5-1.636zM26.25 15H19.5c-.828 0-1.5.733-1.5 1.636v32.728c0 .903.672 1.636 1.5 1.636h6.75c.828 0 1.5-.733 1.5-1.636V16.636c0-.903-.672-1.636-1.5-1.636z'
        fill='#fff'
      />
    </svg>
  );
};

export default PauseIcon;
