import React from 'react';
import styled from 'styled-components';
import Unit from 'components/atoms/Unit';
import SendIcon from 'icons/SendIcon';
import ETHIcon from 'icons/ETHIcon';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border-radius: 12px;
`;
const Token = styled.div`
  display: flex;
  padding: 1rem;
  justify-content: space-between;
  align-items: center;
  background: #6e779550; //transparentLight @ 50%
  width: 80%;
`;
const TokenInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
`;
const TokenIcon = styled.div`
  display: flex;
  height: 2rem;
  padding: 0;
  align-items: center;
  justify-content: center;
  width: 15%;
`;
const TokenWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

function TokenDisplay({ eth, weth, unit, caption, name, setPage }) {
  return (
    <Wrapper>
      <TokenWrapper>
        <Token
          style={{
            borderBottom: '2px solid #23283bef',
            borderTopRightRadius: '12px',
            borderTopLeftRadius: '12px',
          }}
        >
          <TokenInfo>
            <span style={{ color: 'white' }}>
              {eth}&nbsp;
              <span style={{ color: '#90A0D3' }}>
                <Unit type={'eth'} />
              </span>
            </span>
            <span style={{ color: '#90A0D3' }}>{caption}</span>
          </TokenInfo>
          <TokenIcon>
            <ETHIcon />
          </TokenIcon>
        </Token>

        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            // paddingRight: '1rem',
            cursor: 'pointer',

            width: '20%',
          }}
          onClick={() => setPage('eth')}
        >
          <SendIcon size={24} stroke={'#fff'} />
        </div>
      </TokenWrapper>
      <TokenWrapper>
        <Token
          style={{
            borderBottomRightRadius: '12px',
            borderBottomLeftRadius: '12px',
          }}
        >
          <TokenInfo>
            <div style={{ color: 'white' }}>
              {weth}{' '}
              <span style={{ color: '#90A0D3' }}>
                <Unit type={'weth'} />
              </span>
            </div>
            <span style={{ color: '#90A0D3' }}>{'Wrapped Ether'}</span>
          </TokenInfo>
          <TokenIcon>
            <ETHIcon />
          </TokenIcon>
        </Token>

        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            // paddingRight: '1rem',
            cursor: 'pointer',

            width: '20%',
          }}
          onClick={() => setPage('weth')}
        >
          <SendIcon size={24} stroke={'#fff'} />
        </div>
      </TokenWrapper>
    </Wrapper>
  );
}

export default TokenDisplay;
