import React, { useState, useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Helmet } from 'react-helmet';
import login from 'actions//login';
import { magicLogin, magicRedirect } from 'api/magic';
import { ProfileContext } from 'components/user/ProfileContext';
import getParams from 'helpers/getURLParams';
import Loader from 'components/atoms/Loader';
import * as Sentry from '@sentry/react';
import './MagicLogin.scss';
import AppLogo from 'images/app-logo.svg';
import FormBg from 'images/form-bg.png';
import { navigate } from '@reach/router';
import Button from 'components/atoms/Button';
import Footer from 'components/organisms/Footer';

function MagicLogin() {
  const dispatch = useDispatch();
  const { platformType, platformId, communityId, ref } =
    useContext(ProfileContext);

  // Magic.Link Redirect - if there is a "magic_credential" URL param then use that to login
  const { magic_credential } = getParams(window.location);
  const [isRedirecting, setIsRedirecting] = useState(false);
  useEffect(() => {
    if (magic_credential) {
      setIsRedirecting(true);
      magicRedirect().then((didToken) => {
        dispatch(
          login('magic', didToken, {
            platformType,
            platformId,
            communityId,
            ref,
          })
        );
        setLoading(false);
        navigate('/');
      });
    }
  }, [magic_credential, dispatch, platformType, platformId, communityId, ref]);

  // Login Form
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  async function submitForm(event) {
    event.preventDefault();
    setErrorMessage(null);
    setLoading(true);
    try {
      // build redirect URL if params
      let redirectURI = `${window.location.origin}/?ref=${ref}`;
      const didToken = await magicLogin({
        email,
        redirectURI,
      });

      if (didToken) {
        dispatch(
          login('magic', didToken, {
            platformType,
            platformId,
            communityId,
            ref,
          })
        );
        setLoading(false);
        navigate('/' + ref ? `?ref=${ref}` : '');
      }
    } catch (error) {
      console.log(error);
      setErrorMessage(error.message);
      setLoading(false);
      Sentry.captureException(error);
    }
    setLoading(false);
  }

  return (
    <React.Fragment>
      {isRedirecting ? (
        <Redirecting />
      ) : (
        <div className='magic-login'>
          <div style={{ maxWidth: '400px', width: '60%' }}>
            <img className='logo' src={AppLogo} alt='rara logo' />

            <div className='mb-3'></div>

            <h1 style={{ margin: 0, padding: 0 }}>RARA Social</h1>

            <div className='mb-3'></div>

            <form className='loginForm' name='loginForm' onSubmit={submitForm}>
              <label htmlFor='email'>
                <h2
                  style={{
                    textAlign: 'left',
                    fontWeight: 'normal',
                    margin: 0,
                    padding: 0,
                  }}
                >
                  Drop your email address! We'll send you an email to confirm.
                </h2>
              </label>

              <div className='mb-3'></div>

              <div className='login-form-grid'>
                <div className='input-wrapper'>
                  <img
                    src={FormBg}
                    className='input-background'
                    alt='form input background'
                  />

                  <input
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    name='email'
                    type='email'
                    placeholder='Email'
                  />
                </div>

                {/* Button/Loading */}

                <Button
                  borderRadius='8px'
                  type='submit'
                  bg='green80'
                  color='black'
                  display='flex'
                  alignItems='center'
                  justifyContent='center'
                  height='3em'
                  width='100%'
                >
                  {!loading ? (
                    'Enter'
                  ) : (
                    <Loader
                      style={{
                        height: '2em',
                        width: '2em',
                        borderWidth: '2px',
                        margin: 0,
                      }}
                    />
                  )}
                </Button>
              </div>
              {errorMessage ? (
                <div>
                  <div className='mb-3'></div>
                  <p>{errorMessage}</p>
                </div>
              ) : null}
            </form>

            <div className='mb-3'></div>

            <p
              className='text-secondary'
              style={{ textAlign: 'left', color: '#FFFFFF99' }}
            >
              By entering your email, RARA is creating a wallet controlled by
              you and secured by your email. You don’t need passwords, seed
              phrases and back-up systems. Click{' '}
              <a
                href='https://magic.link/docs/introduction/faq'
                target='_blank'
                rel='noreferrer'
              >
                {' '}
                here{' '}
              </a>{' '}
              to learn more.
            </p>
          </div>

          {/* TERMS */}
          {/* <div>
            <p className='text-secondary'>
              By providing your email and logging in to
              <br /> RARA.social, you agree to our Terms of Service
              <br /> and Privacy Policy
            </p>
          </div> */}
          <Footer />

          <Helmet>
            <title>RARA</title>
          </Helmet>
        </div>
      )}
    </React.Fragment>
  );
}

export default MagicLogin;

function Redirecting(params) {
  return (
    <div
      style={{
        display: 'grid',
        alignContent: 'center',
        justifyContent: 'center',
        height: '100%',
      }}
    >
      <p className='robot-label'>Loading</p>
      <div className='text-center'>
        <Loader
          style={{
            height: '2em',
            width: '2em',
            borderWidth: '2px',
            margin: 0,
          }}
        />
      </div>
    </div>
  );
}
