import React from 'react';

const DiscordIcon = () => {
  return (
    <svg
      width='100%'
      height='100%'
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12 19.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zM20 19.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z'
        fill='#fff'
      />
      <path
        d='M9.3 10A21.916 21.916 0 0 1 16 9a21.916 21.916 0 0 1 6.7 1M22.7 22a21.917 21.917 0 0 1-6.7 1 21.917 21.917 0 0 1-6.7-1'
        stroke='#fff'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='m19.38 22.761 1.504 3.006a.997.997 0 0 0 1.123.526c3.068-.75 5.712-2.057 7.638-3.73a1.008 1.008 0 0 0 .309-1.05L25.707 7.358a1.003 1.003 0 0 0-.578-.639c-1.2-.491-2.44-.875-3.708-1.148a1.002 1.002 0 0 0-1.165.66l-.997 2.99M12.62 22.76l-1.503 3.007a.996.996 0 0 1-1.124.526c-3.068-.75-5.712-2.057-7.638-3.73a1.007 1.007 0 0 1-.309-1.05L6.293 7.358a1.003 1.003 0 0 1 .578-.64c1.2-.49 2.44-.874 3.708-1.147a1.002 1.002 0 0 1 1.165.66l.997 2.99'
        stroke='#fff'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12 19.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zM20 19.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z'
        fill='#fff'
      />
      <path
        d='M9.3 10A21.916 21.916 0 0 1 16 9a21.916 21.916 0 0 1 6.7 1M22.7 22a21.917 21.917 0 0 1-6.7 1 21.917 21.917 0 0 1-6.7-1'
        stroke='#fff'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='m19.38 22.761 1.504 3.006a.997.997 0 0 0 1.123.526c3.068-.75 5.712-2.057 7.638-3.73a1.008 1.008 0 0 0 .309-1.05L25.707 7.358a1.003 1.003 0 0 0-.578-.639c-1.2-.491-2.44-.875-3.708-1.148a1.002 1.002 0 0 0-1.165.66l-.997 2.99M12.62 22.76l-1.503 3.007a.996.996 0 0 1-1.124.526c-3.068-.75-5.712-2.057-7.638-3.73a1.007 1.007 0 0 1-.309-1.05L6.293 7.358a1.003 1.003 0 0 1 .578-.64c1.2-.49 2.44-.874 3.708-1.147a1.002 1.002 0 0 1 1.165.66l.997 2.99'
        stroke='#fff'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default DiscordIcon;
