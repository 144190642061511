import React, { useEffect } from 'react';

import getParams from 'helpers/getURLParams';

import { navigate } from '@reach/router';

export const ProfileContext = React.createContext({});
export const ProfileProvider = ({ children }) => {
  const { platformType, platformId, communityId, ref } = getParams(
    window.location
  );
  // cleanup URL once we have these params
  useEffect(() => {
    if ((platformType && platformId && communityId) || ref) {
      navigate(window.location.origin + window.location.pathname);
    }
  }, [platformType, platformId, communityId, ref]);

  return (
    <ProfileContext.Provider
      value={{ platformType, platformId, communityId, ref }}
    >
      {children}
    </ProfileContext.Provider>
  );
};
