import { Magic } from 'magic-sdk';

// Setup Magic
const magic = new Magic(process.env.REACT_APP_MAGIC_PUBLISHABLE_KEY);

export async function magicLogin({ email, redirectURI }) {
  return magic.auth.loginWithMagicLink({ email, redirectURI });
}

export async function magicRedirect() {
  return magic.auth.loginWithCredential();
}
