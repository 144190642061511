import React from 'react';
const ToastSuccessIcon = () => {
  return (
    <svg width='27' height='27' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M16.748 9.273 11.1 14.935l-2.173-2.173a1.317 1.317 0 1 0-1.856 1.857l3.094 3.107a1.318 1.318 0 0 0 1.856 0l6.584-6.583a1.316 1.316 0 0 0 0-1.87 1.317 1.317 0 0 0-1.857 0zM13.167.333a13.167 13.167 0 1 0 0 26.333 13.167 13.167 0 0 0 0-26.333zm0 23.7a10.533 10.533 0 1 1 0-21.065 10.533 10.533 0 0 1 0 21.065z'
        fill='#0C1226'
        fillOpacity='.9'
      />
      <path
        d='M16.748 9.273 11.1 14.935l-2.173-2.173a1.317 1.317 0 1 0-1.856 1.857l3.094 3.107a1.318 1.318 0 0 0 1.856 0l6.584-6.583a1.316 1.316 0 0 0 0-1.87 1.317 1.317 0 0 0-1.857 0zM13.167.333a13.167 13.167 0 1 0 0 26.333 13.167 13.167 0 0 0 0-26.333zm0 23.7a10.533 10.533 0 1 1 0-21.065 10.533 10.533 0 0 1 0 21.065z'
        fill='url(#toast-success-gradient)'
        fillOpacity='.2'
      />
      <defs>
        <linearGradient
          id='toast-success-gradient'
          x1='13.167'
          y1='.333'
          x2='13.167'
          y2='26.666'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#3ACAC7' />
          <stop offset='1' stopColor='#3ACAC7' stopOpacity='0' />
        </linearGradient>
      </defs>
    </svg>
  );
};
export default ToastSuccessIcon;
