import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { web3Selector, walletSelector } from 'reducers/rootReducer';
import UserWalletRAs from 'components/molecules/UserWalletRAs';
import TokenDisplay from 'components/molecules/UserWalletTokens';

const Wrapper = styled.div`
  border-radius: 20px;
  padding: 0.5rem 1rem;
  background-color: ${({ theme }) => theme.colors.transparentDark};
  display: flex;
  flex-direction: column;
`;
const WalletInfo = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;
const Network = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 1.1em;
`;

export default function UserWallet({ setPage }) {
  const wallet = useSelector(walletSelector);
  const { network, status } = useSelector(web3Selector);
  return (
    <Wrapper>
      <h2>My Wallet</h2>

      <UserWalletRAs
        amount={wallet.ra}
        unit={'ra'}
        caption={'$RA!'}
        highlight
      />
      <div className='mb-2'></div>

      <WalletInfo
        style={{
          overflow: 'hidden',
          width: '100%',
        }}
      >
        {network && status === 'connected' ? (
          <Network>
            <span style={{ color: '#90A0D3', fontWeight: 'bold' }}>
              Network:{' '}
            </span>
            &nbsp;
            {network.name}
          </Network>
        ) : (
          'Connecting...'
        )}
      </WalletInfo>
      <div className='mb-2'></div>
      <TokenDisplay
        eth={parseFloat(wallet.eth.toFixed(7))}
        weth={parseFloat(wallet.weth.toFixed(7))}
        caption={'Ethereum'}
        setPage={setPage}
      />
    </Wrapper>
  );
}
