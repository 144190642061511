import React from 'react';

const InstagramIcon = () => {
  return (
    <svg
      width='100%'
      height='100%'
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M16 21a5 5 0 1 0 0-10 5 5 0 0 0 0 10z'
        stroke='#fff'
        strokeWidth='2'
        strokeMiterlimit='10'
      />
      <path
        d='M21.5 4.5h-11a6 6 0 0 0-6 6v11a6 6 0 0 0 6 6h11a6 6 0 0 0 6-6v-11a6 6 0 0 0-6-6z'
        stroke='#FDFDFD'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M22.5 11a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z' fill='#fff' />
    </svg>
  );
};

export default InstagramIcon;
