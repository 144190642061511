import React, { useCallback } from 'react';
import ModalPage from 'components/atoms/ModalPage';
import Input from 'components/atoms/Input';
import { useForm } from 'react-hook-form';
import useRequest from 'hooks/useRequest';
import useWeb3 from 'hooks/useWeb3';
import { toast } from 'react-toastify';
import Toast from 'components/molecules/Toast';

const TwitterVerificationForm = ({ onSuccess, closeModal }) => {
  const { signMsg } = useWeb3();
  const {
    register,
    handleSubmit,
    formState: { errors, dirtyFields },
  } = useForm();
  const callApi = useRequest();
  const onSubmit = useCallback(
    async ({ twitterHandle }) => {
      await callApi({
        path: `/api/user/twitter/${twitterHandle.slice(1)}`, // slice off '@' symbol
        method: 'GET',
      })
        .then(async (result) => {
          if (result.error) throw result;
          const message = "Entering RARA's NFT RA!CE.";
          const signature = await signMsg(message);
          if (signature.error) {
            return; // do nothing -- user can't continue unless there's a signed message.  signMsg func will toast the signature error
          }
          await callApi({
            path: '/api/user/twitter/message',
            method: 'POST',
            body: { message, signature },
          })
            .then((result) => {
              if (result.success) {
                onSuccess(signature);
              } else {
                throw result;
              }
            })
            .catch((e) => {
              toast.error(
                <Toast
                  title='Twitter verification error'
                  text={"Couldn't save signed message"}
                />
              );
              return;
            });
        })
        .catch(({ error }) => {
          toast.error(
            <Toast title='Twitter verification error' text={error.error} />
          );
        });
    },
    [callApi, signMsg, onSuccess]
  );
  return (
    <ModalPage
      title={'Complete Your Profile'}
      primaryBtnText={'Link Twitter'}
      primaryBtnAction={handleSubmit(onSubmit)}
      headerNav='close'
      closeAction={closeModal}
      size={'l'}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <p>
          To enter the NFT RA!CE, you need to verify your Twitter profile. You
          only need to verify once.
        </p>
        <label style={{ fontWeight: 'bold', lineHeight: '2em' }}>
          Add your Twitter username
        </label>
        <Input
          type='text'
          label={''}
          placeholder='@username'
          error={errors.twitterHandle}
          dirty={dirtyFields.twitterHandle}
          {...register('twitterHandle', {
            required: true,
            pattern: /^@(\w){1,15}$/i,
          })}
        />
      </form>
    </ModalPage>
  );
};

export default TwitterVerificationForm;
