import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import useModal from 'hooks/useModal';
import useUser from 'hooks/useUser';
import useMeme from 'hooks/useMeme';
import CoinIcon from 'icons/CoinIcon';
import CopyButton from 'components/atoms/CopyButton';
import Column from 'components/_particles/Column';
import InfoIcon from 'icons/ToastInfoIcon';
import ModalPage from 'components/atoms/ModalPage';
import TwitterBirdIcon from 'icons/TwitterBirdIcon';
import EllipsesIcon from 'icons/EllipsesIcon';
import UserProfileMenu from 'components/molecules/UserProfileMenu';
import ReferralLinkModal from 'components/molecules/ReferralLinkModal';
import TwitterVerificationModal from 'components/organisms/TwitterVerificationModal';
import AuctionAvatar from 'components/atoms/AuctionAvatar';

const Wrapper = styled(Column)`
  /* width: 30%; */
  align-items: center;
  padding-top: 60px;
`;
const ProfileCard = styled.div`
  position: relative;
  width: 20rem;
  padding: 60px 0 1rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* border: 1px solid white; */
  border-radius: 20px;
  background: ${({ theme }) => theme.colors.transparentDark};
`;
const MenuIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  cursor: pointer;
  top: 0.5rem;
  right: 1rem;
  /* border: 1px solid white; */
`;
const AvatarWrapper = styled.div`
  height: 80px;
  width: 80px;
  position: absolute;
  top: -30px;
  border-radius: 50%;
  background: black;
  border: 2px solid #323b53;
  box-shadow: 0 0 6px black;
  overflow: hidden;
  object-fit: cover;
`;
const NameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 0.5rem;
  width: 100%;
  font-size: 1em;
`;
const ProfileItem = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: relative;
  width: calc(100% - 2rem);
  height: 2.25rem;
  margin: 0.25rem 0rem;
  padding: 0 1rem;
  border-radius: 8px;
  background: #6e779550;
  color: ${({ color, theme }) => (color ? color : 'white')};
`;
const InfoIconWrapper = styled.div`
  height: 1em;
  width: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
const UserProfile = ({ profile }) => {
  const { openModal, closeModal } = useModal();
  const { user } = useUser();
  const [profileMenuOpen, setProfileMenu] = useState(false);
  const [shareBtnClicked, setShareBtnClicked] = useState(false);
  const explainerMeme = useMeme({ type: 'explain-taste' });

  const showTasteExplainer = useCallback(() => {
    openModal(
      <ModalPage
        size={'xxl'}
        title={'What is TASTE?'}
        headerNav={'close'}
        closeAction={closeModal}
        primaryBtnAction={closeModal}
        primaryBtnText={'Close'}
      >
        <img src={explainerMeme} alt={explainerMeme} width='100%' />
        <p style={{ color: 'black', marginTop: '.5rem' }}>
          Like your taste in fashion and art, your TASTE reputation quantifies
          your ability to discover cool NFTs before they go mainstream. Increase
          your TASTE score by investing $RA! in the RA!CE’s hottest NFTs before
          others, especially before those with higher TASTE scores. As your
          TASTE increases, you'll gain the ability to curate NFTs in the RA!CE,
          access to the private Curator-Club, a special NFT badge, and much
          more. Read more{' '}
          <a
            href='https://rara.mirror.xyz/5WNrK3VvIO4Lw4F1iBYuLbx7op3O9L_EQ2EFVpebSnI'
            target='_blank'
            rel='noreferrer'
          >
            here
          </a>
          .
        </p>
      </ModalPage>
    );
  }, [openModal, closeModal, explainerMeme]);
  const openProfileMenu = useCallback(() => {
    setProfileMenu(true);
  }, []);
  const closeProfileMenu = useCallback(() => {
    setProfileMenu(false);
  }, []);
  const showRefLinkModal = useCallback(() => {
    if (user?.publicAddress === profile.publicAddress) {
      openModal(<ReferralLinkModal />);
    }
  }, [openModal, user, profile]);
  const handleShareBtnClick = useCallback(() => {
    setShareBtnClicked((c) => !c);
    setTimeout(() => {
      setShareBtnClicked((c) => !c);
    }, 1800);
  }, []);
  const showTwitterVerificationModal = useCallback(() => {
    openModal(<TwitterVerificationModal />);
  }, [openModal]);
  const navToTwitter = useCallback(() => {
    window.open(`https://twitter.com/${profile?.twitter?.username}`, '_blank');
  }, [profile]);
  return (
    <Wrapper>
      <ProfileCard>
        {profile.publicAddress === user?.publicAddress && (
          <MenuIconWrapper onClick={openProfileMenu}>
            <EllipsesIcon />
          </MenuIconWrapper>
        )}
        {profileMenuOpen && <UserProfileMenu closeMenu={closeProfileMenu} />}
        <AvatarWrapper>
          <AuctionAvatar
            user={{ ...profile.profile, publicAddress: profile.publicAddress }}
            dimensions={{ height: 80, width: 80 }}
          />
        </AvatarWrapper>
        <NameWrapper>
          <h2 style={{ margin: 0 }}>
            {profile.profile.displayName ||
              profile.publicAddress.substring(0, 6) +
                '...' +
                profile.publicAddress.substring(
                  profile.publicAddress.length - 4
                )}
          </h2>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              width: '80%',
            }}
          >
            <div
              style={{
                height: '.75em',
                width: '.75em',
                marginRight: '1rem',
              }}
            >
              <CopyButton
                address={profile?.publicAddress}
                height={'1.25em'}
                width={'1.25em'}
              />
            </div>
            <span>
              {profile.publicAddress.substring(0, 6) +
                '...' +
                profile.publicAddress.substring(
                  profile.publicAddress.length - 4
                )}
            </span>
          </div>
        </NameWrapper>
        <ProfileItem
          onClick={() => {}}
          style={{ cursor: 'inherit', justifyContent: 'space-between' }}
        >
          <span>{profile && profile.isCurator ? 'Curator' : 'Fan'}</span>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <b>{profile && profile.taste}</b> &nbsp;Taste &nbsp;
            <InfoIconWrapper onClick={showTasteExplainer}>
              <InfoIcon fill={'#90A0D3'} />
            </InfoIconWrapper>
          </div>
        </ProfileItem>
        {user?.publicAddress === profile.publicAddress ? (
          <ProfileItem onClick={showRefLinkModal}>
            <>
              <div
                style={{
                  height: '1.5em',
                  width: '1.5em',
                  position: 'absolute',
                  left: '1rem',
                }}
              >
                <CoinIcon />
              </div>
              <span style={{ fontWeight: 600, fontSize: '1.1em' }}>
                Share to Earn
              </span>
            </>
          </ProfileItem>
        ) : (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center',
              width: 'calc(100% - 2rem)',
              height: '2.25rem',
              margin: '0.25rem 0rem',
              padding: '0 1rem',
              borderRadius: '8px',
              background: '#6e779550',
            }}
            onClick={handleShareBtnClick}
          >
            <CopyButton
              address={window.location.href}
              height={'1.25em'}
              width={'1.25em'}
              btnStyle={{
                width: '90%',
                background: 'transparent',
                position: 'absolute',
              }}
            />
            <span style={{ fontWeight: 600, fontSize: '1.1em' }}>
              {shareBtnClicked ? 'Copied Link!' : 'Share Profile'}
            </span>
          </div>
        )}

        {profile && profile.twitter && profile.twitter.isRaraVerified ? (
          <ProfileItem onClick={navToTwitter}>
            <div
              style={{
                // alignSelf: 'center',
                position: 'absolute',
                marginLeft: '1rem',
                left: 0,
                height: '1.25rem',
                width: '1.25rem',
              }}
            >
              <TwitterBirdIcon />
            </div>
            <span>@{profile.twitter.username}</span>
          </ProfileItem>
        ) : profile?.publicAddress === user?.publicAddress ? (
          <ProfileItem
            background={'rgba(13, 236, 182, .8)'}
            color={'white'}
            onClick={showTwitterVerificationModal}
          >
            Link to Twitter
          </ProfileItem>
        ) : null}
      </ProfileCard>
    </Wrapper>
  );
};

export default UserProfile;
