import React, { useState, useEffect } from 'react';
// Dialog componets & css
import { DialogOverlay, DialogContent } from '@reach/dialog';
import { useTransition, animated, config } from 'react-spring';
import '@reach/dialog/styles.css';
import 'css/dialog.scss';
import Unit from 'components/atoms/Unit';
import useRequest from 'hooks/useRequest';

export default function Notifications({ notifications }) {
  const callApi = useRequest();
  // NotificationModal controls
  const [showDialog, setShowDialog] = useState(false);
  useEffect(() => {
    if (notifications && notifications.length) {
      setShowDialog(true);
    }
  }, [notifications]);

  const close = () => {
    setShowDialog(false);
    // call api
    callApi({ method: 'PUT', path: '/api/user/notifications' })
      .then((response) => {
        // console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Display Params
  return (
    <NotificationModal open={showDialog} onClose={close}>
      <div style={{ borderRadius: '24px', color: '#383838' }}>
        <div className='mb-2'></div>
        <h3 className='h4 text-center'>RARA Rewards</h3>
        <div className='mb-3'></div>
        <ul style={{ padding: '0' }}>
          {notifications &&
            notifications.map((item, index) => {
              return (
                <li
                  key={index}
                  style={{
                    listStyle: 'none',
                    border: 'solid 1px #ddd',
                    padding: '8px',
                    marginBottom: '8px',
                  }}
                >
                  {/* Amount */}
                  <span style={{ float: 'right' }}>
                    <b>
                      + {item.amount} <Unit type={item.unit} />{' '}
                    </b>
                  </span>

                  {/* Title */}
                  <b>{item.title}</b>
                  <div>{item.message}</div>
                </li>
              );
            })}
        </ul>

        <div className='text-center'>
          <span className='gradient-box'>
            <button onClick={close}>RA! RA!</button>
          </span>
        </div>
      </div>
    </NotificationModal>
  );
}

const AnimatedDialogOverlay = animated(DialogOverlay);
const AnimatedDialogContent = animated(DialogContent);
const NotificationModal = ({ children, open, onClose }) => {
  const transitions = useTransition(open, {
    from: {
      opacity: 0,
      transform: 'translateY(50vh)',
    },
    enter: {
      opacity: 1,
      transform: 'translateY(0)',
    },
    leave: {
      opacity: 0,
      transform: 'translateY(50vh)',
    },
    config: config.gentle,
  });

  return transitions(
    (styles, item, props, key) =>
      item && (
        <AnimatedDialogOverlay
          style={{
            opacity: styles.opacity,
            zIndex: '1000',
            display: 'flex',
            flexDirection: 'column-reverse',
            borderRadius: '20px',
            ...styles,
          }}
          onDismiss={onClose}
        >
          <AnimatedDialogContent style={styles} aria-label='rara wallet'>
            {children}
          </AnimatedDialogContent>
        </AnimatedDialogOverlay>
      )
  );
};
