import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { AppActions } from 'reducers/appReducer';
import { appSelector } from 'reducers/rootReducer';
import useRequest from 'hooks/useRequest';
import LeaderboardUser from 'components/molecules/LeaderboardUser';
import Leaderboard from 'components/organisms/DataTable';
import { Switch } from 'antd';
import Loader from 'components/atoms/Loader';
import { useMediaQuery } from 'react-responsive';
import Footer from 'components/organisms/Footer';

const Wrapper = styled.div`
  padding: 2.5rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const TableHeader = styled.header`
  width: 60%;
  display: flex;
  /* flex-direction: column; */
  justify-content: space-between;
  align-items: flex-end;
  /* align-items: ${({ activeTable }) =>
    activeTable !== 'last' ? 'center' : 'flex-end'}; */
  h1,
  h3 {
    margin: 0;
  }
  h3 {
    font-size: 1em;
  }
  @media (max-width: 835px) {
    width: 100%;
  }
`;
const Subtitle = styled.div`
  width: 100%;
  height: 20%;
  display: flex;
  justify-content: space-between;
`;
const StyledSwitch = styled(Switch)`
  margin-bottom: 0.25rem;
`;

const LeaderboardView = () => {
  const dispatch = useDispatch();
  const callApi = useRequest();
  const { page } = useSelector(appSelector);
  const [displayedUsers, setDisplayedUsers] = useState([]);
  const [activeTable, setActiveTable] = useState('last');
  const [subtitle, setSubtitle] = useState(null);
  const [tableLoading, setTableLoading] = useState(false);
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 900px)' });

  const toggleList = useCallback(() => {
    setTableLoading(true);
    const nextTable = activeTable === 'last' ? 'all-time' : 'last';
    callApi({ path: `/api/race/leaderboard/${nextTable}`, method: 'GET' })
      .then(({ leaderboard, race }) => {
        if (leaderboard && race) {
          setDisplayedUsers(
            leaderboard.map((u, i) => ({
              ...u,
              keyField: `leaderboard-row-${i}`,
            }))
          );
          if (nextTable === 'last') {
            setSubtitle(
              `${
                race.number
                  ? 'NFT RA!CE #' + String(race.number).padStart(3, '0') + ' - '
                  : ''
              } ${new Intl.DateTimeFormat('en-US', {
                dateStyle: 'long',
              }).format(new Date(race.frame))}`
            );
          } else {
            setSubtitle(
              `Last updated ${new Date().toLocaleDateString('en-US', {
                dateStyle: 'long',
              })}`
            );
          }
        } else {
          setDisplayedUsers([]);
        }
      })
      .finally(() => {
        setActiveTable(nextTable);
        setTableLoading(false);
      });
  }, [activeTable, callApi]);
  const Columns = useMemo(() => {
    return activeTable === 'all-time'
      ? [
          {
            name: 'Name',
            sortable: true,
            grow: isTabletOrMobile ? 1 : 2,
            cell: (d, i, c, r) => {
              return (
                <LeaderboardUser
                  rank={d.rank}
                  user={d}
                  highlighted={d.rank <= 9}
                />
              );
            },
            selector: (row) => row.rank,
          },
          {
            name: 'TASTE score',
            sortable: true,
            center: true,
            selector: (row) => row.taste,
          },
          // {
          //   name: 'Verification',
          //   center: true,
          //   width: '8rem',
          //   cell: (data, idx, col, ref) => {
          //     return (
          //       <button onClick={() => console.log('action')}>Action</button>
          //     );
          //   },
          // },
        ]
      : activeTable === 'last'
      ? [
          {
            name: 'Name',
            sortable: true,
            grow: isTabletOrMobile ? 1 : 2,
            cell: (d, i, c, r) => {
              return (
                <LeaderboardUser
                  rank={d.rank}
                  user={d}
                  highlighted={d.rank === 0}
                />
              );
            },
            selector: (row) => row.rank,
          },
          {
            name: 'TASTE change',
            sortable: true,
            center: true,
            format: (row) => '+' + row.tasteEarned,
            selector: (row) => row.tasteEarned,
          },
          {
            name: '$RA! Earnings',
            sortable: true,
            center: true,
            format: (row) => '+' + Math.floor(row.raEarned),
            selector: (row) => Math.floor(row.raEarned),
          },
          // {
          //   name: 'Verification',
          //   center: true,
          //   width: '8rem',
          //   cell: (data, idx, col, ref) => {
          //     return (
          //       <button onClick={() => console.log('action')}>Action</button>
          //     );
          //   },
          // },
        ]
      : [];
  }, [activeTable, isTabletOrMobile]);
  useEffect(() => {
    if (page !== 'leaderboard') {
      toggleList();
      dispatch(AppActions.SET_PAGE('leaderboard'));
    }
  }, [page, dispatch, toggleList]);

  return (
    <Wrapper>
      <TableHeader activeTable={activeTable}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <h1>Leaderboard </h1>
          {subtitle && <h3>{subtitle}</h3>}
        </div>
        <StyledSwitch
          onChange={toggleList}
          disabled={tableLoading}
          checkedChildren={'Last RA!CE'}
          unCheckedChildren={'All Time'}
        />
      </TableHeader>
      {tableLoading ? (
        <Loader style={{ height: '4rem', width: '4rem', margin: '4rem 0' }} />
      ) : (
        <Leaderboard columns={Columns} data={displayedUsers} />
      )}
      <Footer />
      <Helmet>
        <title>RARA</title>
      </Helmet>
    </Wrapper>
  );
};

export default LeaderboardView;
