import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { web3Selector } from 'reducers/rootReducer';
import AuctionCardHeader from '../molecules/AuctionCardHeader';
import AuctionAsset from 'components/molecules/AuctionAsset';
import AuctionControls from 'components/molecules/AuctionControls';
import AuctionSocials from './AuctionSocials';
import AuctionCurator from 'components/molecules/AuctionCurator';
import { useNavigate } from '@reach/router';
import { toast } from 'react-toastify';
import Toast from 'components/molecules/Toast';
import useAuctionRoom from 'hooks/useAuctionRoom';
import useUser from 'hooks/useUser';
import { abi_foundation } from 'configs/foundation';

const HoverWrapper = styled.div`
  ${({ page }) =>
    page !== 'detail'
      ? '&:hover {transform: scale(1.025);  }; transition: all 100ms; '
      : null}
`;

const Card = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  width: ${({ view }) => (view === 'desktop' ? '100%' : '23rem')};
  height: ${({ view }) => (view === 'desktop' ? '100%' : 'auto')};
  border-radius: 24px;
  background: ${({ theme }) => theme.colors.transparentLight};
  overflow: hidden;
  @media (max-width: 375px) {
    width: 20rem;
  }
  @media (max-width: 320px) {
    width: 18rem;
  }
  position: relative;
`;

export const AuctionContext = React.createContext();
// TODO: figure out a better way to share page specific funcs with this card without all this prop-bloat
const AuctionCard = ({
  auctionId,
  page = 'list',
  view = 'list',
  withCommunity = false,
  displayLightbox,
  header, // string indicating which header to display
  setAuctionInfo, //updates detail view with info from auction record
  removeCuration, //gets new curations for profile view
}) => {
  const navigate = useNavigate();
  const web3 = useSelector(web3Selector);
  const { user } = useUser();
  const { auction, auctionStatus, auctionError } = useAuctionRoom(auctionId);
  const earningsRef = useRef(0);
  const [showEarningsDropdown, setShowEarningsDropdown] = useState(false);
  const isOwner = useMemo(
    () => user?.publicAddress === auction?.curator?.publicAddress,
    [user, auction]
  );
  const Header = useMemo(() => {
    switch (header) {
      case 'curator': {
        return (
          <div style={{ marginBottom: '1rem' }}>
            <AuctionCurator
              curator={auction && auction.curator ? auction.curator : null}
            />
          </div>
        );
      }
      default:
        return <></>;
    }
  }, [header, auction]);
  const handleNFTClick = useCallback(() => {
    if (page === 'detail') {
      displayLightbox(auction.asset);
    } else {
      navigate('/auction/' + auction._id);
    }
  }, [page, auction, navigate, displayLightbox]);

  const createBuyOrder = useCallback(
    async ({ tokenId, tokenAddress, schemaName, startAmount }) => {
      if (web3.network.id !== Number(process.env.REACT_APP_ETH_NETWORK_ID)) {
        toast.error(
          <Toast
            title='Network Error'
            text={`You must be on ${process.env.REACT_APP_ETH_NETWORK_NAME} to complete this action.  Current network: ${web3.network.name}`}
          />
        );
        return;
      }
      if (auction.source && auction.source === 'Foundation') {
        const amountBN = web3.instance.utils.toWei(startAmount.toString());
        const foundationContract = new web3.instance.eth.Contract(
          abi_foundation,
          tokenAddress
        );
        return foundationContract.methods
          .placeBid(amountBN)
          .send({ from: web3.connectedAccount });
      } else {
        return await web3.seaport.createBuyOrder({
          asset: { tokenId, tokenAddress, schemaName },
          accountAddress: web3.connectedAccount,
          startAmount,
        });
      }
    },
    [web3, auction]
  );

  useEffect(() => {
    auction &&
      setAuctionInfo &&
      header === 'curator' &&
      setAuctionInfo({ curator: Header, assetName: auction.asset.name });
  }, [auction, setAuctionInfo, Header, header]);
  useEffect(() => {
    if (
      auction &&
      auction.auctionResults &&
      user &&
      auction.auctionResults[user.publicAddress] &&
      earningsRef.current !==
        auction.auctionResults[user.publicAddress].raEarned
    ) {
      setShowEarningsDropdown(true);
      earningsRef.current = auction.auctionResults[user.publicAddress].raEarned;
    }
  }, [auction, user, earningsRef]);
  useEffect(() => {
    let closeEarningsTimer;
    if (showEarningsDropdown) {
      closeEarningsTimer = setTimeout(() => {
        setShowEarningsDropdown(false);
      }, 4000);
    }
    return () => {
      closeEarningsTimer && clearTimeout(closeEarningsTimer);
    };
  }, [showEarningsDropdown]);
  const ContextValue = useMemo(
    () => ({
      auction,
      status: auctionStatus,
      error: auctionError,
      view,
      page,
      createBuyOrder,
    }),
    [auction, auctionStatus, auctionError, view, page, createBuyOrder]
  );
  return (
    <AuctionContext.Provider value={ContextValue}>
      <HoverWrapper
        page={page}
        // onMouseOver={() => {
        //   setShowEarningsDropdown(true);
        // }}
        // onMouseOut={() => {
        //   setShowEarningsDropdown(false);
        // }}
      >
        <Card view={view}>
          <AuctionCardHeader
            isOwner={isOwner}
            removeCuration={removeCuration}
            showEarningsDropdown={showEarningsDropdown}
          />
          <AuctionAsset clickHandler={handleNFTClick} />
          {auction && auction.curator && page !== 'detail' && (
            <AuctionCurator curator={auction.curator} race={auction.race} />
          )}
          <AuctionControls setShowEarningsDropdown={setShowEarningsDropdown} />
          {page !== 'profile' && <AuctionSocials />}
        </Card>
      </HoverWrapper>
    </AuctionContext.Provider>
  );
};

export default AuctionCard;
