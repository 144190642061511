import React, { useMemo } from 'react';
import styled, { keyframes } from 'styled-components';
import GradientSpinnerIcon from 'icons/GradientSpinnerIcon';

const SpinAnimation = keyframes`
  0% {
    transform: rotate(0deg)
  }
  100% {
    transform: rotate(360deg)
  }
`;

const SpinWrapper = styled.div`
  height: 100%;
  width: 100%;
  animation: ${SpinAnimation} 1s linear infinite;
`;

const Loader = ({ type = 'spin', style = {} }) => {
  const loader = useMemo(
    () =>
      type === 'spin' ? (
        // <div className='lds-dual-ring' style={style}></div>
        <SpinWrapper style={style}>
          <GradientSpinnerIcon />
        </SpinWrapper>
      ) : type === 'bounce' ? (
        <div style={{ textAlign: 'center', display: 'inline-block' }}>
          <div className='spinner' style={style}>
            <div className='bounce1'></div>
            <div className='bounce2'></div>
            <div className='bounce3'></div>
          </div>
        </div>
      ) : (
        <span>'loading'</span>
      ),
    [type, style]
  );
  return loader;
};

export default Loader;
