import React, { useState } from 'react';
import { useSpring, config } from 'react-spring';

function useAnimatedPath({ delay = 0 }) {
  const [length, setLength] = useState(null);
  const animatedStyle = useSpring({
    // strokeDashoffset: toggle ? length : 0,
    from: {
      stroke: 'white',
      strokeDashoffset: length,
      fill: '#18A0FB',
    },
    strokeDashoffset: 0,
    stroke: '#0DECB6',
    fill: '#0DECB6',
    strokeDasharray: length,
    delay,
    config: {
      mass: 1,
      tension: 170,
      friction: 26,
      frequency: 5,
      damping: 0.9,
    },
  });

  return {
    style: animatedStyle,
    ref: (ref) => {
      // The ref is `null` on component unmount
      if (ref) {
        setLength(ref.getTotalLength());
      }
    },
  };
}

export default useAnimatedPath;
