import Web3 from 'web3';
import { Magic } from 'magic-sdk';
import Web3Modal from 'web3modal';
import WalletConnectProvider from '@walletconnect/web3-provider';
import { OpenSeaPort, Network } from 'opensea-js';
import { toast } from 'react-toastify';
import Toast from 'components/molecules/Toast';
import { SessionActions } from 'reducers/sessionReducer';
import refreshWallet from './refreshWallet';

const providerOptions = {
  walletconnect: {
    package: WalletConnectProvider,
    options: {
      infuraId: '3bd4b032abf14871a9fc0f2574e0016f',
    },
  },
};
const web3Modal = new Web3Modal({
  providerOptions,
  cacheProvider: true,
});

const connectWeb3 = (providerName) => {
  return async (dispatch, getState) => {
    dispatch(SessionActions.SET_WEB3_STATUS('connecting'));
    let web3 = null;
    try {
      if (providerName === 'magic') {
        const magic = new Magic(process.env.REACT_APP_MAGIC_PUBLISHABLE_KEY);
        web3 = new Web3(magic.rpcProvider);
        window.localStorage.setItem('MAGIC_CACHED_PROVIDER', 'MAGIC');
        dispatch(SessionActions.SET_WEB3(web3));
        dispatch(createSeaport(web3));
        return dispatch(SessionActions.SET_WEB3_STATUS('connected'));
      } else if (providerName === 'walletconnect') {
        await web3Modal
          .connect()
          .then(async (provider) => {
            const decoratedProvider = attachProviderEventListeners(
              dispatch,
              provider
            );
            web3 = new Web3(decoratedProvider);
            dispatch(SessionActions.SET_WEB3(web3));
            dispatch(createSeaport(web3));
            return dispatch(SessionActions.SET_WEB3_STATUS('connected'));
          })
          .catch((e) => {
            toast.error(
              <Toast text='You must select a web3 provider' title='Error' />
            );
            dispatch(SessionActions.SET_WEB3_STATUS('disconnected'));
            throw new Error('User did not select a provider');
          });
      }
    } catch (e) {
      console.error('Failed to connect with given provider', e);
      dispatch(SessionActions.SET_WEB3_STATUS('error'));
      dispatch(SessionActions.SET_WEB3_ERROR({ message: e }));
      return e;
    }
  };
};

const attachProviderEventListeners = (dispatch, provider) => {
  provider.on('accountsChanged', () => dispatch(refreshWallet()));
  provider.on('chainChanged', () => dispatch(refreshWallet()));
  provider.on('connect', (info) => console.log('provider connect', info));
  provider.on('disconnect', (error) =>
    console.log('provider disconnect', error)
  );
  return provider;
};

const createSeaport = (web3) => {
  return async (dispatch, getState) => {
    try {
      if (web3) {
        const networkId = await web3.eth.net.getId();
        const seaport = new OpenSeaPort(web3.currentProvider, {
          networkName: networkId === 1 ? Network.Main : Network.Rinkeby,
          apiKey: process.env.REACT_APP_OPENSEA_API_KEY,
        });
        dispatch(SessionActions.SET_SEAPORT(seaport));
      }
    } catch (e) {
      dispatch(SessionActions.SET_WEB3_ERROR({ message: e }));
      dispatch(SessionActions.SET_WEB3_STATUS('error'));
    }
  };
};

export default connectWeb3;
