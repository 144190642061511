import React from 'react';
import styled from 'styled-components';
import Countdown, { zeroPad } from 'react-countdown';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h3 {
    margin: 0;
    padding: 0;
    font-weight: normal;
  }
`;
const TimerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: flex-start;
  justify-content: flex-start;
  border-radius: 1rem;
  height: 4.25rem;
  width: 18rem;
  padding: 0 1rem;
  background: ${({ theme }) => theme.colors.dark};
`;
const TimerValues = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 0.5rem;
`;
const Value = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  width: 3.5rem;
  font-size: 2.25em;
  font-family: 'Major Mono Display';
  span {
    line-height: 0.25em;
    font-weight: bold;
    font-size: 0.3em;
  }
`;
const RaceTimer = ({ raceStatus, timeToDisplay }) => {
  if (!timeToDisplay instanceof Date) return null;
  const TimerContent = ({ days, hours, minutes, seconds, completed, api }) => {
    return (
      <TimerWrapper>
        <TimerValues>
          <Value>
            {zeroPad(days)}
            <span>days</span>
          </Value>
          :
          <Value>
            {zeroPad(hours)}
            <span>hours</span>
          </Value>
          :
          <Value>
            {zeroPad(minutes)}
            <span>minutes</span>
          </Value>
          :
          <Value>
            {zeroPad(seconds)}
            <span>seconds</span>
          </Value>
        </TimerValues>
      </TimerWrapper>
    );
  };
  if (timeToDisplay) {
    return (
      <Wrapper background={'#d4d5e1'}>
        <h3>
          {raceStatus === 'PENDING' ? (
            <>Next RA!CE starts in...</>
          ) : raceStatus === 'ACTIVE' ? (
            <>Ending in...</>
          ) : raceStatus === 'COMPLETED' ? (
            <>Next RA!CE starts in...</>
          ) : null}
        </h3>
        <Countdown
          key={timeToDisplay.toString()}
          date={raceStatus ? timeToDisplay : null}
          renderer={TimerContent}
          zeroPadTime={2}
          autoStart={true}
        />
      </Wrapper>
    );
  } else {
    return null;
  }
};

export default RaceTimer;
