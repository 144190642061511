import React, { useState, useEffect, useMemo } from 'react';
import styled, { keyframes, css } from 'styled-components';
import RaceTimer from 'components/atoms/RaceTimer';
import useUser from 'hooks/useUser';
import UserDashboard from 'components/molecules/DashboardUserStats';
import Loader from 'components/atoms/Loader';
const pulse = keyframes`
  0% {
    opacity:.5
  }
  50% {
      opacity:1
  }
  100% {
      opacity: .25
  }
`;
const DashboardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 71rem;
  height: auto;
  margin: 1rem 0 0.5rem;
  border-radius: 20px 20px 0 0;
  background: ${({ theme }) => theme.colors.transparentDark};
  padding-top: 0.5rem;
  @media (max-width: 1220px) {
    width: 48rem;
  }
  @media (max-width: 900px) {
    width: 23rem;
    padding: 1rem 1rem;
    flex-direction: column;
    height: 21rem;
  }
  @media (max-width: 375px) {
    width: 19rem;
  }
`;
const DashboardHeader = styled.header`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 2rem;
  @media (max-width: 900px) {
    margin: 0.25rem 0;
    flex-direction: column;
    gap: 0.25rem;
  }
`;
const RaceThemeHeader = styled.h2`
  color: white;
  align-self: center;
  justify: center;
  margin: 0;
  padding: 0;
  @media (max-width: 900px) {
    font-size: 1.6em;
  }
`;
const RaceState = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme, status }) =>
    status === 'ACTIVE' ? theme.colors.green100 : theme.colors.pink};
  font-size: 1.25em;
  font-weight: bold;
`;

const PulseMixin = css`
  animation: ${pulse} 1.2s ease-in-out infinite;
`;
const RaceStatusIndicator = styled.div`
  height: 12px;
  width: 12px;
  border-radius: 50%;
  margin-right: 1rem;
  background-color: ${({ theme, status }) =>
    status === 'ACTIVE' ? theme.colors.green100 : theme.colors.pink};
  ${({ theme, status, withPulse }) =>
    withPulse
      ? `box-shadow: 0 0 0.6em 0.1em
    ${status === 'ACTIVE' ? theme.colors.green100 : theme.colors.pink};`
      : null}
  ${({ withPulse }) => (withPulse ? PulseMixin : null)}
`;

const RaceInfoPanel = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  place-items: center;
  padding-bottom: 1rem;
  width: 71rem;
  border-radius: 20px 20px 0 0;
  background: ${({ theme }) => theme.colors.transparentDark};
  @media (max-width: 1220px) {
    width: 48rem;
  }
  @media (max-width: 900px) {
    width: 23rem;
    padding: 1rem 3rem;
    grid-template-columns: auto;
    flex-direction: column;
  }
  @media (max-width: 375px) {
    width: 19rem;
    padding: 1rem 0.5rem;
  }
`;
const PanelItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  line-height: 1em;
  h3,
  h4 {
    margin: 0.4rem;
    padding: 0;
    @media (max-width: 1220px) {
      font-size: 1em;
    }
  }
`;

const ValueWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.colors.dark};
  border-radius: 1rem;
  height: 4rem;
  min-width: 8rem;
  padding: 1rem;
`;

const RaceDashboard = ({ activeRace }) => {
  const { user } = useUser();
  const [currentRace, setCurrentRace] = useState(activeRace);
  useEffect(() => {
    activeRace && setCurrentRace(activeRace);
  }, [activeRace]);
  const summary = useMemo(() => {
    let sum = { totalSpend: 0, userSpend: 0, userEarn: 0 };
    if (currentRace && Array.isArray(currentRace.leaderboard) && user) {
      currentRace.leaderboard.forEach((participant) => {
        if (participant.publicAddress === user.publicAddress) {
          sum.userSpend = participant.ras.spent;
          sum.userEarned = participant.ras.earned;
        }
        sum.totalSpend += participant.ras.spent;
      });
    }
    return sum;
  }, [currentRace, user]);
  const timeToDisplay = useMemo(() => {
    if (activeRace) {
      if (activeRace.state === 'ACTIVE') {
        return new Date(activeRace.raceEnd);
      } else if (activeRace.state === 'PENDING') {
        return new Date(activeRace.raceStart);
      } else if (activeRace.state === 'COMPLETED') {
        if (activeRace.nextStart) {
          return new Date(activeRace.nextStart);
        } else {
          return null;
        }
      }
    }
  }, [activeRace]);

  return !activeRace ? (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        minHeight: '20vh',
      }}
    >
      <Loader style={{ height: '4rem', width: '4rem' }} />
    </div>
  ) : (
    <>
      <DashboardWrapper>
        <>
          <DashboardHeader>
            <RaceState status={activeRace?.state}>
              {activeRace && (
                <>
                  <RaceStatusIndicator
                    status={activeRace?.state}
                    withPulse={activeRace?.state !== 'COMPLETED'}
                  />
                  <span>
                    {activeRace.state === 'COMPLETED'
                      ? 'CLOSED'
                      : activeRace.state === 'ACTIVE'
                      ? 'OPEN'
                      : activeRace.state}
                  </span>
                </>
              )}
            </RaceState>
            <RaceThemeHeader>
              {activeRace &&
                `RA!CE #${activeRace.raceNumber
                  .toString()
                  .padStart(3, '0')} Exhibit: ${activeRace.theme}`}
            </RaceThemeHeader>
          </DashboardHeader>
          <RaceInfoPanel>
            <div>
              {activeRace && timeToDisplay ? (
                <RaceTimer
                  raceStatus={activeRace.state}
                  timeToDisplay={timeToDisplay}
                />
              ) : (
                <h3>Next RA!CE hasn't been scheduled yet</h3>
              )}
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                justifyContent: 'space-evenly',
              }}
            >
              <DashboardItem
                header='$RA! Pot'
                content={summary?.totalSpend || 0}
                subtitle='$RA!'
                orientation='column'
              />
              <DashboardItem
                header='Fans'
                content={
                  activeRace?.leaderboard ? activeRace.leaderboard.length : 0
                }
                subtitle=''
                orientation='column'
              />
            </div>
          </RaceInfoPanel>
        </>
      </DashboardWrapper>
      <UserDashboard summary={summary} />
    </>
  );
};

export default RaceDashboard;

const DashboardItem = ({
  header,
  content,
  subtitle,
  orientation = 'column',
}) => {
  return (
    <PanelItem style={{ flexDirection: orientation ? orientation : 'row' }}>
      <h4>{header} &nbsp;</h4>
      <ValueWrapper
        style={{
          flexDirection: orientation ? orientation : 'row',
          alignItems: 'center',
        }}
      >
        <span style={{ fontSize: '2em' }}>
          {content}
          <span style={{ fontSize: '.4em' }}>&nbsp; {subtitle}</span>
        </span>
      </ValueWrapper>
    </PanelItem>
  );
};
