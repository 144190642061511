import connectWeb3 from './connectWeb3';
import startUserSession from './startUserSession';
import refreshWallet from './refreshWallet';
const login = (providerName, payload, profile) => {
  return (dispatch) => {
    try {
      return dispatch(connectWeb3(providerName))
        .then(() => {
          dispatch(refreshWallet()).then(() =>
            dispatch(startUserSession(providerName, payload, profile))
          );
        })
        .catch((e) => {
          throw e;
        });
    } catch (err) {
      console.log('IT DIDNT WORK', err);
    }
  };
};

export default login;
