import React from 'react';
import { DialogOverlay, DialogContent } from '@reach/dialog';
import { useTransition, animated, config } from 'react-spring';
import { ToastContainer } from 'react-toastify';
// import '@reach/dialog/styles.css';
// import 'css/dialog.scss';

const AnimatedDialogOverlay = animated(DialogOverlay);
const AnimatedDialogContent = animated(DialogContent);

const Modal = ({
  isVisible,
  openModal,
  closeModal,
  content,
  contentWrapperClass,
  position = {}, // use margin to position the content
  style = {},
}) => {
  const transitions = useTransition(isVisible, {
    from: {
      opacity: 0,
      transform: 'translateY(-40px)',
    },
    enter: {
      opacity: 1,
      transform: 'translateY(0)',
    },
    leave: {
      opacity: 0,
      transform: 'translateY(-40px)',
    },
    config: config.gentle,
  });
  return transitions(
    (styles, item, props, key) =>
      item && (
        <AnimatedDialogOverlay
          // // key={key}
          style={{
            opacity: styles.opacity,
            ...styles,
            zIndex: '1000',
          }}
          onDismiss={closeModal}
        >
          <AnimatedDialogContent
            // className={contentWrapperClass}
            style={{
              transform: styles.transform,
              ...position,
              ...styles,
            }}
            aria-label='rara wallet'
          >
            <ToastContainer
              id='toast-container'
              pauseOnFocusLoss={false}
              style={{ marginTop: '3rem' }}
            />
            {content}
          </AnimatedDialogContent>
        </AnimatedDialogOverlay>
      )
  );
};

export default Modal;
