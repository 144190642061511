import React, { useState, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import styled, { withTheme } from 'styled-components';
import { userSelector } from 'reducers/rootReducer';
import useModal from 'hooks/useModal';
import PageSlider from 'components/atoms/PageSlider';
import CurationRaceSelection from 'components/molecules/CurationRaceSelection';
import CurationForm from 'components/molecules/CurationForm';
import CurationPreview from 'components/molecules/CurationPreview';
import ModalPage from 'components/atoms/ModalPage';
import CopyArea from 'components/atoms/CopyArea';

const Wrapper = styled.div`
  width: 100%;
  color: black;
  padding: 0.25rem;
`;
const CurateNFTModal = ({ theme }) => {
  const user = useSelector(userSelector);
  const [page, setPage] = useState('form');
  const [selectedRace, setSelectedRace] = useState(null);
  const [asset, setAsset] = useState(null);
  const [assetUrl, setAssetUrl] = useState('');
  const [msg, setMsg] = useState('');
  const [auction, setAuction] = useState(null);
  const { closeModal } = useModal();

  const onSubmit = useCallback((formData, asset) => {
    setAsset(asset);
    setMsg(formData.message);
    setAssetUrl(formData.url);
    setPage('valid');
  }, []);
  const onSuccess = useCallback((auction) => {
    setAuction(auction);
  }, []);

  const successTweet = useMemo(() => {
    if (asset && user) {
      let message = `I just curated ${asset.name} in the RARA NFT RA!CE.

${process.env.REACT_APP_URL}`;

      if (auction) {
        message += `?asset=${auction._id}`;
      }
      return message;
    } else return '';
  }, [asset, user, auction]);

  const PAGES = useMemo(
    () => [
      (next, prev, key) => (
        <CurationRaceSelection
          key={key}
          prev={prev}
          next={next}
          selectRace={setSelectedRace}
        />
      ),
      (next, prev, key) => (
        <div key={key}>
          <CurationForm
            selectedRace={selectedRace}
            closeModal={closeModal}
            setPage={setPage}
            onSubmit={onSubmit}
            navNext={next}
            navPrev={prev}
          />
        </div>
      ),
      (next, prev, key) =>
        page === 'error' ? (
          <ModalPage
            key={key}
            closeAction={closeModal}
            title={'Unable to find this NFT'}
            backAction={prev}
            primaryBtnText={'Back'}
            primaryBtnAction={prev}
            size={'xs'}
          >
            <p>
              We are having trouble locating this NFT. We currently support NFTs
              from OpenSea, Foundation, and Zora
            </p>
          </ModalPage>
        ) : page === 'missingAsset' ? (
          <ModalPage
            key={key}
            closeAction={closeModal}
            title={'Unable to find this NFT'}
            backAction={prev}
            primaryBtnText={'Back'}
            primaryBtnAction={prev}
            size={'xs'}
          >
            <p>
              We are having trouble locating this NFT. We currently support NFTs
              from OpenSea, Foundation, and Zora
            </p>
          </ModalPage>
        ) : page === 'opensea' ? (
          <ModalPage
            key={key}
            closeAction={closeModal}
            title={'OpenSea Error'}
            backAction={prev}
            primaryBtnText={'Back'}
            primaryBtnAction={prev}
            size={'xs'}
          >
            <p>
              We are having trouble locating this NFT. Make sure you have the
              correct URL and try again.
            </p>
          </ModalPage>
        ) : page === 'foundation' ? (
          <ModalPage
            key={key}
            closeAction={closeModal}
            title={'Foundation Error'}
            backAction={prev}
            primaryBtnText={'Back'}
            primaryBtnAction={prev}
            size={'xs'}
          >
            <p>
              We are having trouble locating this NFT. Make sure you have the
              correct URL and try again.
            </p>
          </ModalPage>
        ) : page === 'zora' ? (
          <ModalPage
            key={key}
            closeAction={closeModal}
            title={'Zora Error'}
            backAction={prev}
            primaryBtnText={'Back'}
            primaryBtnAction={prev}
            size={'xs'}
          >
            <p>
              We are having trouble locating this NFT. Make sure you have the
              correct Zora URL and try again.
            </p>
          </ModalPage>
        ) : page === 'badUrl' ? (
          <ModalPage
            key={key}
            closeAction={closeModal}
            title={'Invalid URL'}
            backAction={prev}
            primaryBtnText={'Back'}
            primaryBtnAction={prev}
            size={'xs'}
          >
            <p>
              We are having trouble locating this NFT. We currently support NFTs
              from OpenSea, Foundation, and Zora
            </p>
          </ModalPage>
        ) : page === 'preview' ? (
          <CurationPreview
            selectedRace={selectedRace}
            asset={asset}
            closeModal={closeModal}
            key={key}
            assetUrl={assetUrl}
            message={msg}
            setPage={setPage}
            onSuccess={onSuccess}
            navPrev={prev}
            navNext={next}
            size={'l'}
          />
        ) : (
          <ModalPage
            key={key}
            closeAction={closeModal}
            title={'Unhandled Error'}
            backAction={prev}
            primaryBtnText={'Back'}
            primaryBtnAction={prev}
            size={'l'}
          >
            <p>
              Something went wrong here and we're not sure what! Please reach
              out to us on Twitter (@rara_social) or{' '}
              <a href={'https://discord.gg/mTsnafcCQj'}>Discord</a> so we can
              get to the bottom of it!
            </p>{' '}
          </ModalPage>
        ),
      (next, prev, key) =>
        page === 'success' ? (
          <ModalPage
            key={key}
            closeAction={closeModal}
            title={'Success!'}
            backAction={() => {
              setPage('preview');
              prev();
            }}
            primaryBtnText={'Close'}
            primaryBtnAction={() => {
              closeModal();
            }}
            size={'xl'}
          >
            <p>
              The NFT has been entered into{' '}
              {selectedRace ? selectedRace.name : 'the NFT RA!CE!'}.
            </p>
            <p>
              Share this link on Twitter to promote your NFT in the RA!CE and
              earn $RA!
            </p>
            <CopyArea value={successTweet} />
          </ModalPage>
        ) : page === 'submission-error' ? (
          <ModalPage
            key={key}
            closeAction={closeModal}
            title={'Error'}
            backAction={prev}
            primaryBtnText={'Back'}
            primaryBtnAction={() => {
              setPage('preview');
              prev();
            }}
            size={'xs'}
          >
            <p>
              Oops. We are having trouble curating this NFT. Please tell us how
              to reproduce this error{' '}
              <a
                href='https://www.notion.so/rarasocial/Fix-Curate-button-updates-issues-2c9783044b074d96bea813ce3338466f#649675b6b8bd456081891f518a061301'
                target='_blank'
                rel='noreferrer'
              >
                {' '}
                here
              </a>
              , or jump into our Discord's #support{' '}
              <a
                href='https://discord.gg/wHuxKjxqZt'
                target='_blank'
                rel='noreferrer'
              >
                channel
              </a>
              .
            </p>
          </ModalPage>
        ) : page === 'insufficient' ? (
          <ModalPage
            key={key}
            closeAction={closeModal}
            title={'Insufficient RAs'}
            backAction={prev}
            primaryBtnText={'Close'}
            primaryBtnAction={closeModal}
            size={'xs'}
          >
            <p>You don't have enough $RA! to curate an NFT in RARA.</p>
          </ModalPage>
        ) : page === 'missingUrl' ? (
          <ModalPage
            key={key}
            closeAction={closeModal}
            title={'Error'}
            backAction={prev}
            primaryBtnText={'Back'}
            primaryBtnAction={() => {
              setPage('preview');
              prev();
            }}
            size={'xs'}
          >
            <p>
              Looks like you forgot to include a URL! How did you even get
              here?!
            </p>
          </ModalPage>
        ) : page === 'badUrl' ? (
          <ModalPage
            key={key}
            closeAction={closeModal}
            title={'Error'}
            backAction={prev}
            primaryBtnText={'Back'}
            primaryBtnAction={() => {
              setPage('preview');
              prev();
            }}
            size={'xs'}
          >
            <p>
              We are having trouble locating this NFT. We currently support NFTs
              from OpenSea, Foundation, and Zora
            </p>
          </ModalPage>
        ) : page === 'duplicate' ? (
          <ModalPage
            key={key}
            closeAction={closeModal}
            title={'Duplicate Asset'}
            backAction={prev}
            primaryBtnText={'Back'}
            primaryBtnAction={() => {
              setPage('preview');
              prev();
            }}
            size={'xs'}
          >
            <p>
              This asset has already been curated for either the current or the
              next RA!CE
            </p>
          </ModalPage>
        ) : (
          <ModalPage
            key={key}
            closeAction={closeModal}
            title={'Unhandled Error'}
            backAction={prev}
            primaryBtnText={'Back'}
            primaryBtnAction={() => {
              setPage('preview');
              prev();
            }}
            size={'s'}
          >
            <p>
              Something went wrong here and we're not sure what! Please reach
              out to us on Twitter (@rara_social) or{' '}
              <a href={'https://discord.gg/mTsnafcCQj'}>Discord</a> so we can
              get to the bottom of it!
            </p>{' '}
          </ModalPage>
        ),
    ],
    [
      page,
      closeModal,
      asset,
      msg,
      onSubmit,
      assetUrl,
      successTweet,
      onSuccess,
      selectedRace,
    ]
  );
  return (
    <Wrapper>
      <PageSlider pages={PAGES} pageHeights={['xl', 'xxl', 'xxl', 'xl']} />{' '}
    </Wrapper>
  );
};

export default withTheme(CurateNFTModal);
