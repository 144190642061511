import React from 'react';
import styled from 'styled-components';

const Message = styled.div`
  color: #dcddde;
  display: flex;
  flex-direction: column;
  font-size: 0.9em;
  font-family: Whitney, Roboto, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  padding: 0px 1em;
  position: relative;
  word-wrap: break-word;
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  padding-right: 0;
  min-height: 1.375rem;
  padding-right: 48px !important;
  margin-top: 1.0625rem;
  &:first-child {
    margin-top: 0.5rem;
  }
  &:last-child {
    margin-bottom: 0.5rem;
    border-bottom-width: 0;
  }
  &:a {
    color: #0096cf;
    font-weight: normal;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`;

const Inner = styled.div`
  display: flex;
  position: relative;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
`;
const AvatarWrapper = styled.div`
  margin-right: 16px;
  margin-top: 5px;
  min-width: 40px;
`;
const DiscordAvatar = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
`;
const Content = styled.div`
  width: 100%;
  line-height: 160%;
  font-weight: normal;
  padding-top: 2px;
  display: flex;
  flex-direction: column;
`;
const AuthorInfo = styled.span`
  display: inline-flex;
  align-items: center;
  font-size: 15px;
`;
const Username = styled.span`
  color: #fff;
  font-size: 1.1em;
  font-weight: 500;
  letter-spacing: 0.5px;
`;
const Timestamp = styled.span`
  color: #72767d;
  font-size: 12px;
  margin-left: 3px;
`;
const MessageBody = styled.span`
  font-size: 1rem;
  font-weight: 400;
  word-break: break-word;
  position: relative;
  &:pre {
    border: 1px solid #202225;
    border-radius: 4px;
  }
  &:code {
    /* background: #2f3136; */
    white-space: break-spaces;
  }
`;
const MessageMarkup = styled.span`
  font-size: 1rem;
  line-height: 1.375rem;
  word-wrap: break-word;
  user-select: text;
  font-weight: 400;
`;
export default function DiscordMessage({ message }) {
  function createMarkup() {
    return { __html: message.parsedContent };
  }

  return (
    <Message>
      <Inner>
        <AvatarWrapper>
          <DiscordAvatar src={message.avatar} alt={message.name} />
        </AvatarWrapper>
        <Content>
          <AuthorInfo>
            <Username>{message.name}</Username>
          </AuthorInfo>
          <Timestamp>{message.timestamp}</Timestamp>
          <MessageBody>
            <MessageMarkup
              dangerouslySetInnerHTML={createMarkup()}
            ></MessageMarkup>
          </MessageBody>
        </Content>
      </Inner>
    </Message>
  );
}
