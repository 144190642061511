import React, { useState, useEffect, useMemo } from 'react';
import * as Sentry from '@sentry/react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import {
  Input,
  InputNumber,
  Button as AntButton,
  Form as antForm,
  PageHeader,
  Spin,
} from 'antd';
import PropTypes from 'prop-types';
import AddressInput from 'components/molecules/AddressInput';
import ArrowRightIcon from 'icons/ArrowRightIcon';
import ArrowLeftIcon from 'icons/ArrowLeftIcon';
import Unit from 'components/atoms/Unit';
import getWalletBalance from 'actions//getWalletBalance';
import { LoadingOutlined } from '@ant-design/icons';
import { walletSelector, networkSelector } from 'reducers/rootReducer';
import useWeb3 from 'hooks/useWeb3';

const Form = styled(antForm)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  position: relative;
  color: white;
  width: 100%;
`;

const Back = styled(AntButton)`
  background: white;
  border-color: lightgrey;
  align-self: center;
  margin: 0.25rem 0 0.25rem 0.25rem;
  &:hover {
    background: lightgrey;
  }
  &:active {
    background: grey;
  }
`;
const SendButton = styled(AntButton)`
  background: linear-gradient(to bottom right, #470dc3, #0d56c3);
  border-color: #0d56c3;
`;
const LoadingInfoPanel = styled.div`
  position: absolute;
  bottom: 0.75rem;
  left: 2rem;
  width: 11rem;
  color: #90a0d3;
  background-color: #eeeeee;
  border: 1px solid;
  border-radius: 10px;
  text-align: center;
  font-size: 0.8em;
`;
const ItemHeader = styled.h3`
  color: ${({ theme }) => theme.colors.blueGrey};
  /* margin: 0 0 0 1rem; */
  padding: 0;
  flex: 1;
`;

const TransferAssetForm = ({ assetType, navBack }) => {
  const dispatch = useDispatch();
  const wallet = useSelector(walletSelector);
  const { etherScanUrl } = useSelector(networkSelector);
  const { transferAsset } = useWeb3();

  const availableBalance = useMemo(
    () => wallet[assetType],
    [assetType, wallet]
  );

  // Form Functions
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [complete, setComplete] = useState(false);
  const [error, setError] = useState(null);
  const [txnHash, setTxnHash] = useState(null);

  useEffect(() => {
    return () => {
      //clear form as assetType changes
      setLoading(false);
      setComplete(false);
      setError(null);
      setTxnHash(null);
    };
  }, [assetType]);

  // Submit
  const onSubmit = async ({ sendAmount, destinationAddress }) => {
    setLoading(true);
    setComplete(false);
    setTxnHash(null);
    setError(null);
    const amount =
      sendAmount < availableBalance ? sendAmount : availableBalance;
    try {
      const receipt = await transferAsset({
        assetType,
        destinationAddress,
        amount: amount.toString(),
      });
      setTxnHash(receipt.transactionHash);
      dispatch(getWalletBalance());
      setLoading(false);
      setComplete(true);
    } catch (error) {
      console.log(error);
      setError(error.message);
      Sentry.captureException(error);
      setLoading(false);
    }
  };

  const onSubmitFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <Form
      name='sendForm'
      layout={'vertical'}
      form={form}
      initialValues={{ balance: availableBalance }}
      onFinish={onSubmit}
      onFinishFailed={onSubmitFailed}
      requiredMark={false}
    >
      <PageHeader
        title={
          <h2 style={{ margin: 0, color: 'white' }}>
            Send <Unit type={assetType} length='abrv' />
          </h2>
        }
        onBack={() => {
          navBack();
        }}
        backIcon={
          <Back
            type='primary'
            size='large'
            icon={
              <div className='flex-center'>
                <ArrowLeftIcon />
              </div>
            }
            shape={'circle'}
            disabled={loading || complete}
          />
        }
      />
      <Form.Item
        label={<FormItemHeader text={'Destination Address'} />}
        name='destinationAddress'
        rules={[
          {
            required: true,
            message: 'Please enter destination address',
          },
        ]}
      >
        <AddressInput />
      </Form.Item>

      <Form.Item
        label={<FormItemHeader text={'Wallet Balance'} />}
        name='balance'
        style={{
          marginBottom: 0,
        }}
      >
        <Input
          style={{
            boxSizing: 'border-box',
            height: '2.75rem',
            alignItems: 'center',
          }}
          size={'large'}
          addonBefore={
            <Unit color={'lightgrey'} type={assetType} length='abrv' />
          }
          disabled
        />
      </Form.Item>

      <Form.Item
        label={<FormItemHeader text={'Enter Amount'} />}
        name='sendAmount'
        style={{
          width: '100%',
          marginBottom: '2rem',
        }}
      >
        <div className='ant-input-group'>
          <InputNumber
            className='ant-input'
            size={'large'}
            min='0'
            placeholder='0.0'
            max={availableBalance}
            style={{
              borderRadius: '20px',
              height: '2.75rem',
              padding: '.1rem',
              alignItems: 'center',
              boxSizing: 'border-box',
            }}
          />
        </div>
      </Form.Item>
      {/* TODO:  group this info panel with the button using a DIV instead of Form.Item */}
      {loading && (
        <LoadingInfoPanel>
          <p style={{ margin: 0 }}>
            Your transaction is being broadcast to the blockchain. Please sign
            transaction.
          </p>
        </LoadingInfoPanel>
      )}
      <Form.Item
        style={{
          width: '15%',
          alignSelf: 'flex-end',
        }}
      >
        <SendButton
          type='primary'
          htmlType='submit'
          size='large'
          icon={
            loading ? (
              <div className='flex-center'>
                <Spin indicator={antIcon} />
              </div>
            ) : (
              <div className='flex-center'>
                <ArrowRightIcon />
              </div>
            )
          }
          shape={'circle'}
          disabled={loading || complete}
        />
      </Form.Item>

      {error && <code>{error}</code>}
      {complete && (
        <div>
          <p className='dont-break-out'>
            Transaction Complete.{' '}
            <a href={etherScanUrl + txnHash} rel='noreferrer' target='_blank'>
              View on Etherscan
            </a>
          </p>
        </div>
      )}
      <div className='mb-4'></div>
    </Form>
  );
};

const FormItemHeader = ({ text, color = '#90A0D3' }) => {
  return <ItemHeader>{text}</ItemHeader>;
};

TransferAssetForm.propTypes = {
  assetType: PropTypes.oneOf(['eth', 'weth', 'nft']).isRequired,
};

export default TransferAssetForm;

// TODO: move this to utils
const antIcon = (
  <LoadingOutlined style={{ fontSize: 24, color: '#ffffff' }} spin />
);
