import React from 'react';
const ToastWarnIcon = () => {
  return (
    <svg width='27' height='28' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M13.167 17.127a1.317 1.317 0 0 0 1.316-1.317V8.001a1.317 1.317 0 1 0-2.633 0v7.81a1.317 1.317 0 0 0 1.317 1.316zm-.5 3.35c.32.133.68.133 1 0 .162-.062.31-.156.435-.276.116-.128.21-.275.276-.434.074-.157.11-.328.105-.5a1.317 1.317 0 0 0-.381-.935 1.516 1.516 0 0 0-.435-.277 1.318 1.318 0 0 0-1.817 1.212c.005.348.141.682.382.934.125.12.273.214.434.277zm.5 6.69a13.167 13.167 0 1 0 0-26.334 13.167 13.167 0 0 0 0 26.334zm0-23.7a10.533 10.533 0 1 1 0 21.066 10.533 10.533 0 0 1 0-21.067z'
        fill='#000000'
      />
    </svg>
  );
};
export default ToastWarnIcon;
