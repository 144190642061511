import React, { useEffect, useState, useMemo } from 'react';
import styled from 'styled-components';
import { animated } from 'react-spring';
import { useMediaQuery } from 'react-responsive';
import { useTransition } from 'react-spring';
import useMeasure from 'react-use-measure';

const GalleryWrapper = styled.div`
  position: relative;
  display: flex;
  height: 100%;
  width: 72rem;
  @media (max-width: 1220px) {
    width: 48rem;
  }
  @media (max-width: 900px) {
    width: 23rem;
  }
`;
const Wrapper = styled.div`
  height: 100%;
  width: 72rem;
  @media (max-width: 1220px) {
    width: 48rem;
  }
  @media (max-width: 900px) {
    width: 23rem;
  }
`;
export default function Gallery({ assets, columns = 3 }) {
  const [ref, { width }] = useMeasure({ scroll: true });
  const [items, setItems] = useState(assets);
  const isThreeColumn = useMediaQuery({ query: '(min-width: 1220px)' });
  const isTwoColumn = useMediaQuery({ query: '(min-width: 1000px)' });
  const cols = isThreeColumn ? 3 : isTwoColumn ? 2 : 1;
  useEffect(() => {
    setItems(assets);
    return () => {
      setItems([]);
    };
  }, [assets]);
  const [heights, gridItems] = useMemo(() => {
    let heights = new Array(cols).fill(0); // Each column gets a height starting with zero
    let gridItems = items.map((child, i) => {
      const column = heights.indexOf(Math.min(...heights)); // Basic masonry-grid placing, puts tile into the smallest column using Math.min
      const x = (width / cols) * column; // x = container width / number of cols * column index,
      const y =
        (heights[column] += (i <= 2 ? 1550 : 1420) / 2) -
        (i <= 2 ? 1550 : 1420) / 2; // y = it's just the height of the current column
      return {
        ...child,
        x,
        y,
        width: width / cols,
        height: (i <= 2 ? 1550 : 1420) / 2,
      };
    });
    return [heights, gridItems];
  }, [cols, items, width]);
  const transitions = useTransition(gridItems, {
    key: (item) => item.props.auctionId,
    from: ({ x, y, width, height }) => ({ x, y, width, height, opacity: 0 }),
    enter: ({ x, y, width, height }) => ({ x, y, width, height, opacity: 1 }),
    update: ({ x, y, width, height }) => ({ x, y, width, height }),
    leave: { height: 0, opacity: 0 },
    config: { mass: 5, tension: 500, friction: 100 },
    trail: 25,
  });
  return (
    <Wrapper>
      <GalleryWrapper
        ref={ref}
        columns={columns}
        style={{ height: Math.max(...heights) }}
        // onClick={() => {
        //   setItems(shuffle);
        // }}
      >
        {transitions((style, item, t, index) => {
          return (
            <animated.div
              // key={index}
              style={{
                ...style,
                position: 'absolute',
                willChange: 'transform, width, height, opacity',
              }}
            >
              {items[index]}
            </animated.div>
          );
        })}
      </GalleryWrapper>
    </Wrapper>
  );
}
