import React from 'react';
const SendIcon = ({ height, width, stroke }) => {
  return (
    <svg
      width={width ? width : '40'}
      height={height ? height : '28'}
      viewBox='0 0 30 30'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M5 15L1.62451 26.8142C1.38547 27.6509 2.255 28.3725 3.03324 27.9834L27.2111 15.8944C27.9482 15.5259 27.9482 14.4741 27.2111 14.1056L3.03324 2.01662C2.255 1.6275 1.38547 2.34915 1.62451 3.18577L5 15ZM5 15H15'
        stroke={stroke}
        strokeWidth='1'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
export default SendIcon;
