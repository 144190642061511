import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from 'styled-components';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import 'react-input-range/lib/css/index.css';
import 'antd/dist/antd.css';
import './css/antd-overrides.scss';
// import './css/reactDataTableOverrides.scss';

import './css/index.scss';
import './css/grids.scss';
import './css/loaders.scss';
import './css/buttons_forms.scss';

import store from './store';

import { ProfileProvider } from 'components/user/ProfileContext';
import App from './components/App';
import { theme } from 'configs/theme';
Sentry.init({
  // dev
  // https://27868f0c57c64642a64bdd7d6f40944c@o724052.ingest.sentry.io/5782164

  // staging
  // 'https://fc8741e44c694064a71fe8ed2fdb3d8a@o724052.ingest.sentry.io/5782366'

  // prod
  // 'https://396c57c2e2544aa1a19d7d1fe07c7aad@o724052.ingest.sentry.io/5782146'

  dsn: process.env.REACT_APP_SENTRY_URL,
  integrations: [new Integrations.BrowserTracing()],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <ProfileProvider>
          <App />
        </ProfileProvider>
      </ThemeProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
