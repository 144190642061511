import React from 'react';
const ToastInfoIcon = ({ fill = '#0C1226' }) => {
  return (
    <svg
      viewBox='0 0 27 28'
      width='100%'
      height='100%'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M13.167 12.683A1.317 1.317 0 0 0 11.85 14v5.267a1.317 1.317 0 1 0 2.633 0V14a1.317 1.317 0 0 0-1.316-1.317zm.5-5.161a1.317 1.317 0 0 0-1 0c-.162.062-.31.156-.435.276-.116.128-.21.275-.277.435-.073.156-.11.327-.105.5a1.317 1.317 0 0 0 .382.935c.128.116.275.21.434.276a1.318 1.318 0 0 0 1.817-1.211 1.382 1.382 0 0 0-.381-.935 1.317 1.317 0 0 0-.435-.276zm-.5-6.689a13.167 13.167 0 1 0 0 26.333 13.167 13.167 0 0 0 0-26.333zm0 23.7a10.533 10.533 0 1 1 0-21.067 10.533 10.533 0 0 1 0 21.067z'
        fill={fill}
        fillOpacity='.9'
      />
      <path
        d='M13.167 12.683A1.317 1.317 0 0 0 11.85 14v5.267a1.317 1.317 0 1 0 2.633 0V14a1.317 1.317 0 0 0-1.316-1.317zm.5-5.161a1.317 1.317 0 0 0-1 0c-.162.062-.31.156-.435.276-.116.128-.21.275-.277.435-.073.156-.11.327-.105.5a1.317 1.317 0 0 0 .382.935c.128.116.275.21.434.276a1.318 1.318 0 0 0 1.817-1.211 1.382 1.382 0 0 0-.381-.935 1.317 1.317 0 0 0-.435-.276zm-.5-6.689a13.167 13.167 0 1 0 0 26.333 13.167 13.167 0 0 0 0-26.333zm0 23.7a10.533 10.533 0 1 1 0-21.067 10.533 10.533 0 0 1 0 21.067z'
        fill={fill}
        fillOpacity='.2'
      />
      <defs>
        <linearGradient
          id='toast-info-gradient'
          x1='13.167'
          y1='.833'
          x2='13.167'
          y2='27.166'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#3ACAC7' />
          <stop offset='1' stopColor='#3ACAC7' stopOpacity='0' />
        </linearGradient>
      </defs>
    </svg>
  );
};
export default ToastInfoIcon;
