import React, { forwardRef, useCallback, useState } from 'react';
import { color, layout, space, SpaceProps } from 'styled-system';
import styled from 'styled-components';

interface IInput
  extends SpaceProps,
    React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  value?: string;
  onChange?: React.ChangeEventHandler<EventTarget>;
  error?: any;
  onFocus?: React.EventHandler<any>;
  placeholder: string;
  width?: string;
  height?: string;
  ref?: any;
  dirty?: boolean;
  showPasswordFullError?: boolean;
}

export const Container = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  ${layout};
  ${space};
`;

const StyledInput = styled.input`
  width: ${({ width }) => width};
  height: ${({ height }) => (height ? height : '3rem')};
  padding: 1rem;
  border-radius: 15px;

  font-family: ${({ theme }) => theme.fonts.RobotoRegular};
  color: ${({ theme }) => theme.colors.black};
  font-size: 1rem;
  line-height: 24;
  letter-spacing: 0.15px;

  outline: 0;
  box-sizing: border-box;
  border: solid 2px
    ${({ theme, error }) => (error ? 'red' : theme.colors.black)};
  &::placeholder {
    color: ${({ theme }) => theme.colors.black};
  }
  &:focus {
    border: solid 2px
      ${({ theme, error }) => (error ? 'red' : theme.colors.black)};
  }
  &:focus::placeholder {
    color: ${({ theme, error }) => (error ? 'red' : theme.colors.black)};
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 3.25rem white inset !important;
  }

  ${space};
`;

const ErrorMessageWrapper = styled.span`
  display: flex;
  align-items: center;
  color: ${({ theme, error }) => (error ? 'red' : theme.colors.black)};
  font-family: ${({ theme }) => theme.fonts.RobotoRegular};
  font-size: 0.75rem;
  line-height: 14px;
  letter-spacing: 0.4px;
  padding-left: 1rem;
  ${space};
  ${color};
`;

const Input = forwardRef(
  (
    {
      width = '100%',
      height = '4.125rem',
      placeholder,
      label = '',
      error,
      type,
      name,
      dirty = false,
      ...rest
    }: IInput,
    ref?
  ) => {
    const [focus, setFocus] = useState(false);
    const handleFocus = useCallback(() => {
      setFocus(true);
    }, []);
    const handleBlur = useCallback(() => {
      setFocus(false);
    }, []);
    return (
      <Container width={width} height={height} {...rest}>
        <StyledInput
          label={label}
          error={error}
          onFocus={handleFocus}
          onBlur={handleBlur}
          type={type}
          ref={ref}
          name={name}
          placeholder={focus ? '' : placeholder}
        />
        <ErrorMessageWrapper error={error}>
          {error && error.message}
        </ErrorMessageWrapper>
      </Container>
    );
  }
);

export default Input;
