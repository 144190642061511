import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { sessionSelector } from 'reducers/rootReducer';
import { AppActions } from 'reducers/appReducer';
import { Helmet } from 'react-helmet';
import useRequest from 'hooks/useRequest';
import { useParams } from '@reach/router';
import useUser from 'hooks/useUser';
import UserProfileTemplate from 'components/templates/UserProfileTemplate';
import Loader from 'components/atoms/Loader';
import Footer from 'components/organisms/Footer';

const Profile = () => {
  const dispatch = useDispatch();
  const callApi = useRequest();
  const session = useSelector(sessionSelector);
  const { user } = useUser();
  const [userProfile, setUserProfile] = useState(null);
  const [curations, setCurations] = useState([]);
  const [status, setStatus] = useState('mount'); //loading, error, idle
  const { publicAddress } = useParams();
  const getCurations = useCallback(async () => {
    setStatus('loading');
    return await callApi({
      method: 'GET',
      path: '/api/user/curations/' + publicAddress,
    })
      .then((curations) => {
        if (publicAddress !== user?.publicAddress) {
          delete curations.PENDING;
        }
        setCurations(curations);
      })
      .finally(() => {
        setStatus('idle');
      });
  }, [callApi, publicAddress, user]);
  const removeCuration = useCallback(
    async (auctionId) => {
      if (curations.PENDING) {
        setCurations((curations) => ({
          ...curations,
          PENDING: curations.PENDING.filter((a) => a.id !== auctionId),
        }));
      }
    },
    [curations]
  );
  useEffect(() => {
    dispatch(AppActions.SET_PAGE('profile'));
    setStatus('loading');
    callApi({
      method: 'GET',
      path: '/api/user/public/' + publicAddress,
    })
      .then((r) => {
        if (r) {
          setUserProfile(r);
          getCurations();
        }
      })
      .catch((e) => {
        console.log('get public profile error', e);
      })
      .finally(() => {
        setStatus('idle');
      });

    return () => {
      setCurations([]);
    };
  }, [callApi, publicAddress, getCurations, dispatch]);

  return (
    <>
      {status === 'loading' ? (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            minHeight: '100%',
          }}
        >
          <Loader type={'spin'} style={{ height: '4rem', width: '4rem' }} />
        </div>
      ) : status === 'idle' ? (
        <UserProfileTemplate
          user={userProfile ? userProfile : user}
          curations={curations}
          removeCuration={removeCuration}
          closedBids={[]}
        />
      ) : (
        <span>Error</span>
      )}
      <Footer />
      <Helmet>
        <title>
          {session.status === 'connected' && userProfile?.displayName}
        </title>
      </Helmet>
    </>
  );
};

export default Profile;
