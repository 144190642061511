import { SessionActions } from 'reducers/sessionReducer';
import { AppActions } from 'reducers/appReducer';
import * as Sentry from '@sentry/react';
import { toast } from 'react-toastify';
import Toast from 'components/molecules/Toast';
import ethUtil from 'ethereumjs-util';
import TwitterVerification from 'components/organisms/TwitterVerificationModal';

/*
  startUserSession params:
    loginType: 'magic' || 'walletconnect'
    payload: didToken || connectedAccount
    profile?: {platformType, platformId, communityId}
*/
const startUserSession = (loginType, payload, profile = {}) => {
  return async (dispatch, getState) => {
    /*
      TODO: MAGIC issue with referrerCode:
      when users login with magic they are connecting to our website using a link we sent them -- this link doesn't have the referralcode so we are unable to send it to the backend after signing he user in
    */
    if (loginType === 'magic') {
      const {
        platformType,
        platformId,
        communityId,
        ref: referrerCode,
      } = profile;
      dispatch(SessionActions.SET_USER_STATUS('connecting'));
      fetch(`/api/login`, {
        headers: new Headers({
          Authorization: 'Bearer ' + payload,
          'Content-Type': 'application/json',
        }),
        withCredentials: true,
        credentials: 'same-origin',
        method: 'POST',
        body: JSON.stringify({
          platformType,
          platformId,
          communityId,
          referrerCode,
        }),
      })
        .then(async (response) => {
          const user = await response.json();
          dispatch(SessionActions.SET_USER(user));
          dispatch(SessionActions.SET_BALANCE({ ra: user.ra }));
          dispatch(SessionActions.SET_USER_STATUS('connected'));
          if (
            user &&
            (!user.twitter || (user.twitter && !user.twitter.isRaraVerified))
          ) {
            dispatch(AppActions.SET_MODAL(<TwitterVerification />));
          }
        })
        .catch((err) => {
          dispatch(SessionActions.SET_USER_STATUS('error'));
          dispatch(SessionActions.SET_USER_ERROR(err));
          toast.error(<Toast title='Error' text={err} />);
        });
    } else if (loginType === 'walletconnect') {
      dispatch(SessionActions.SET_USER_STATUS('signing'));
      const { ref: referrerCode } = profile;
      const state = getState();
      const web3 = state.session.web3;
      try {
        if (!web3.instance) {
          throw new Error('Not connected to web3');
        }
        const serverMessage = await fetch(`/api/login/message`, {
          headers: new Headers({
            'Content-Type': 'application/json',
          }),
          method: 'POST',
          body: JSON.stringify({
            publicAddress: web3.connectedAccount,
          }),
        }).then(async (response) => {
          if (response.status === 200) {
            const body = await response.json();
            return body.message;
          }
        });

        // sign message
        const msg = ethUtil.bufferToHex(Buffer.from(serverMessage));
        const signature = await web3.instance.eth.personal.sign(
          msg,
          web3.connectedAccount
        ); // accepts an optional node-style callback in addition to the req params that we supplied

        // send to server
        const response = await fetch(`/api/login/signature`, {
          headers: new Headers({
            'Content-Type': 'application/json',
          }),
          method: 'POST',
          body: JSON.stringify({
            address: web3.connectedAccount,
            msg: serverMessage,
            signed: signature,
            ...profile,
            referrerCode,
          }),
        });
        if (response.status === 200) {
          const user = await response.json();
          dispatch(SessionActions.SET_USER(user));
          dispatch(SessionActions.SET_BALANCE({ ra: user.ra }));
          dispatch(SessionActions.SET_USER_STATUS('connected'));
          if (
            user &&
            (!user.twitter || (user.twitter && !user.twitter.isRaraVerified))
          ) {
            dispatch(AppActions.SET_MODAL(<TwitterVerification />));
          }
          // closeDialog(); // close AuthModal
        } else {
          throw new Error(response.statusText);
        }
      } catch (error) {
        console.log('start user session error', error);
        dispatch(SessionActions.SET_USER_STATUS('error'));
        dispatch(SessionActions.SET_USER_ERROR(error));
        toast.error(<Toast title='Error' text={error.message} />);
        Sentry.captureException(error);
      }
    }
  };
};

export default startUserSession;
