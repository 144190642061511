import React from 'react';
import { Link } from '@reach/router';

function SendPage({ children }) {
  // get URL params

  return (
    <div
      style={{
        padding: '1rem',
        width: '20rem',
        margin: '0 auto',
        borderRadius: '20px',
      }}
      className='light-background'
    >
      {children}
    </div>
  );
}

export default SendPage;
