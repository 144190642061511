import React from 'react';
const UnMuteIcon = () => {
  return (
    <svg
      width='100%'
      height='100%'
      viewBox='0 0 45 45'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle
        cx='22.238'
        cy='22.762'
        r='22.238'
        fill='#6E7795'
        fillOpacity='.66'
      />
      <path
        d='M30.852 17.166a8.387 8.387 0 0 1 1.59 2.677A9.15 9.15 0 0 1 33 23c0 1.083-.19 2.156-.559 3.157-.368 1-.908 1.91-1.59 2.676M16.704 27.583h-4.89a.772.772 0 0 1-.575-.268.978.978 0 0 1-.239-.648v-7.334c0-.243.086-.476.239-.648a.772.772 0 0 1 .576-.268h4.89L24.038 12v22l-7.335-6.417zM16.705 18.418v9.167M27.97 20.41c.303.34.544.745.707 1.19.164.445.248.921.248 1.403 0 .481-.084.958-.248 1.403a3.727 3.727 0 0 1-.706 1.19'
        stroke='#fff'
        strokeWidth='2.022'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};
export default UnMuteIcon;
