import styled from 'styled-components';

const Wrapper = styled.div`
  background: rgba(144, 160, 211, 0.2);
  // border-radius: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default Wrapper;
