import { useState, useEffect, useCallback, useMemo } from 'react';
import * as Sentry from '@sentry/react';
import { appSelector } from 'reducers/rootReducer';
import { useSelector } from 'react-redux';

const useChatRoom = (auctionId) => {
  const App = useSelector(appSelector);
  const [chatStatus, setStatus] = useState('loading');
  const [chatError, setError] = useState(null);
  const [chat, setChat] = useState([]);
  const [channel, setChannel] = useState(null);
  const connectToChatRoom = useCallback(
    ({ auctionId }) => {
      if (App.socket) {
        setStatus('connecting');
        App.socket.emit(
          'chat',
          { auctionId },
          (err, { chat, roomId, channel: discordChannel }) => {
            if (err) {
              setChat([]);
              setChannel(null);
              setStatus('error');
              setError(err);
              return;
            }
            App.socket.on(`chat-msg-${roomId}`, ({ msg }) => {
              setChat((msgs) => [...msgs, msg]);
            });
            // on connection
            setChannel(discordChannel);
            setChat(chat);
            setStatus('connected');
          }
        );
      }
    },
    [App]
  );
  useEffect(() => {
    if (chatError) {
      Sentry.captureException(chatError);
    }
  }, [chatError]);
  useEffect(() => {
    // join the chat room when this hook mounts
    if (auctionId && App.socket) {
      connectToChatRoom({ auctionId });
    }
    return () => {
      // leave the chat room when this hook unmounts
      auctionId &&
        App.socket &&
        App.socket.emit('leave-room', { roomName: `chat-${auctionId}` });
    };
  }, [connectToChatRoom, auctionId, App.socket]);
  return useMemo(
    () => ({
      chat,
      chatStatus,
      chatError,
      channel,
    }),
    [chat, chatStatus, chatError, channel]
  );
};

export default useChatRoom;
