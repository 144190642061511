import React from 'react';
import styled from 'styled-components';
import AuctionAvatar from 'components/atoms/AuctionAvatar';
import { useMediaQuery } from 'react-responsive';
const LeaderboardUserWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const LeaderboardRank = styled.div`
  height: 1.5rem;
  width: 1.5rem;
  min-height: 1.5em;
  min-width: 1.5em;
  border-radius: 50%;
  margin-right: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme, highlighted }) =>
    highlighted ? theme.colors.green60 : 'transparent'};
`;
const LeaderboardUser = ({ rank, user, highlighted }) => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 425px)' });
  return (
    <LeaderboardUserWrapper>
      <LeaderboardRank highlighted={highlighted}>{rank + 1}</LeaderboardRank>
      <AuctionAvatar
        user={user}
        dimensions={{ height: 24, width: 24 }}
        mx={'.5rem'}
      />{' '}
      {isTabletOrMobile ? '' : user.displayName}
    </LeaderboardUserWrapper>
  );
};
export default LeaderboardUser;
