// TODO: Organize / rename colors
const colors = {
  transparentLight: '#6e779530',
  transparentDark: '#23283bef',
  greyish: '#252939',
  greyBlue: '#323b53',
  solidDark: '#0c1226',
  blueGrey: '#90a0d3',
  black: '#383838',
  blackblack: '#000000',
  dark: '#0F1529',
  white: '#ffffff',
  pink: '#e901e9',
  green10: 'rgba(13, 236, 182, .1)',
  green20: 'rgba(13, 236, 182, .2)',
  green30: 'rgba(13, 236, 182, .3)',
  green: 'rgba(13, 236, 182, .5)',
  green60: 'rgba(13, 236, 182, .6)',
  green75: 'rgba(13, 236, 182, .75)',
  green90: 'rgba(13, 236, 182, .9)',
  green100: 'rgba(13, 236, 182, 1)',
  // azure: 'azure'
};

const gradients = {
  pinkToGreen: 'linear-gradient(45deg, #e901e9, #0decb6)',
  dark: 'linear-gradient(to top, rgba(12, 18, 38, 90%), #000000)',
};

const fonts = {
  DMSansBold:
    "DM Sans-Bold, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
  DMSansBoldItalic:
    "DM Sans-BoldItalic, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
  DMSansItalic:
    "DM Sans-Italic, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
  DMSansMedium:
    "DM Sans-Medium, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
  DMSansMediumItalic:
    "DM Sans-MediumItalic, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
  DMSansRegular:
    "DM Sans-Regular, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
};
// const breakpoints = {
//   desktopLrg: '1440px',
//   desktopMed: '1024px',
//   tablet: '768px',
//   mobileLrg: '425px',
//   mobileMed: '375px',
//   mobileSm: '320px',
// };

const borderRadius = {
  xsmall: '8px',
  small: '12px',
  medium: '16px',
  large: '20px',
  xlarge: '24px',
};

export const theme = {
  colors,
  // breakpoints,
  gradients,
  borderRadius,
  fonts,
};
