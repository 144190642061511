import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Badge from '../_particles/Badge';
import copyTextToClipboard from 'helpers/copyTextToClipboard';

const Wrapper = styled(Badge)`
  height: 2.5rem;
  width: 12rem;
  background: ${({ theme, isPressed }) =>
    isPressed ? theme.gradients.pinkToGreen : '#252939'};
  border-width: 0px;
  font-weight: bold;
  cursor: pointer;
  justify-content: center;
  ${(props) => props}
`;

const Icon = styled.div`
  height: 1.2em;
  width: 1.2em;
  align-self: center;
`;

/* TODO: Generalize Share Button interface

  - Rename Props:
      "title" => "initial"
      "successTitle" => "success"
  - New Props
    ? "successStyle": styleObj

*/

const ShareButton = ({
  value = '',
  title = 'Copy',
  successTitle = 'Copied!',
  icon,
  ...props
}) => {
  const [isPressed, setIsPressed] = useState(false);
  useEffect(() => {
    let timeout;
    if (isPressed) {
      copyTextToClipboard(value);
      timeout = setTimeout(() => setIsPressed(false), 1500);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [isPressed, value]);
  return (
    <Wrapper
      onMouseDown={() => setIsPressed(true)}
      isPressed={isPressed}
      {...props}
    >
      {!isPressed ? (
        <>
          {icon && <Icon>{icon}</Icon>}
          <span>&nbsp; {title}</span>
        </>
      ) : (
        <>&nbsp; {successTitle}&nbsp; </>
      )}
    </Wrapper>
  );
};

export default ShareButton;
