import React, { useRef, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import useOnClickOutside from 'hooks/useOnClickOutside';
import PencilIcon from 'icons/PencilIcon';
import LinkOutIcon from 'icons/LinkOutIcon';
import ProfileUpdateForm from 'components/molecules/ProfileUpdateForm';
import logout from 'actions/logout';
import useModal from 'hooks/useModal';

const Wrapper = styled.div`
  position: absolute;
  top: -1rem;
  right: 0rem;
  display: flex;
  flex-direction: column;
  width: 8rem;
  background: ${({ theme }) => theme.colors.greyBlue};
  padding: 0.5rem 1rem;
  border-radius: 8px;
  z-index: 5;
`;
const Item = styled.div`
  display: flex;
  text-align: center;
  align-items: center;
  width: 100%;
  cursor: pointer;
`;
const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 0.8em;
  width: 0.8em;
`;

const UserProfileMenu = ({
  closeMenu = () => console.log('Close UserProfileMenu'),
}) => {
  const dispatch = useDispatch();
  const { openModal } = useModal();
  const showEditProfileDialog = useCallback(
    () => openModal(<ProfileUpdateForm />),
    [openModal]
  );
  const handleLogout = useCallback(() => dispatch(logout()), [dispatch]);

  const ProfileMenuRef = useRef();
  const onClickOutside = useCallback(() => {
    closeMenu && closeMenu();
  }, [closeMenu]);
  useOnClickOutside({ ref: ProfileMenuRef, onClickOutside });

  return (
    <Wrapper ref={ProfileMenuRef}>
      <Item onClick={showEditProfileDialog}>
        <Icon>
          <PencilIcon />
        </Icon>
        &nbsp;&nbsp;Edit Profile
      </Item>
      <Item onClick={handleLogout} style={{ marginTop: '2px' }}>
        <Icon>
          <LinkOutIcon />
        </Icon>
        &nbsp;&nbsp;Log Out
      </Item>
    </Wrapper>
  );
};

export default UserProfileMenu;
