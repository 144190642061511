import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  min-width: 4.75rem;
  height: 1.5rem;
  align-self: ${({ align }) => align};
  white-space: pre;
  margin-right: 1rem;
  cursor: default;
  ${({ btmMargin }) => (btmMargin ? `margin-bottom: ${btmMargin}` : null)}
  @media (max-width: 320px) {
    margin-right: 0.5rem;
  }
`;
const Text = styled.span`
  padding: 5px;
  /* background: ${({ theme }) => theme.colors.green}; */
  /* background: #ffcc48; */
  background: #6e779520;
  text-align: center;
  width: 100%;
  color: ${({ theme }) => theme.colors.white};
  border-radius: 4px;
`;
const Spacing = styled.div`
  min-width: 5.75rem; //account for margin
  @media (max-width: 320px) {
    min-width: 5.25rem;
  }
`;

const TrackLabel = ({ label, align = 'center', btmMargin, style }) => {
  return label ? (
    <Wrapper align={align} btmMargin={btmMargin} style={style}>
      <Text>{label}</Text>
    </Wrapper>
  ) : (
    <Spacing></Spacing>
  );
};

export default TrackLabel;
