import React from 'react';
const ToastErrorIcon = () => {
  return (
    <svg width='27' height='28' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M13.167.833a13.167 13.167 0 1 0 0 26.333 13.167 13.167 0 0 0 0-26.333zm0 23.7A10.533 10.533 0 0 1 2.633 14c-.003-2.34.781-4.612 2.226-6.452l14.76 14.76a10.428 10.428 0 0 1-6.452 2.225zm8.308-4.082L6.715 5.691a10.428 10.428 0 0 1 6.452-2.225A10.533 10.533 0 0 1 23.7 14c.003 2.339-.78 4.611-2.225 6.451z'
        fill='#FFF'
      />
    </svg>
  );
};
export default ToastErrorIcon;
