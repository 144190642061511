import React from 'react';
const PauseIcon = () => {
  return (
    <svg
      width='100%'
      height='100%'
      viewBox='0 0 66 66'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='33' cy='33' r='33' fill='#6E7795' fillOpacity='.66' />
      <path
        d='m47.282 31.72-27-16.5A1.5 1.5 0 0 0 18 16.5v33a1.5 1.5 0 0 0 2.282 1.28l27-16.5a1.5 1.5 0 0 0 0-2.56z'
        fill='#F4F4F4'
      />
    </svg>
  );
};

export default PauseIcon;
