import React from 'react';

const PencilIcon = () => {
  return (
    <svg
      width='100%'
      height='100%'
      viewBox='0 0 12 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M7.944 0a.502.502 0 0 0-.348.147L.147 7.597A.502.502 0 0 0 0 7.95V11.5A.502.502 0 0 0 .502 12h3.547c.133 0 .26-.053.354-.147l7.45-7.45a.502.502 0 0 0 0-.709L8.305.147A.502.502 0 0 0 7.944 0zm.006 1.211L10.79 4.05l-1.42 1.42-2.837-2.84L7.95 1.212zM5.822 3.34 8.66 6.178 3.84 10.997H1.003V8.159l4.82-4.82z'
        fill='#fff'
      />
    </svg>
  );
};

export default PencilIcon;
