import React from 'react';
import Row from '../_particles/Row';
import TrackLabel from '../atoms/TrackLabel';
import UserTrack from './UserTrack';
import Track from '../_particles/Track';

const AuctionCardRow = ({
  label,
  users,
  numToDisplay = 5,
  width,
  emptyLabel,
  mt = '',
}) => {
  return (
    <Row
      mt={mt}
      width={width}
      style={{
        alignItems: 'center',
      }}
    >
      <TrackLabel label={label} />
      {users ? (
        <UserTrack
          users={users}
          numToDisplay={numToDisplay}
          emptyLabel={emptyLabel}
        />
      ) : (
        <Track />
      )}
    </Row>
  );
};

export default AuctionCardRow;
