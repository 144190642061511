import { SessionActions } from 'reducers/sessionReducer';
import { toast } from 'react-toastify';
import Toast from 'components/molecules/Toast';

const mainnetWethContract = '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2';
const rinkbyWethContract = '0xc778417e063141139fce010982780140aa0cd5ab';

const getWalletBalance = () => {
  return async (dispatch, getState) => {
    const { session } = getState();
    const { instance, seaport, connectedAccount, network } = session.web3;
    if (network && instance && connectedAccount && seaport) {
      try {
        const ethBalance = await instance.eth.getBalance(connectedAccount);
        const wethBalance = await seaport.getTokenBalance({
          accountAddress: connectedAccount,
          tokenAddress:
            network.id === 1 ? mainnetWethContract : rinkbyWethContract,
        });
        const balance = {
          eth: Number(instance.utils.fromWei(ethBalance.toString())),
          weth: Number(instance.utils.fromWei(wethBalance.toString())),
        };
        if (session.user && session.user.ra) {
          balance.ra = session.user.ra;
        }
        return dispatch(SessionActions.SET_BALANCE(balance));
      } catch (e) {
        dispatch(SessionActions.SET_WEB3_ERROR({ message: e }));
        toast.error(<Toast title='Wallet Error' text={e} />);
        return dispatch(SessionActions.SET_WEB3_STATUS('error'));
      }
    }
  };
};

export default getWalletBalance;
