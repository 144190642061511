import { useState, useEffect, useCallback, useMemo } from 'react';
import * as Sentry from '@sentry/react';
import { appSelector } from 'reducers/rootReducer';
import { useSelector } from 'react-redux';

const useRaceRoom = () => {
  const App = useSelector(appSelector);
  const [raceStatus, setStatus] = useState('loading');
  const [raceError, setError] = useState(null);
  const [activeRace, setActiveRace] = useState(null);

  const connectToRaceRoom = useCallback(() => {
    if (App.socket) {
      setStatus('connecting');
      App.socket.on('race-update', (currentRace) => {
        setActiveRace(currentRace);
      });
      App.socket.emit('race', (err, currentRace) => {
        if (err) {
          setError(err);
          setActiveRace({
            auctions: [null, null, null],
          });
          setStatus('disconnected');
          return;
        }
        setActiveRace(currentRace);
        setStatus('connected');
      });
    }
  }, [App.socket]);
  useEffect(() => {
    if (raceError) {
      Sentry.captureException(raceError);
    }
  }, [raceError]);

  useEffect(() => {
    if (App.socket) {
      connectToRaceRoom();
    }
    return () => {
      App.socket && App.socket.emit('leave-room', { roomName: 'race' });
    };
  }, [connectToRaceRoom, App.socket]);

  return useMemo(
    () => ({
      activeRace,
      raceStatus,
      raceError,
    }),
    [activeRace, raceStatus, raceError]
  );
};

export default useRaceRoom;
