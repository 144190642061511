import React, { useState, useCallback } from 'react';

import QrReader from 'react-qr-reader';
import { Input } from 'antd';

import { useLookupAddress } from 'eth-hooks';
// probably we need to change value={toAddress} to address={toAddress}

/*
  ~ What it does? ~

  Displays an address input with QR scan option

  ~ How can I use? ~

  <AddressInput
    autoFocus
    ensProvider={mainnetProvider}
    placeholder='Enter address'
    value={toAddress}
    onChange={setToAddress}
  />

  ~ Features ~

  - Provide ensProvider={mainnetProvider} and your address will be replaced by ENS name
              (ex. '0xa870' => 'user.eth') or you can enter directly ENS name instead of address
  - Provide placeholder='Enter address' value for the input
  - Value of the address input is stored in value={toAddress}
  - Control input change by onChange={setToAddress}
                          or onChange={address => { setToAddress(address);}}
*/

export default function AddressInput(props) {
  const [value, setValue] = useState(props.value);
  const [scan, setScan] = useState(false);

  const currentValue = typeof props.value !== 'undefined' ? props.value : value;
  const ens = useLookupAddress(props.ensProvider, currentValue);

  const { ensProvider, onChange } = props;
  const updateAddress = useCallback(
    async (newValue) => {
      if (typeof newValue !== 'undefined') {
        let address = newValue;
        if (address.indexOf('.eth') > 0 || address.indexOf('.xyz') > 0) {
          try {
            const possibleAddress = await ensProvider.resolveName(address);
            if (possibleAddress) {
              address = possibleAddress;
            }
            // eslint-disable-next-line no-empty
          } catch (e) {}
        }
        setValue(address);
        if (typeof onChange === 'function') {
          onChange(address);
        }
      }
    },
    [ensProvider, onChange]
  );

  const scanner = scan ? (
    <div
      style={{
        zIndex: 256,
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
      }}
      onClick={() => {
        setScan(false);
      }}
    >
      <QrReader
        delay={250}
        resolution={1200}
        onError={(e) => {
          console.log('SCAN ERROR', e);
          setScan(false);
        }}
        onScan={(newValue) => {
          if (newValue) {
            console.log('SCAN VALUE', newValue);
            let possibleNewValue = newValue;
            if (possibleNewValue.indexOf('/') >= 0) {
              possibleNewValue = possibleNewValue.substr(
                possibleNewValue.lastIndexOf('0x')
              );
              console.log('CLEANED VALUE', possibleNewValue);
            }
            setScan(false);
            updateAddress(possibleNewValue);
          }
        }}
        style={{ width: '100%' }}
      />
    </div>
  ) : null;

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        width: '100%',
      }}
    >
      <Input
        id={'0xAddress'} //name it something other than address for auto fill doxxing
        name={'0xAddress'} //name it something other than address for auto fill doxxing
        autoComplete='off'
        autoFocus={props.autoFocus}
        placeholder={props.placeholder ? props.placeholder : '0x0000...'}
        // prefix={<Blockie address={currentValue} size={8} scale={3} />}
        value={ens || currentValue}
        // addonAfter={scannerButton}
        onChange={(e) => {
          updateAddress(e.target.value);
        }}
        size={'large'}
        style={{
          height: '2.75rem',
          margin: '0 .5rem 0 0',
          borderRadius: '20px',
        }}
      />
      <div
        style={{
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '2.75rem',
          width: '2.75rem',
        }}
      >
        <ScanIcon onClick={() => setScan((s) => !s)} />
      </div>
      <div>{scanner}</div>
    </div>
  );
}

const ScanIcon = ({ onClick }) => {
  return (
    <svg
      onClick={onClick}
      viewBox='0 0 49 49'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g filter='url(#filter0_b)'>
        <rect
          x='1'
          y='1'
          width='47'
          height='47'
          rx='23.5'
          fill='white'
          fillOpacity='0.8'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M16.0137 17.9723C16.0137 16.8907 16.8904 16.0139 17.972 16.0139H20.5831C20.9436 16.0139 21.2359 16.3062 21.2359 16.6667C21.2359 17.0272 20.9436 17.3195 20.5831 17.3195H17.972C17.6115 17.3195 17.3192 17.6118 17.3192 17.9723V20.5834C17.3192 20.9439 17.027 21.2362 16.6664 21.2362C16.3059 21.2362 16.0137 20.9439 16.0137 20.5834V17.9723ZM27.7637 16.6667C27.7637 16.3062 28.0559 16.0139 28.4165 16.0139H31.0276C32.1091 16.0139 32.9859 16.8907 32.9859 17.9723V20.5834C32.9859 20.9439 32.6936 21.2362 32.3331 21.2362C31.9726 21.2362 31.6803 20.9439 31.6803 20.5834V17.9723C31.6803 17.6118 31.3881 17.3195 31.0276 17.3195H28.4165C28.0559 17.3195 27.7637 17.0272 27.7637 16.6667ZM16.6664 27.7639C17.027 27.7639 17.3192 28.0562 17.3192 28.4167V31.0278C17.3192 31.3884 17.6115 31.6806 17.972 31.6806H20.5831C20.9436 31.6806 21.2359 31.9729 21.2359 32.3334C21.2359 32.6939 20.9436 32.9862 20.5831 32.9862H17.972C16.8904 32.9862 16.0137 32.1094 16.0137 31.0278V28.4167C16.0137 28.0562 16.3059 27.7639 16.6664 27.7639ZM32.3331 27.7639C32.6936 27.7639 32.9859 28.0562 32.9859 28.4167V31.0278C32.9859 32.1094 32.1091 32.9862 31.0276 32.9862H28.4165C28.0559 32.9862 27.7637 32.6939 27.7637 32.3334C27.7637 31.9729 28.0559 31.6806 28.4165 31.6806H31.0276C31.3881 31.6806 31.6803 31.3884 31.6803 31.0278V28.4167C31.6803 28.0562 31.9726 27.7639 32.3331 27.7639Z'
          fill='#0C1226'
        />
        <rect
          x='1'
          y='1'
          width='47'
          height='47'
          rx='23.5'
          stroke='#2A3148'
          strokeWidth='1.30556'
        />
      </g>
      <defs>
        <filter
          id='filter0_b'
          x='-20.5412'
          y='-20.5417'
          width='90.0833'
          height='90.0833'
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity='0' result='BackgroundImageFix' />
          <feGaussianBlur in='BackgroundImage' stdDeviation='10.4444' />
          <feComposite
            in2='SourceAlpha'
            operator='in'
            result='effect1_backgroundBlur'
          />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='effect1_backgroundBlur'
            result='shape'
          />
        </filter>
      </defs>
    </svg>
  );
};
