import { useDispatch } from 'react-redux';
import logout from 'actions/logout';
import { useCallback } from 'react';

const useRequest = () => {
  const dispatch = useDispatch();
  const makeRequest = useCallback(
    async ({ path, method, headers, body }) => {
      try {
        return await fetch(path, {
          method,
          headers: {
            'Content-Type': 'application/json',
            ...headers,
          },
          body: JSON.stringify(body),
        })
          .then(async (res) => {
            if (res.status === 200 || res.status === 201) {
              return await res.json();
            } else if (res.status === 401) {
              dispatch(logout());
            } else if (res.status === 500) {
              const data = await res.json();
              throw data;
            } else if (res.status === 404) {
              throw await res.json();
            }
          })
          .catch((e) => {
            throw e;
          });
      } catch (e) {
        return { error: e.error, data: null, isError: true };
      }
    },
    [dispatch]
  );

  return makeRequest;
};

export default useRequest;
